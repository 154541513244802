import {AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SiteCode} from '../../../models/site-code';
import {emptyProcessNameDropDown, ProcessName} from '../../../models/process-name';
import {emptySLRoot, SharedLoadingRoot} from '../../../models/shared-loading-root';
import {emptySLP, SharedLoadingPlan, SharedLoadingPlanHolder} from '../../../models/shared-loading-plan';
import {emptySL, SharedLoading} from '../../../models/shared-loading';
import {ProcessNameService} from '../../../services/master/process-name/process-name.service';
import {ManageSharedLoadingService} from '../../../services/master/manage-shared-loading/manage-shared-loading.service';
import {DialogService, MessageService} from 'primeng/api';
import {HistoricalMfgPerf} from '../../../models/historical-mfgperf';
import {HistoricalMfgPerfService} from '../../../services/master/historical-mfgperf/historical-mfgperf.service';
import {UtilService} from '../../../services/util.service';
import {SiteCodeService} from '../../../services/sitecode.service';
import {OndeleteDialogueboxComponent} from '../../../ondelete-dialoguebox/ondelete-dialoguebox.component';
import {OnDeleteDisplayDialogueModel} from '../../../models/on-delete-display-dialogue-model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {
	chartColors,
	ChartData,
	dataSetTemplate,
	SharedLoadingChart,
	sharedLoadingChartTemplate
} from '../../../models/chart-data';
import * as _ from 'lodash';
import {CarRequestService} from '../../../services/request/car-request.service';
import {ApsAttributes} from '../../../models/aps-attributes';

@Component({
	selector: 'create-shared-loading',
	templateUrl: './create-shared-loading.component.html',
	styleUrls: ['./create-shared-loading.component.css']
})
export class CreateSharedLoadingComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() isHMPTriggred ?: boolean;
	@ViewChild('sharedLoadingPlanBox', {static: false}) sharedLoadingPlanBox;
	@ViewChildren('sharedLoadingPlanItem') sharedLoadingPlanItemElements: QueryList<any>;
	private scrollContainer: any;
	siteCodes: SiteCode[];
	private mode: string;
	private isUpdatedByUser = true;
	private isSharedLoadingAlreadyUpdatedByCurrentThread = false;
	private sharedLoadingRootId: number;
	selectedSharedLoadingRoot = new SharedLoadingRoot(emptySLRoot);
	selectedSharedLoadingAPW: SharedLoading = new SharedLoading(emptySL);
	selectedSharedLoadingMPW: SharedLoading = new SharedLoading(emptySL);
	sharedLoadingPlanHolder: SharedLoadingPlanHolder = new SharedLoadingPlanHolder();
	sharedLoadingPlanHolderList: SharedLoadingPlanHolder[] = [];
	screenName: string;
	selectedSiteCode: SiteCode;
	availableProcessNames: ProcessName[];
	selectedProcessName: ProcessName;
	historicalmfgperfs: HistoricalMfgPerf[];
	historicalMfgPerfObj: any;
	demonOEE = 0;
	natAPW = 0;
	natMPW = 0;
	totalReqGoodPartsAPW: number;
	totalReqGoodPartsMPW: number;
	totalTimeReqAPW = 0;
	totalTimeReqMPW = 0;
	totalOfAllocationTotalAPW = 0;
	totalOfAllocationTotalMPW = 0;
	loadingPlanExceedsCapacityAbove100APW = false;
	loadingPlanExceedsCapacityAbove100MPW = false;
	loadingPlanExceedsCapacityAbove90APW = false;
	loadingPlanExceedsCapacityAbove90MPW = false;
	disableSiteCodeProcessName = false;
	isCarStatusSubmitted = false;
	showSubmitButton = true;
	carReqId: string;
	processNameId: string;
	private viewMode: string;
	noHMPDataErrorDisplay = false;
	sLPAlreadyExistsErrorDisplay = false;
	disableDataFields = true;
	private isCreateModeSaveAsDraft = true;
	isViewMode = false;
	totalReqGoodPartsCapacityAPW: number;
	totalReqGoodPartsCapacityMPW: number;
	totalOfAllocationTotalCapacityAPW: any;
	totalOfAllocationTotalCapacityMPW: any;
	totalTimeReqCapacityAPW: number;
	totalTimeReqCapacityMPW: number;
	loadingPlanExceedsCapacityAbove100CapacityAPW = false;
	loadingPlanExceedsCapacityAbove100CapacityMPW = false;
	loadingPlanExceedsCapacityAbove90CapacityAPW = false;
	loadingPlanExceedsCapacityAbove90CapacityMPW = false;
	isCapacityStudyAvailable = false;
	displayChartLink = false;
	showChart = false;
	sharedLoadingChart: SharedLoadingChart;
	HMPBasePath: string;
	HMPLink: string;
	private srcSharedLoadingRootId: number;
	disableSubmitForCopy = false;
	disableSiteCodeForCopy = false;
	private unsubscribe$: Subject<any> = new Subject<any>();
	undoIds: number[];
	undoList: Array<number[]> = [];
	boxIndex = 0;
	isSitePresentInSIM = true;
	isTokenOnlyUser: boolean;
	initialTotalAllocAPW: number;
	initialTotalAllocMPW: number;
	initialTotalCapAllocAPW: number;
	initialTotalCapAllocMPW: number;
	loggedInUser: string;
	attributes: ApsAttributes;
	private masterSLRLinkInvokedFromSnapshot: string;
	private slpPresent: boolean;
	private planCount: number;
	private initialPartNames: string[] = [];
	private partNumberChange = false;
	private vLineGreaterTwenty: number;
	private isNonSiteUser: boolean;
	disableUpload = true;
	private indexDuplicate: number[] = [];
	private isDemonOEEDiff: boolean;
	private currentPage: number;
	private currentPageSize: number;

	constructor(private siteCodeService: SiteCodeService, private utilService: UtilService, private historicalMfgPerfService: HistoricalMfgPerfService, private messageService: MessageService, private sharedLoadingService: ManageSharedLoadingService, private processNameService: ProcessNameService, private activatedRoute: ActivatedRoute, private router: Router, public dialogService: DialogService, private carRequestService: CarRequestService) {
	}

	ngOnInit() {
		console.log('ngOnInit');
		this.findAttributes();
		this.retrieveRouteParameter();
		this.checkNonSiteUser(sessionStorage.getItem('userId'), JSON.parse(sessionStorage.getItem('attributes')).userRole);
		this.initializeSharedLoading();
	}

	private initializeSharedLoading() {
		this.buildScreenName();
		this.HMPBasePath = window.location.origin + '/#/historical-mfgperf-master-page/';
		if (this.mode === 'create') {
			this.initializeEmpty();
		} else if (this.mode === 'copy') {
			this.sharedLoadingService.findById(this.srcSharedLoadingRootId).subscribe(value => {
				this.loadProcessNamesForCopy(value);
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		} else {
			this.retrieveSharedLoadingByRootIdInEditMode();
			this.disableSiteCodeProcessName = true;
			if (this.mode === 'view') {
				this.isViewMode = true;
			}
		}

		this.historicalMfgPerfService.triggerSharedLoadingSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(siteCodeAndProcessId => {
			this.isSharedLoadingAlreadyUpdatedByCurrentThread = false;
			this.updatedSharedLoadingForHMPChanges(siteCodeAndProcessId);
		});
		this.loadSiteCodes();
	}

	ngAfterViewInit() {
		if (this.sharedLoadingPlanBox) {
			this.scrollContainer = this.sharedLoadingPlanBox.nativeElement;
		}
	}

	updatedSharedLoadingForHMPChanges(siteCodeAndProcessId: string) {
		this.mode = 'edit';
		this.isUpdatedByUser = false;
		const siteCodeProcessCodeArray = siteCodeAndProcessId.split('~');
		const siteCodeTemp = siteCodeProcessCodeArray[0];
		const processIdTemp = siteCodeProcessCodeArray[1];
		if (siteCodeTemp && processIdTemp) {
			this.retrieveSharedLoadingBySiteCodeAndProcessNameId(siteCodeTemp, processIdTemp);
		} else {
			console.log('Invalid siteCodeAndProcessId');
		}
	}

	private initializeEmpty() {
		this.sharedLoadingPlanHolder.apw = new SharedLoadingPlan(emptySLP);
		this.sharedLoadingPlanHolder.mpw = new SharedLoadingPlan(emptySLP);
		this.sharedLoadingPlanHolder.apw.isApw = true;
		this.sharedLoadingPlanHolder.mpw.isApw = false;
		this.sharedLoadingPlanHolder.apw.groupId = 0;
		this.sharedLoadingPlanHolder.mpw.groupId = 0;
		this.sharedLoadingPlanHolderList.push(this.sharedLoadingPlanHolder);
	}

	loadHistoricalMfgPerf() {
		if (this.mode === 'create') {
			if (this.selectedSiteCode && this.selectedProcessName && this.selectedSiteCode.code !== '' && this.selectedProcessName.id !== 0) {
				this.fetchHMPData();
			}
		} else {
			if (this.selectedSharedLoadingRoot && this.selectedSharedLoadingRoot.siteCode !== '' && this.selectedSharedLoadingRoot.processNameId !== 0) {
				this.fetchHMPData();
			}
		}
	}

	private fetchHMPData() {
		this.historicalMfgPerfObj = {
			processId: this.selectedProcessName.id,
			siteCode: this.selectedSiteCode.code
		};
		this.noHMPDataErrorDisplay = false;
		if (this.mode === 'edit' && this.isCarStatusSubmitted && this.carReqId) {
			this.historicalMfgPerfService.findHistMfgPerfRecordsByCarId(this.historicalMfgPerfObj, this.carReqId).subscribe((response: HistoricalMfgPerf[]) => {
				this.historicalmfgperfs = response;
				if (this.historicalmfgperfs.length === 0) {
					this.noHMPDataErrorDisplay = true;
					this.HMPLink = 'Not Available';
				} else {
					this.HMPLink = this.HMPBasePath + '?siteCode=' + this.selectedSiteCode.code + '&processId=' + this.selectedProcessName.id + '&carStatus=view&carId=' + this.carReqId;
				}
				this.demonOEE = this.utilService.calculateAverageOEE(this.historicalmfgperfs);
				this.buildAndShowSharedLoadingInEditMode();
				this.buildAndShowSharedLoadingPlanBoxInEditMode();
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		} else {
			this.historicalMfgPerfService.findLatest25HistMfgPerfRecords(this.historicalMfgPerfObj).subscribe((response: HistoricalMfgPerf[]) => {
				this.historicalmfgperfs = response;
				if (this.historicalmfgperfs.length === 0) {
					this.noHMPDataErrorDisplay = true;
					this.HMPLink = 'Not Available';
				} else {
					this.HMPLink = this.HMPBasePath + '?siteCode=' + this.selectedSiteCode.code + '&processId=' + this.selectedProcessName.id;
				}
				this.demonOEE = this.utilService.calculateAverageOEE(this.historicalmfgperfs);
				if (this.mode !== 'create') {
					this.buildAndShowSharedLoadingInEditMode();
					this.buildAndShowSharedLoadingPlanBoxInEditMode();
				}
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	private buildAndShowSharedLoadingInEditMode() {
		this.selectedSharedLoadingAPW = this.selectedSharedLoadingRoot.sharedLoadingList[0];
		this.selectedSharedLoadingMPW = this.selectedSharedLoadingRoot.sharedLoadingList[1];
		this.displayNatAPW(this.selectedSharedLoadingAPW);
		this.displayNatMPW(this.selectedSharedLoadingMPW);
	}

	private buildAndShowSharedLoadingPlanBoxInEditMode() {
		if (this.mode === 'edit') {
			this.initializePartPlanDetails();
		}
		this.sharedLoadingPlanHolderList = [];
		this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList.forEach((sharedLoadingPlan, index) => {
			const sharedLoadingPlanHolder = new SharedLoadingPlanHolder();
			if (sharedLoadingPlan.isCapacity) {
				let indexValue = 0;
				this.sharedLoadingPlanHolderList.forEach((slPlanHolder, indx) => {
					if (slPlanHolder.apw.groupId === sharedLoadingPlan.groupId) {
						indexValue = indx;
					}
				});
				this.sharedLoadingPlanHolderList[indexValue].capacityAPW = sharedLoadingPlan;
				this.sharedLoadingPlanHolderList[indexValue].capacityMPW = this.selectedSharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[index];
			} else {
				if (sharedLoadingPlan.partName.length > 0) {
					const partNames = sharedLoadingPlan.partName.split('-');
					if (partNames.length > 2) {
						sharedLoadingPlan.partNamePrefix = partNames[0];
						sharedLoadingPlan.partNameBase = partNames[1];
						sharedLoadingPlan.partNameSuffix = partNames[2];
					}
				}
				sharedLoadingPlanHolder.apw = sharedLoadingPlan;
				sharedLoadingPlanHolder.mpw = this.selectedSharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[index];
				//Adding Group ID for old records (before implementing grouping and capacity apw | mpw)
				if (!sharedLoadingPlanHolder.apw.groupId) {
					sharedLoadingPlanHolder.apw.groupId = index;
					sharedLoadingPlanHolder.apw.isCapacity = false;
				}
				if (!sharedLoadingPlanHolder.mpw.groupId) {
					sharedLoadingPlanHolder.mpw.groupId = index;
					sharedLoadingPlanHolder.mpw.isCapacity = false;
				}
				this.sharedLoadingPlanHolderList.push(sharedLoadingPlanHolder);
			}
		});
		this.displayNatAPW(this.selectedSharedLoadingAPW);
		this.displayNatMPW(this.selectedSharedLoadingMPW);
		this.calculateTotalReqGoodPartsPerWeekAPW();
		this.calculateTotalReqGoodPartsPerWeekMPW();
		this.calculateTotalTimeReqAPW();
		this.calculateTotalTimeReqMPW();
		this.calculateTotalOfAllocationTotalAPW();
		this.calculateTotalOfAllocationTotalMPW();
		const isUpdatable = !this.isUpdatedByUser && !this.isSharedLoadingAlreadyUpdatedByCurrentThread;
		if (isUpdatable) {
			this.onSubmit(false);
			this.isSharedLoadingAlreadyUpdatedByCurrentThread = true;
			this.sharedLoadingService.isRequiredGoodPartsRefreshCompleted.next('true');
		}
		this.checkCapacityStudyAvailable();
	}

	private buildScreenName() {
		// this.screenName = this.mode === 'edit' ? 'Edit Shared Loading' : 'Create Shared Loading';
		if (this.mode === 'view' || this.viewMode === 'Y' || this.isCarStatusSubmitted) {
			this.screenName = 'View Shared Loading';
		} else if (this.mode === 'edit') {
			this.screenName = 'Edit Shared Loading';
		} else {
			this.screenName = 'Create Shared Loading';
		}
	}

	private loadProcessNamesForCopy(value: SharedLoadingRoot) {
		this.selectedSharedLoadingRoot = value;
		this.disableSiteCodeForCopy = true;
		this.disableSubmitForCopy = true;
		this.selectedSiteCode = this.siteCodes.find(siteCodeObject => siteCodeObject.code === this.selectedSharedLoadingRoot.siteCode);
		this.sharedLoadingService.findAllProcessNamesWithoutHavingSLP(this.selectedSiteCode.code).subscribe((response: ProcessName[]) => {
			this.availableProcessNames = response;
			if (this.availableProcessNames && this.availableProcessNames.length > 0) {
				this.availableProcessNames = this.availableProcessNames.filter(processName => processName.active === true);
				this.availableProcessNames = this.availableProcessNames.sort(function (a, b) {
					return a.processName.localeCompare(b.processName, undefined, {
						numeric: true,
						sensitivity: 'base'
					});
				});
			}
			this.availableProcessNames.unshift(emptyProcessNameDropDown);
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
		this.isSiteCodeAvailableInSIM(this.selectedSiteCode.code);
		this.updateSHValuesFromSourceSL();
	}

	private updateSHValuesFromSourceSL() {
		this.selectedSharedLoadingAPW = new SharedLoading(this.selectedSharedLoadingRoot.sharedLoadingList[0]);
		this.selectedSharedLoadingMPW = new SharedLoading(this.selectedSharedLoadingRoot.sharedLoadingList[1]);
		this.buildAndShowSharedLoadingPlanBoxInEditMode();
	}

	allowSubmitForCopy() {
		if (this.disableSubmitForCopy) {
			this.disableSubmitForCopy = false;
			this.dialogService.open(OndeleteDialogueboxComponent, {
				header: 'Info',
				width: '30%',
				contentStyle: {'max-height': '500px', 'overflow': 'auto'},
				baseZIndex: 20000,
				data: {
					'message': 'SLPReviewPartsForCopy'
				}
			});
		}
	}

	private retrieveRouteParameter() {
		/*this.activatedRoute.queryParamMap.subscribe(params => {
			this.viewMode = params.get('mode');
		});*/
		this.activatedRoute.paramMap.subscribe(params => {
			this.mode = params.get('mode');
			this.viewMode = params.get('viewMode');
			this.currentPage = Number(params.get('pageNo'));
			this.currentPageSize = Number(params.get('pageSize'));
			if (this.mode === 'copy') {
				this.srcSharedLoadingRootId = Number(params.get('id'));
			} else {
				this.sharedLoadingRootId = Number(params.get('id'));
				const status = params.get('carStatus');
				this.carReqId = params.get('carReqId');
				this.processNameId = params.get('processNameId');
				if (status === 'true') {
					this.isCarStatusSubmitted = true;
				} else {
					this.isCarStatusSubmitted = false;
				}
			}
		});
	}

	private validateData() {
		let isValid = true;
		if (this.mode === 'create') {
			if (!this.selectedSiteCode) {
				isValid = this.showSiteCodeInvalidErrorMsg();
			}
			if (!this.selectedProcessName) {
				isValid = this.showProcessNameInvalidErrorMsg();
			}
		} else {
			if (this.selectedSiteCode.code === '') {
				isValid = this.showSiteCodeInvalidErrorMsg();
			}
			if (this.selectedProcessName.id === 0) {
				isValid = this.showProcessNameInvalidErrorMsg();
			}
		}

		if (!this.validateSharedLoadingTextBox()) {
			return false;
		}
		if (!this.validateSharedLoadingPlanTextBox()) {
			return false;
		}
		return isValid;
	}

	private showSiteCodeInvalidErrorMsg(): boolean {
		this.utilService.pushMsg('error', 'Error', 'Please select any valid Site Code.');
		return false;
	}

	private showProcessNameInvalidErrorMsg(): boolean {
		this.utilService.pushMsg('error', 'Error', 'Please select any Process Name.');
		return false;
	}

	onSubmit(isSaveAsDraft) {
		console.log('onSubmit');
		if (this.validateData()) {
			if (this.mode === 'edit') {
				this.selectedSharedLoadingAPW.siteCode = this.selectedSharedLoadingRoot.siteCode;
				this.selectedSharedLoadingAPW.demonOEE = this.roundNumber(this.selectedSharedLoadingRoot.demonstratedOEE * 100, 1);
				this.selectedSharedLoadingMPW.siteCode = this.selectedSharedLoadingRoot.siteCode;
				this.selectedSharedLoadingMPW.demonOEE = this.roundNumber(this.selectedSharedLoadingRoot.demonstratedOEE * 100, 1);
			} else {
				this.selectedSharedLoadingRoot.siteCode = this.selectedSiteCode.code;
				this.selectedSharedLoadingRoot.processNameId = this.selectedProcessName.id;
				this.selectedSharedLoadingAPW.processNameId = this.selectedProcessName.id;
				this.selectedSharedLoadingMPW.processNameId = this.selectedProcessName.id;
				this.selectedSharedLoadingAPW.siteCode = this.selectedSiteCode.code;
				this.selectedSharedLoadingMPW.siteCode = this.selectedSiteCode.code;
			}
			this.selectedSharedLoadingAPW.isAPW = true;
			this.selectedSharedLoadingMPW.isAPW = false;
			this.selectedSharedLoadingAPW.demonOEE = this.demonOEE;
			this.selectedSharedLoadingMPW.demonOEE = this.demonOEE;
			this.selectedSharedLoadingRoot.sharedLoadingList = [];
			this.selectedSharedLoadingRoot.sharedLoadingList.push(this.selectedSharedLoadingAPW);
			this.selectedSharedLoadingRoot.sharedLoadingList.push(this.selectedSharedLoadingMPW);
			const selectedSharedLoadingPlanAPWList: any[] = [];
			const selectedSharedLoadingPlanMPWList: any[] = [];
			this.sharedLoadingPlanHolderList.forEach((item, index) => {
				if (item.apw.partNamePrefix && item.apw.partNameBase && item.apw.partNameSuffix) {
					item.apw.partName = item.apw.partNamePrefix.trim() + '-' + item.apw.partNameBase.trim() + '-' + item.apw.partNameSuffix.trim();
					item.mpw.partName = item.apw.partNamePrefix.trim() + '-' + item.apw.partNameBase.trim() + '-' + item.apw.partNameSuffix.trim();
					if (!this.partNumberChange && this.initialPartNames.length > 0 && (this.sharedLoadingPlanHolderList.length !== this.initialPartNames.length || this.initialPartNames[index].toUpperCase() !== item.apw.partName.toUpperCase())) {
						this.partNumberChange = true;
					}
				}
				selectedSharedLoadingPlanAPWList.push(item.apw);
				selectedSharedLoadingPlanMPWList.push(item.mpw);
				if (item.capacityAPW) {
					item.capacityAPW.partName = item.apw.partName;
					selectedSharedLoadingPlanAPWList.push(item.capacityAPW);
				}
				if (item.capacityMPW) {
					item.capacityMPW.partName = item.apw.partName;
					selectedSharedLoadingPlanMPWList.push(item.capacityMPW);
				}
			});

			if (this.selectedSharedLoadingRoot.sharedLoadingList[0].isAPW) {
				this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList = selectedSharedLoadingPlanAPWList;
			}
			if (!this.selectedSharedLoadingRoot.sharedLoadingList[1].isAPW) {
				this.selectedSharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList = selectedSharedLoadingPlanMPWList;
			}

			this.initialTotalAllocAPW = this.selectedSharedLoadingRoot.totalAllocationPercentageAPW;
			this.initialTotalAllocMPW = this.selectedSharedLoadingRoot.totalAllocationPercentageMPW;
			this.initialTotalCapAllocAPW = this.selectedSharedLoadingRoot.totalAllocationPercentageCapacityAPW;
			this.initialTotalCapAllocMPW = this.selectedSharedLoadingRoot.totalAllocationPercentageCapacityMPW;
			this.selectedSharedLoadingRoot.totalAllocationPercentageAPW = this.totalOfAllocationTotalAPW;
			this.selectedSharedLoadingRoot.totalAllocationPercentageMPW = this.totalOfAllocationTotalMPW;
			this.totalOfAllocationTotalCapacityAPW = this.totalOfAllocationTotalCapacityAPW === 0 ? null : this.totalOfAllocationTotalCapacityAPW;
			this.totalOfAllocationTotalCapacityMPW = this.totalOfAllocationTotalCapacityMPW === 0 ? null : this.totalOfAllocationTotalCapacityMPW;
			this.selectedSharedLoadingRoot.totalAllocationPercentageCapacityAPW = this.totalOfAllocationTotalCapacityAPW;
			this.selectedSharedLoadingRoot.totalAllocationPercentageCapacityMPW = this.totalOfAllocationTotalCapacityMPW;
			console.log('FINAL selectedSharedLoadingRoot');
			console.log(this.selectedSharedLoadingRoot);
			this.showSubmitButton = false;
			if (this.isDemonOEEDiff) {
				this.selectedSharedLoadingRoot.totalAllocationPercentageAPW = this.totalOfAllocationTotalAPW;
				this.selectedSharedLoadingRoot.totalAllocationPercentageMPW = this.totalOfAllocationTotalMPW;
				this.selectedSharedLoadingRoot.totalAllocationPercentageCapacityAPW = this.totalOfAllocationTotalCapacityAPW;
				this.selectedSharedLoadingRoot.totalAllocationPercentageCapacityMPW = this.totalOfAllocationTotalCapacityMPW;
				this.selectedSharedLoadingRoot.isAlertRequired = false;
				this.selectedSharedLoadingRoot.sharedLoadingList[0].processNameId = this.selectedSharedLoadingRoot.processNameId;
				this.selectedSharedLoadingRoot.sharedLoadingList[1].processNameId = this.selectedSharedLoadingRoot.processNameId;
				this.sharedLoadingService.overwriteSharedLoading(this.selectedSharedLoadingRoot.id, this.selectedSharedLoadingRoot).subscribe((slr) => {
					this.router.navigate(['manage-shared-loading']);
				}, error => {
					this.utilService.pushMsg('error', 'Error', error.error);
				});
			} else if ((this.mode === 'create' || this.mode === 'copy') && this.isCreateModeSaveAsDraft) {
				this.sharedLoadingService.create(this.selectedSharedLoadingRoot).subscribe((value) => {
					if (!isSaveAsDraft) {
						sessionStorage.setItem('SharedLoadingCreateSuccess', JSON.stringify({
							'status': 'success',
							'message': 'Shared Loading is created successfully!'
						}));
						this.router.navigate(['manage-shared-loading']);
					} else {
						this.utilService.pushMsg('success', 'Success', 'Shared Loading is saved successfully!');
						this.isCreateModeSaveAsDraft = false;
						//this.router.navigate(['create-shared-loading/edit/' + value.id + '/false/0/0']);
						this.viewMode = 'edit';
						this.mode = 'edit';
						this.sharedLoadingRootId = value.id;
						this.isCarStatusSubmitted = false;
						this.carReqId = '0';
						this.processNameId = '0';
						this.initializeSharedLoading();
					}
				}, error => {
					console.log(error);
					console.log(error.error);
					this.utilService.pushMsg('error', 'Error', error.error);
				});
			} else {
				console.log('onSubmit updated');
				this.sharedLoadingService.update(this.selectedSharedLoadingRoot.id, this.selectedSharedLoadingRoot).subscribe((value) => {
					this.initializeSharedLoading();
					if (this.isUpdatedByUser) {
						if (!isSaveAsDraft) {
							sessionStorage.setItem('SharedLoadingCreateSuccess', JSON.stringify({
								'status': 'success',
								'message': 'Shared Loading is updated successfully!'
							}));
							this.router.navigate(['manage-shared-loading']);
						} else {
							this.utilService.pushMsg('success', 'Success', 'Shared Loading is updated successfully!');
						}
					}

					const roundedTotalAllocAPW = _.round(this.selectedSharedLoadingRoot.totalAllocationPercentageAPW, 2);
					const roundedTotalAllocMPW = _.round(this.selectedSharedLoadingRoot.totalAllocationPercentageMPW, 2);
					const roundedTotalCapAllocAPW = _.round(this.selectedSharedLoadingRoot.totalAllocationPercentageCapacityAPW, 2);
					const roundedTotalCapAllocMPW = _.round(this.selectedSharedLoadingRoot.totalAllocationPercentageCapacityMPW, 2);
					console.log('initial total apw ' + this.initialTotalAllocAPW + ' src apw ' + roundedTotalAllocAPW);
					console.log('initial total mpw ' + this.initialTotalAllocMPW + ' src mpw ' + roundedTotalAllocMPW);
					console.log('initial cap apw ' + this.initialTotalCapAllocAPW + ' src apw ' + roundedTotalCapAllocAPW);
					console.log('initial cap mpw ' + this.initialTotalCapAllocMPW + ' src mpw ' + roundedTotalCapAllocMPW);
					if (this.initialTotalAllocAPW !== roundedTotalAllocAPW || this.initialTotalAllocMPW !== roundedTotalAllocMPW ||
						this.initialTotalCapAllocAPW !== roundedTotalCapAllocAPW || this.initialTotalCapAllocMPW !== roundedTotalCapAllocMPW ||
						this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList.length !== this.planCount || this.partNumberChange) {
						this.updateSLPFlagInCAR();
					}
				}, error => {
					this.utilService.pushMsg('error', 'Error', error.error);
				});
			}
		}
	}

	private updateSLPFlagInCAR() {
		const totalAllocCapacityAPW = (!this.selectedSharedLoadingRoot.totalAllocationPercentageCapacityAPW || this.selectedSharedLoadingRoot.totalAllocationPercentageCapacityAPW === null) ? -1 : this.selectedSharedLoadingRoot.totalAllocationPercentageCapacityAPW;
		const totalAllocCapacityMPW = (!this.selectedSharedLoadingRoot.totalAllocationPercentageCapacityMPW || this.selectedSharedLoadingRoot.totalAllocationPercentageCapacityMPW === null) ? -1 : this.selectedSharedLoadingRoot.totalAllocationPercentageCapacityMPW;
		const isPlanCntPartNameChange = this.mode === 'edit' && (this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList.length !== this.planCount || this.partNumberChange);
		this.carRequestService.updateSLPFlagForTotalAllocChange(this.selectedSiteCode.code, this.selectedProcessName.id, this.selectedSharedLoadingRoot.totalAllocationPercentageAPW, this.selectedSharedLoadingRoot.totalAllocationPercentageMPW, totalAllocCapacityAPW, totalAllocCapacityMPW, isPlanCntPartNameChange).subscribe(isUpdated => {
			isUpdated ? console.log('SLP flags updated') : console.log('SLP flags not updated');
			if (this.mode === 'edit') {
				this.initializePartPlanDetails();
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	onCancel() {
		const pageNo = this.currentPage;
		const pageSize = this.currentPageSize;
		this.router.navigate(['manage-shared-loading/' + pageNo + '/' + pageSize]);
	}

	addSHPlanBox() {
		this.sharedLoadingPlanItemElements.changes.subscribe(_ => {
			this.scrollToBottom();
		});
		if (this.boxIndex !== 0) {
			this.boxIndex += 1;
		} else {
			this.boxIndex = this.sharedLoadingPlanHolderList.length - 1;
		}
		this.sharedLoadingPlanHolder = new SharedLoadingPlanHolder();
		this.sharedLoadingPlanHolder.apw = new SharedLoadingPlan(emptySLP);
		this.sharedLoadingPlanHolder.mpw = new SharedLoadingPlan(emptySLP);
		this.sharedLoadingPlanHolder.apw.isApw = true;
		this.sharedLoadingPlanHolder.mpw.isApw = false;
		this.sharedLoadingPlanHolder.apw.groupId = this.boxIndex + 1;
		this.sharedLoadingPlanHolder.mpw.groupId = this.boxIndex + 1;
		this.sharedLoadingPlanHolderList.push(this.sharedLoadingPlanHolder);
	}

	removeSHPlanBox(index: number) {
		if (this.sharedLoadingPlanHolderList.length > 1) {
			const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
				header: 'Warning',
				width: '50%',
				contentStyle: {'max-height': '700px', 'overflow': 'auto'},
				data: {
					'message': 'SLProcessData'
				}
			});
			deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
				if (deleteModel != null) {
					if (deleteModel.isDeleted) {
						if (this.boxIndex !== 0) {
							this.boxIndex--;
						}
						this.sharedLoadingPlanHolderList.splice(index, 1);
						this.calculateTotalReqGoodPartsPerWeekAPW();
						this.calculateTotalReqGoodPartsPerWeekMPW();
						this.calculateTotalTimeReqAPW();
						this.calculateTotalTimeReqMPW();
						this.calculateTotalOfAllocationTotalAPW();
						this.calculateTotalOfAllocationTotalMPW();
						this.checkCapacityStudyAvailable();
					} else {
						console.log('dont Delete response from dialogue box');
					}
				}
			});
		}
	}

	displayNatAPW(selectedSharedLoadingAPW: SharedLoading) {
		if (this.loggedInUser.includes('SiteEngineer') && selectedSharedLoadingAPW.demonOEE && this.mode !== 'copy') {
			this.demonOEE = selectedSharedLoadingAPW.demonOEE;
		}
		this.natAPW = this.calculateNAT(selectedSharedLoadingAPW);
		selectedSharedLoadingAPW.netAvailableTime = this.natAPW;
		this.sharedLoadingPlanHolderList.forEach((sharedLoadingPlanHolder, index) => {
			this.calculateTimeRequiredAPW(sharedLoadingPlanHolder);
			if (sharedLoadingPlanHolder.capacityAPW) {
				this.calculateTimeRequiredCapacityAPW(sharedLoadingPlanHolder);
			}
		});
	}

	private calculateNAT(selectedSharedLoading: SharedLoading) {
		const natSum = selectedSharedLoading.daysPerWeek * selectedSharedLoading.shiftsPerDay * selectedSharedLoading.hrsPerShift;
		return natSum - (selectedSharedLoading.daysPerWeek * selectedSharedLoading.shiftsPerDay * selectedSharedLoading.contPlanned / 60);
	}

	displayNatMPW(selectedSharedLoadingMPW: SharedLoading) {
		if (this.loggedInUser.includes('SiteEngineer') && selectedSharedLoadingMPW.demonOEE && this.mode !== 'copy') {
			this.demonOEE = selectedSharedLoadingMPW.demonOEE;
		}
		this.natMPW = this.calculateNAT(selectedSharedLoadingMPW);
		selectedSharedLoadingMPW.netAvailableTime = this.natMPW;
		this.sharedLoadingPlanHolderList.forEach((sharedLoadingPlanHolder, index) => {
			this.calculateTimeRequiredMPW(sharedLoadingPlanHolder);
			if (sharedLoadingPlanHolder.capacityMPW) {
				this.calculateTimeRequiredCapacityMPW(sharedLoadingPlanHolder);
			}
		});
	}

	calculateTimeRequiredAPW(sharedLoadingPlanHolderLocal: SharedLoadingPlanHolder) {
		const reqGoodPartsPerWeek = sharedLoadingPlanHolderLocal.apw.reqGoodPartsPerWeek;
		const nict = sharedLoadingPlanHolderLocal.apw.nict;
		const timeReqDemonstratedOEEAPW = (((reqGoodPartsPerWeek * nict) / 3600) / this.demonOEE) * 100;
		sharedLoadingPlanHolderLocal.apw.timeReq = timeReqDemonstratedOEEAPW;
		this.calculateAllocationMinRequiredAPW(sharedLoadingPlanHolderLocal);
		this.calculateTotalTimeReqAPW();
	}

	calculateAllocationMinRequiredAPW(sharedLoadingPlanHolderLocal: SharedLoadingPlanHolder) {
		sharedLoadingPlanHolderLocal.apw.allocationMinRequired = (sharedLoadingPlanHolderLocal.apw.timeReq / this.natAPW) * 100;
		this.calculateAllocationTotalRequiredAPW(sharedLoadingPlanHolderLocal);
	}

	calculateTimeRequiredMPW(sharedLoadingPlanHolderLocal: SharedLoadingPlanHolder) {
		const reqGoodPartsPerWeek = sharedLoadingPlanHolderLocal.mpw.reqGoodPartsPerWeek;
		const nict = sharedLoadingPlanHolderLocal.mpw.nict;
		const timeReqDemonstratedOEEMPW = (((reqGoodPartsPerWeek * nict) / 3600) / this.demonOEE) * 100;
		sharedLoadingPlanHolderLocal.mpw.timeReq = timeReqDemonstratedOEEMPW;
		this.calculateTotalTimeReqMPW();
		this.calculateAllocationMinRequiredMPW(sharedLoadingPlanHolderLocal);
	}

	calculateAllocationMinRequiredMPW(sharedLoadingPlanHolderLocal: SharedLoadingPlanHolder) {
		sharedLoadingPlanHolderLocal.mpw.allocationMinRequired = (sharedLoadingPlanHolderLocal.mpw.timeReq / this.natMPW) * 100;
		this.calculateAllocationTotalRequiredMPW(sharedLoadingPlanHolderLocal);
	}

	calculateAllocationTotalRequiredAPW(sharedLoadingPlanHolder: SharedLoadingPlanHolder) {
		if (!sharedLoadingPlanHolder.apw.isOtherAllocation) {
			sharedLoadingPlanHolder.apw.allocationTotalRequired = sharedLoadingPlanHolder.apw.allocationMinRequired + sharedLoadingPlanHolder.apw.additionalAllocation;
		}
		this.calculateTotalOfAllocationTotalAPW();
	}

	transformAddAllocation(sharedLoadingPlanHolderList: SharedLoadingPlanHolder[], boxIndex: number, isAPW: boolean) {
		const sharedLoadingPlan = sharedLoadingPlanHolderList[boxIndex];
		if (isAPW) {
			if (isNaN(sharedLoadingPlan.apw.additionalAllocation)) {
				sharedLoadingPlan.apw.additionalAllocation = 0;
			}
			sharedLoadingPlan.apw.additionalAllocation = (sharedLoadingPlan.apw.additionalAllocation * 10) / 10;
		}
		if (!isAPW) {
			if (isNaN(sharedLoadingPlan.mpw.additionalAllocation)) {
				sharedLoadingPlan.mpw.additionalAllocation = 0;
			}
			sharedLoadingPlan.mpw.additionalAllocation = (sharedLoadingPlan.mpw.additionalAllocation * 10) / 10;
		}
		if (sharedLoadingPlan.apw.isOtherAllocation) {
			sharedLoadingPlan.apw.allocationTotalRequired = sharedLoadingPlan.apw.additionalAllocation;
		}
		if (sharedLoadingPlan.mpw.isOtherAllocation) {
			sharedLoadingPlan.mpw.allocationTotalRequired = sharedLoadingPlan.mpw.additionalAllocation;
		}
	}

	calculateTotalOfAllocationTotalAPW() {
		this.totalOfAllocationTotalAPW = 0;
		this.sharedLoadingPlanHolderList.forEach((sharedLoadingPlanHolder, index) => {
			this.totalOfAllocationTotalAPW = this.totalOfAllocationTotalAPW + sharedLoadingPlanHolder.apw.allocationTotalRequired;
			this.showLoadingPlanExceedMsgAPW();
		});
		this.calculateTotalOfAllocationTotalCapacityAPW();
	}

	calculateTotalOfAllocationTotalDirect() {
		this.calculateTotalOfAllocationTotalAPW();
		this.calculateTotalOfAllocationTotalMPW();
	}

	showLoadingPlanExceedMsgAPW() {
		if (this.selectedProcessName && this.selectedProcessName.id !== 0 && this.totalOfAllocationTotalAPW > 100) {
			this.loadingPlanExceedsCapacityAbove100APW = true;
		} else {
			this.loadingPlanExceedsCapacityAbove100APW = false;
		}

		if (this.totalOfAllocationTotalAPW > 90 && this.totalOfAllocationTotalAPW < 100) {
			this.loadingPlanExceedsCapacityAbove90APW = true;
		} else {
			this.loadingPlanExceedsCapacityAbove90APW = false;
		}
	}

	calculateAllocationTotalRequiredMPW(sharedLoadingPlanHolder: SharedLoadingPlanHolder) {
		if (!sharedLoadingPlanHolder.mpw.isOtherAllocation) {
			sharedLoadingPlanHolder.mpw.allocationTotalRequired = sharedLoadingPlanHolder.mpw.allocationMinRequired + sharedLoadingPlanHolder.mpw.additionalAllocation;
		}
		this.calculateTotalOfAllocationTotalMPW();
	}

	calculateTotalOfAllocationTotalMPW() {
		this.totalOfAllocationTotalMPW = 0;
		this.sharedLoadingPlanHolderList.forEach((sharedLoadingPlanHolder, index) => {
			this.totalOfAllocationTotalMPW = this.totalOfAllocationTotalMPW + sharedLoadingPlanHolder.mpw.allocationTotalRequired;
			this.showLoadingPlanExceedMsgMPW();
		});
		this.calculateTotalOfAllocationTotalCapacityMPW();
	}

	showLoadingPlanExceedMsgMPW() {
		if (this.selectedProcessName && this.selectedProcessName.id !== 0 && this.totalOfAllocationTotalMPW > 100) {
			this.loadingPlanExceedsCapacityAbove100MPW = true;
		} else {
			this.loadingPlanExceedsCapacityAbove100MPW = false;
		}

		if (this.totalOfAllocationTotalMPW > 90 && this.totalOfAllocationTotalMPW < 100) {
			this.loadingPlanExceedsCapacityAbove90MPW = true;
		} else {
			this.loadingPlanExceedsCapacityAbove90MPW = false;
		}
	}

	calculateTotalReqGoodPartsPerWeekAPW() {
		this.totalReqGoodPartsAPW = 0;
		this.sharedLoadingPlanHolderList.forEach((sharedLoadingPlanHolder, index) => {
			this.totalReqGoodPartsAPW = this.totalReqGoodPartsAPW + sharedLoadingPlanHolder.apw.reqGoodPartsPerWeek;
		});
		this.calculateTotalReqGoodPartsPerWeekCapacityAPW();
	}

	calculateTotalReqGoodPartsPerWeekMPW() {
		this.totalReqGoodPartsMPW = 0;
		this.sharedLoadingPlanHolderList.forEach((sharedLoadingPlanHolder, index) => {
			this.totalReqGoodPartsMPW = this.totalReqGoodPartsMPW + sharedLoadingPlanHolder.mpw.reqGoodPartsPerWeek;
		});
		this.calculateTotalReqGoodPartsPerWeekCapacityMPW();
	}

	calculateTotalTimeReqAPW() {
		this.totalTimeReqAPW = 0;
		this.sharedLoadingPlanHolderList.forEach((sharedLoadingPlanHolder, index) => {
			this.totalTimeReqAPW = this.totalTimeReqAPW + sharedLoadingPlanHolder.apw.timeReq;
		});
		this.calculateTotalTimeReqCapacityAPW();
	}

	calculateTotalTimeReqMPW() {
		this.totalTimeReqMPW = 0;
		this.sharedLoadingPlanHolderList.forEach((sharedLoadingPlanHolder, index) => {
			this.totalTimeReqMPW = this.totalTimeReqMPW + sharedLoadingPlanHolder.mpw.timeReq;
		});
		this.calculateTotalTimeReqCapacityMPW();
	}

	switchOtherAllocation(sharedLoadingPlanHolder: SharedLoadingPlanHolder) {
		sharedLoadingPlanHolder.mpw.isOtherAllocation = !sharedLoadingPlanHolder.mpw.isOtherAllocation;
		this.resetCurrentSharedLoadingPlanAPW(sharedLoadingPlanHolder);
		this.resetCurrentSharedLoadingPlanMPW(sharedLoadingPlanHolder);
		this.calculateTotalOfAllocationTotalDirect();
	}

	private resetCurrentSharedLoadingPlanMPW(sharedLoadingPlanHolder: SharedLoadingPlanHolder) {
		sharedLoadingPlanHolder.mpw.reqGoodPartsPerWeek = 0;
		sharedLoadingPlanHolder.mpw.nict = 0;
		sharedLoadingPlanHolder.mpw.timeReq = 0;
		sharedLoadingPlanHolder.mpw.toolChangeoverTime = 0;
		sharedLoadingPlanHolder.mpw.allocationMinRequired = 0;
		sharedLoadingPlanHolder.mpw.additionalAllocation = 0;
		sharedLoadingPlanHolder.mpw.allocationTotalRequired = 0;
	}

	private resetCurrentSharedLoadingPlanAPW(sharedLoadingPlanHolder: SharedLoadingPlanHolder) {
		sharedLoadingPlanHolder.apw.reqGoodPartsPerWeek = 0;
		sharedLoadingPlanHolder.apw.nict = 0;
		sharedLoadingPlanHolder.apw.timeReq = 0;
		sharedLoadingPlanHolder.apw.toolChangeoverTime = 0;
		sharedLoadingPlanHolder.apw.allocationMinRequired = 0;
		sharedLoadingPlanHolder.apw.additionalAllocation = 0;
		sharedLoadingPlanHolder.apw.allocationTotalRequired = 0;
	}

	validateSharedLoadingPlanTextBox() {
		let isValid = true;
		this.sharedLoadingPlanHolderList.forEach((sharedLoadingPlanHolder, index) => {
			if (!sharedLoadingPlanHolder.apw.vehLine || sharedLoadingPlanHolder.apw.vehLine.trim().length === 0) {
				this.utilService.pushMsg('error', 'Error', 'Row : ' + (index + 1) + ', Please enter Ford Vehicle Line / Application or enter "Non-Ford".');
				isValid = false;
			}
			if (!sharedLoadingPlanHolder.apw.isOtherAllocation) {
				//prefix
				if (!sharedLoadingPlanHolder.apw.partNamePrefix || sharedLoadingPlanHolder.apw.partNamePrefix.trim().length === 0) {
					this.utilService.pushMsg('error', 'Error', 'Row : ' + (index + 1) + ', Please enter Part Number Prefix or "N/A".');
					isValid = false;
				}

				if (sharedLoadingPlanHolder.apw.partNamePrefix && sharedLoadingPlanHolder.apw.partNamePrefix.trim().length !== 0 && sharedLoadingPlanHolder.apw.partNamePrefix.includes('-')) {
					this.utilService.pushMsg('error', 'Error', 'Row : ' + (index + 1) + ', Please remove hyphen \'-\' in Part Number Prefix.');
					isValid = false;
				}
				//base
				if (!sharedLoadingPlanHolder.apw.partNameBase || sharedLoadingPlanHolder.apw.partNameBase.trim().length === 0) {
					this.utilService.pushMsg('error', 'Error', 'Row : ' + (index + 1) + ', Please enter Part Number Base or "N/A".');
					isValid = false;
				}

				if (sharedLoadingPlanHolder.apw.partNameBase && sharedLoadingPlanHolder.apw.partNameBase.trim().length !== 0 && sharedLoadingPlanHolder.apw.partNameBase.includes('-')) {
					this.utilService.pushMsg('error', 'Error', 'Row : ' + (index + 1) + ', Please remove hyphen \'-\' in Part Number Base.');
					isValid = false;
				}
				//suffix
				if (!sharedLoadingPlanHolder.apw.partNameSuffix || sharedLoadingPlanHolder.apw.partNameSuffix.trim().length === 0) {
					this.utilService.pushMsg('error', 'Error', 'Row : ' + (index + 1) + ', Please enter Part Number Suffix or "N/A".');
					isValid = false;
				}
				if (sharedLoadingPlanHolder.apw.partNameSuffix && sharedLoadingPlanHolder.apw.partNameSuffix.trim().length !== 0 && sharedLoadingPlanHolder.apw.partNameSuffix.includes('-')) {
					this.utilService.pushMsg('error', 'Error', 'Row : ' + (index + 1) + ', Please remove hyphen \'-\' in Part Number Suffix.');
					isValid = false;
				}

				if (sharedLoadingPlanHolder.apw.reqGoodPartsPerWeek === 0 || !sharedLoadingPlanHolder.apw.reqGoodPartsPerWeek) {
					this.utilService.pushMsg('error', 'Error', 'Row : ' + (index + 1) + ', The APW Req\'d Good Parts / Week should not be 0 or empty.');
					isValid = false;
				}
				if (sharedLoadingPlanHolder.apw.nict === 0 || !sharedLoadingPlanHolder.apw.nict) {
					this.utilService.pushMsg('error', 'Error', 'Row : ' + (index + 1) + ', The APW NICT  [Ford Part # or Non-Ford] should not be 0 or empty.');
					isValid = false;
				}
				if (sharedLoadingPlanHolder.apw.toolChangeoverTime === null || sharedLoadingPlanHolder.apw.toolChangeoverTime.toString().trim() === '') {
					this.utilService.pushMsg('error', 'Error', 'Row : ' + (index + 1) + ', The APW Changeover Time / Week should not be empty.');
					isValid = false;
				}
				if (sharedLoadingPlanHolder.mpw.reqGoodPartsPerWeek === 0 || !sharedLoadingPlanHolder.mpw.reqGoodPartsPerWeek) {
					this.utilService.pushMsg('error', 'Error', 'Row : ' + (index + 1) + ', The MPW Req\'d Good Parts / Week should not be 0 or empty.');
					isValid = false;
				}
				if (sharedLoadingPlanHolder.mpw.toolChangeoverTime === null || sharedLoadingPlanHolder.mpw.toolChangeoverTime.toString().trim() === '') {
					this.utilService.pushMsg('error', 'Error', 'Row : ' + (index + 1) + ', The MPW Changeover Time / Week should not be empty.');
					isValid = false;
				}
				/* validation for capacity study fields*/
				if (sharedLoadingPlanHolder.capacityMPW) {
					if (!sharedLoadingPlanHolder.capacityAPW.reqGoodPartsPerWeek || sharedLoadingPlanHolder.capacityAPW.reqGoodPartsPerWeek === 0) {
						this.utilService.pushMsg('error', 'Error', 'Row : ' + (index + 1) + ', CAPACITY STUDY: The APW Req\'d Good Parts / Week should not be 0 or empty.');
						isValid = false;
					}
					if (!sharedLoadingPlanHolder.capacityAPW.nict || sharedLoadingPlanHolder.capacityAPW.nict === 0) {
						this.utilService.pushMsg('error', 'Error', 'Row : ' + (index + 1) + ', CAPACITY STUDY: The APW NICT  [Ford Part # or Non-Ford] should not be 0 or empty.');
						isValid = false;
					}
					if (sharedLoadingPlanHolder.capacityAPW.toolChangeoverTime === null || sharedLoadingPlanHolder.capacityAPW.toolChangeoverTime.toString().trim() === '') {
						this.utilService.pushMsg('error', 'Error', 'Row : ' + (index + 1) + ', CAPACITY STUDY: The APW Changeover Time / Week should not be empty.');
						isValid = false;
					}
					if (!sharedLoadingPlanHolder.capacityMPW.reqGoodPartsPerWeek || sharedLoadingPlanHolder.capacityMPW.reqGoodPartsPerWeek === 0) {
						this.utilService.pushMsg('error', 'Error', 'Row : ' + (index + 1) + ', CAPACITY STUDY: The MPW Req\'d Good Parts / Week should not be 0 or empty.');
						isValid = false;
					}
					if (sharedLoadingPlanHolder.capacityMPW.toolChangeoverTime === null || sharedLoadingPlanHolder.capacityMPW.toolChangeoverTime.toString().trim() === '') {
						this.utilService.pushMsg('error', 'Error', 'Row : ' + (index + 1) + ', CAPACITY STUDY: The MPW Changeover Time / Week should not be empty.');
						isValid = false;
					}
				}
			}
		});
		return isValid;
	}

	validateSharedLoadingTextBox() {
		let isValid = true;
		if (this.selectedSharedLoadingAPW.daysPerWeek === 0 || !this.selectedSharedLoadingAPW.daysPerWeek) {
			this.utilService.pushMsg('error', 'Error', 'The APW Days/Week should not be 0 or empty.');
			isValid = false;
		}
		if (this.selectedSharedLoadingAPW.daysPerWeek > 7 || !this.selectedSharedLoadingAPW.daysPerWeek) {
			this.utilService.pushMsg('error', 'Error', 'The APW Days/Week should not be greater than 7 days.');
			isValid = false;
		}
		if (this.selectedSharedLoadingAPW.shiftsPerDay === 0 || !this.selectedSharedLoadingAPW.shiftsPerDay) {
			this.utilService.pushMsg('error', 'Error', 'The APW Shift/Days should not be 0 or empty.');
			isValid = false;
		}
		if (this.selectedSharedLoadingAPW.hrsPerShift === 0 || !this.selectedSharedLoadingAPW.hrsPerShift) {
			this.utilService.pushMsg('error', 'Error', 'The APW Total Hrs/Shift should not be 0 or empty.');
			isValid = false;
		}
		if (this.selectedSharedLoadingMPW.daysPerWeek === 0 || !this.selectedSharedLoadingMPW.daysPerWeek) {
			this.utilService.pushMsg('error', 'Error', 'The MPW Days/Week should not be 0 or empty.');
			isValid = false;
		}
		if (this.selectedSharedLoadingMPW.daysPerWeek > 7 || !this.selectedSharedLoadingMPW.daysPerWeek) {
			this.utilService.pushMsg('error', 'Error', 'The MPW Days/Week should not be greater than 7 days.');
			isValid = false;
		}
		if (this.selectedSharedLoadingMPW.shiftsPerDay === 0 || !this.selectedSharedLoadingMPW.shiftsPerDay) {
			this.utilService.pushMsg('error', 'Error', 'The MPW Shift/Days should not be 0 or empty.');
			isValid = false;
		}
		if (this.selectedSharedLoadingMPW.hrsPerShift === 0 || !this.selectedSharedLoadingMPW.hrsPerShift) {
			this.utilService.pushMsg('error', 'Error', 'The MPW Total Hrs/Shift should not be 0 or empty.');
			isValid = false;
		}
		return isValid;
	}

	validateSiteCodeProcessName() {
		this.sLPAlreadyExistsErrorDisplay = false;
		if (this.selectedSiteCode && this.selectedProcessName && this.selectedSiteCode.code !== '' && this.selectedProcessName.id !== 0) {
			this.disableDataFields = false;
			this.disableUpload = false;
			this.sharedLoadingService.isValidSiteCodeAndProcessId(this.selectedSiteCode.code, this.selectedProcessName.id).subscribe(isValid => {
				if (!isValid) {
					this.sLPAlreadyExistsErrorDisplay = true;
				}
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		} else {
			this.disableDataFields = true;
		}
	}

	resetFields() {
		if (!this.disableSubmitForCopy) {
			this.selectedSharedLoadingAPW = new SharedLoading(emptySL);
			this.selectedSharedLoadingMPW = new SharedLoading(emptySL);
			this.sharedLoadingPlanHolderList = [];
			this.initializeEmpty();
			this.displayNatAPW(this.selectedSharedLoadingAPW);
			this.displayNatMPW(this.selectedSharedLoadingMPW);
		}
	}

	onSiteCodeChange() {
		this.isSiteCodeAvailableInSIM(this.selectedSiteCode.code);
		this.sharedLoadingService.findAllProcessNamesWithoutHavingSLP(this.selectedSiteCode.code).subscribe((response: ProcessName[]) => {
			this.availableProcessNames = response;
			if (this.availableProcessNames && this.availableProcessNames.length > 0) {
				this.availableProcessNames = this.availableProcessNames.filter(processName => processName.active === true);
				this.availableProcessNames = this.availableProcessNames.sort(function (a, b) {
					return a.processName.localeCompare(b.processName, undefined, {
						numeric: true,
						sensitivity: 'base'
					});
				});
				this.availableProcessNames.unshift(emptyProcessNameDropDown);
			}
		});
	}

	onChangeSiteCode() {
		this.findAllProcessNamesBySiteCode();
	}

	private findAllProcessNamesBySiteCode() {
		this.processNameService.findAllProcessBySiteCode(this.selectedSiteCode.code).subscribe((response: ProcessName[]) => {
			this.availableProcessNames = response;
			if (this.mode === 'edit' || this.mode === 'view') {
				this.selectedProcessName = this.availableProcessNames.find(processName => processName.id === this.selectedSharedLoadingRoot.processNameId);
				this.loadHistoricalMfgPerf();
			} else {
				this.availableProcessNames = this.availableProcessNames.filter(processName => processName.active === true);
				this.availableProcessNames = this.availableProcessNames.sort(function (a, b) {
					return a.processName.localeCompare(b.processName, undefined, {
						numeric: true,
						sensitivity: 'base'
					});
				});
			}
			this.availableProcessNames.unshift(emptyProcessNameDropDown);
			if (this.selectedSiteCode && this.selectedProcessName && this.selectedSiteCode.code !== '' && this.selectedProcessName.id !== 0) {
				this.disableDataFields = false;
			} else {
				this.disableDataFields = true;
			}
		});
	}

	private retrieveSharedLoadingByRootIdInEditMode() {
		if (this.sharedLoadingRootId && this.processNameId) {
			if (this.isCarStatusSubmitted) {
				console.log('getting Transaction Table data');
				this.showSubmitButton = false;
				this.sharedLoadingService.findSharedLoadingBycarIdFromTransaction(this.carReqId, this.processNameId).subscribe(value => {
					this.isSiteCodeAvailableInSIM(value.siteCode);
					setTimeout(() => {
						this.checkNonSiteUser(sessionStorage.getItem('userId'), JSON.parse(sessionStorage.getItem('attributes')).userRole);
						this.updateSHValues(value);
						this.displayChartLink = true;
					}, 2000);
				}, error => {
					this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				});
			} else {
				console.log('getting master Table data');
				if (this.viewMode === 'Y') {
					this.showSubmitButton = false;
					const loggedInUser = JSON.parse(sessionStorage.getItem('attributes')).userRole;
					if (loggedInUser && loggedInUser !== '' && loggedInUser.toLowerCase() === 'ecar_update') {
						this.isViewMode = true;
					}
				}
				this.sharedLoadingService.findById(this.sharedLoadingRootId).subscribe(value => {
					this.isSiteCodeAvailableInSIM(value.siteCode);
					setTimeout(() => {
						this.checkNonSiteUser(sessionStorage.getItem('userId'), JSON.parse(sessionStorage.getItem('attributes')).userRole);
						this.updateSHValues(value);
						//this.showSubmitButton = true;
						this.displayChartLink = true;
					}, 2000);
					if (value.isAlertRequired) {
						this.showAlertPopupMessage();
					}
				}, error => {
					this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				});
			}
		}
	}

	private showAlertPopupMessage() {
		if (this.mode === 'view') {
			const dialogueRefView = this.dialogService.open(OndeleteDialogueboxComponent, {
				header: 'Warning',
				width: '50%',
				contentStyle: {'max-height': '700px', 'overflow': 'auto'},
				data: {
					'message': 'CapacityStudyAlertOnViewInMSL'
				}
			});
		}
		if (this.mode === 'edit') {
			const dialogueRefEdit = this.dialogService.open(OndeleteDialogueboxComponent, {
				header: 'Warning',
				width: '50%',
				contentStyle: {'max-height': '700px', 'overflow': 'auto'},
				data: {
					'message': 'CapacityStudyAlertOnEditInMSL'
				}
			});
		}
	}

	private updateSHValues(value: SharedLoadingRoot) {
		this.selectedSharedLoadingRoot = value;
		this.slpPresentCheck();
		if (this.isNonSiteUser) {
			this.siteCodes = [];
			this.selectedSiteCode = {name: '', code: value.siteCode};
			this.siteCodes.push(this.selectedSiteCode);
		} else {
			this.selectedSiteCode = this.filterSelectedSiteCodeObject();
		}
		this.onChangeSiteCode();
	}

	private retrieveSharedLoadingBySiteCodeAndProcessNameId(siteCode: string, processNameId: string) {
		this.sharedLoadingService.retrieveSharedLoadingIdBySiteCodeAndProcessNameId(siteCode, processNameId).subscribe(value => {
			if (value) {
				this.selectedSharedLoadingRoot = value;
				this.selectedSiteCode = this.filterSelectedSiteCodeObject();
				this.onChangeSiteCode();
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	private filterSelectedSiteCodeObject() {
		return this.siteCodes.find(siteCodeObject => siteCodeObject.code === this.selectedSharedLoadingRoot.siteCode);
	}

	private loadSiteCodes() {
		this.isTokenOnlyUser = this.utilService.isUserHasOnlyTokenOnly();
		//this.siteCodes = this.siteCodeService.fetchSiteCodes();
	}

	NumberValidation(event: KeyboardEvent) {
		const ASCIICode = (event.which) ? event.which : event.keyCode;
		if (ASCIICode > 47 && ASCIICode < 58) {
			return true;
		} else {
			return false;
		}
	}

	nonZeroInputValidation(value: any): any {
		if (value === '0') {
			return null;
		} else {
			return value;
		}
	}

	private scrollToBottom(): void {
		this.scrollContainer.scroll({
			top: this.scrollContainer.scrollHeight,
			left: 0,
			behavior: 'smooth'
		});
	}

	inputNumberValidationWithDigits($event: KeyboardEvent, scale: number) {
		return this.utilService.inputNumberValidationWithDecimal(event, scale);
	}

	inputNumberValidationWithSingleDecimal($event: KeyboardEvent) {
		return this.utilService.inputNumberValidationWithSingleDecimal(event);
	}

	suppressHyphen(event: KeyboardEvent) {
		if (event.key === '-') {
			return false;
		}
	}

	saveSharedLoading(sharedLoadingPlanHolderList: SharedLoadingPlanHolder[]) {
		sharedLoadingPlanHolderList.forEach(sharedLoadingPlan => {
			if (sharedLoadingPlan.enableUndoShareLoading === true) {
				sharedLoadingPlan.enableUndoShareLoading = false;
			}
			if (sharedLoadingPlan.enableAckUndoShareLoading === true) {
				sharedLoadingPlan.enableAckUndoShareLoading = false;
				sharedLoadingPlan.enableConfirmShareLoading = true;
			}
		});
		this.sharedLoadingPlanHolderList = sharedLoadingPlanHolderList;
		if (this.isDemonOEEDiff) {
			this.selectedSharedLoadingRoot.totalAllocationPercentageAPW = this.totalOfAllocationTotalAPW;
			this.selectedSharedLoadingRoot.totalAllocationPercentageMPW = this.totalOfAllocationTotalMPW;
			this.selectedSharedLoadingRoot.totalAllocationPercentageCapacityAPW = this.totalOfAllocationTotalCapacityAPW;
			this.selectedSharedLoadingRoot.totalAllocationPercentageCapacityMPW = this.totalOfAllocationTotalCapacityMPW;
			this.selectedSharedLoadingRoot.isAlertRequired = false;
			this.selectedSharedLoadingRoot.sharedLoadingList[0].processNameId = this.selectedSharedLoadingRoot.processNameId;
			this.selectedSharedLoadingRoot.sharedLoadingList[1].processNameId = this.selectedSharedLoadingRoot.processNameId;
			this.sharedLoadingService.overwriteSharedLoading(this.selectedSharedLoadingRoot.id, this.selectedSharedLoadingRoot).subscribe((slr) => {

			}, error => {
				this.utilService.pushMsg('error', 'Error', error.error);
			});
		} else {
			this.onSubmit(true);
		}
	}

	addCapacityStudyToSharedLoadingPlan(sharedLoadingPlanHolder: SharedLoadingPlanHolder) {
		if (!sharedLoadingPlanHolder.capacityMPW) {
			sharedLoadingPlanHolder.capacityAPW = new SharedLoadingPlan(emptySLP);
			sharedLoadingPlanHolder.capacityMPW = new SharedLoadingPlan(emptySLP);
			sharedLoadingPlanHolder.capacityAPW.isApw = true;
			sharedLoadingPlanHolder.capacityMPW.isApw = false;
			sharedLoadingPlanHolder.capacityAPW.isCapacity = true;
			sharedLoadingPlanHolder.capacityMPW.isCapacity = true;
			sharedLoadingPlanHolder.capacityAPW.partName = sharedLoadingPlanHolder.apw.partName;
			sharedLoadingPlanHolder.capacityMPW.partName = sharedLoadingPlanHolder.apw.partName;
			sharedLoadingPlanHolder.capacityAPW.groupId = sharedLoadingPlanHolder.apw.groupId;
			sharedLoadingPlanHolder.capacityMPW.groupId = sharedLoadingPlanHolder.apw.groupId;
		} else {
			delete sharedLoadingPlanHolder.capacityAPW;
			delete sharedLoadingPlanHolder.capacityMPW;
		}
		//Capacity study calculations
		this.calculateTotalReqGoodPartsPerWeekCapacityAPW();
		this.calculateTotalReqGoodPartsPerWeekCapacityMPW();
		this.calculateTotalTimeReqCapacityAPW();
		this.calculateTotalTimeReqCapacityMPW();
		this.calculateTotalOfAllocationTotalCapacityAPW();
		this.calculateTotalOfAllocationTotalCapacityMPW();
		this.checkCapacityStudyAvailable();
	}

	calculateTimeRequiredCapacityAPW(sharedLoadingPlanHolderLocal: SharedLoadingPlanHolder) {
		const reqGoodPartsPerWeek = sharedLoadingPlanHolderLocal.capacityAPW.reqGoodPartsPerWeek;
		const nict = sharedLoadingPlanHolderLocal.capacityAPW.nict;
		const timeReqDemonstratedOEEAPW = (((reqGoodPartsPerWeek * nict) / 3600) / this.demonOEE) * 100;
		sharedLoadingPlanHolderLocal.capacityAPW.timeReq = timeReqDemonstratedOEEAPW;
		this.calculateAllocationMinRequiredCapacityAPW(sharedLoadingPlanHolderLocal);
		this.calculateTotalTimeReqAPW();
	}

	calculateAllocationMinRequiredCapacityAPW(sharedLoadingPlanHolderLocal: SharedLoadingPlanHolder) {
		sharedLoadingPlanHolderLocal.capacityAPW.allocationMinRequired = (sharedLoadingPlanHolderLocal.capacityAPW.timeReq / this.natAPW) * 100;
		this.calculateAllocationTotalRequiredCapacityAPW(sharedLoadingPlanHolderLocal);
	}

	calculateAllocationTotalRequiredCapacityAPW(sharedLoadingPlanHolder: SharedLoadingPlanHolder) {
		if (!sharedLoadingPlanHolder.capacityAPW.isOtherAllocation) {
			sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired = sharedLoadingPlanHolder.capacityAPW.allocationMinRequired + sharedLoadingPlanHolder.capacityAPW.additionalAllocation;
		}
		this.calculateTotalOfAllocationTotalCapacityAPW();
	}

	calculateTotalOfAllocationTotalCapacityAPW() {
		this.totalOfAllocationTotalCapacityAPW = 0;
		let capacityCnt = 0;
		this.sharedLoadingPlanHolderList.forEach((sharedLoadingPlanHolder) => {
			if (sharedLoadingPlanHolder.capacityAPW && sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired) {
				capacityCnt++;
				this.totalOfAllocationTotalCapacityAPW = this.totalOfAllocationTotalCapacityAPW + sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired;
			} else {
				this.totalOfAllocationTotalCapacityAPW = this.totalOfAllocationTotalCapacityAPW + sharedLoadingPlanHolder.apw.allocationTotalRequired;
			}
			this.showLoadingPlanExceedMsgCapacityAPW();
		});
		if (capacityCnt === 0) {
			this.totalOfAllocationTotalCapacityAPW = 0;
		}
	}

	showLoadingPlanExceedMsgCapacityAPW() {
		this.loadingPlanExceedsCapacityAbove100CapacityAPW = false;
		this.loadingPlanExceedsCapacityAbove90CapacityAPW = false;
		if (this.totalOfAllocationTotalCapacityAPW > 100) {
			this.loadingPlanExceedsCapacityAbove100CapacityAPW = true;
		} else if (this.totalOfAllocationTotalCapacityAPW > 90 && this.totalOfAllocationTotalCapacityAPW < 100) {
			this.loadingPlanExceedsCapacityAbove90CapacityAPW = true;
		}
	}

	calculateTotalReqGoodPartsPerWeekCapacityAPW() {
		this.totalReqGoodPartsCapacityAPW = 0;
		this.sharedLoadingPlanHolderList.forEach((sharedLoadingPlanHolder, index) => {
			if (sharedLoadingPlanHolder.capacityAPW && sharedLoadingPlanHolder.capacityAPW.reqGoodPartsPerWeek) {
				this.totalReqGoodPartsCapacityAPW = this.totalReqGoodPartsCapacityAPW + sharedLoadingPlanHolder.capacityAPW.reqGoodPartsPerWeek;
			} else {
				this.totalReqGoodPartsCapacityAPW = this.totalReqGoodPartsCapacityAPW + sharedLoadingPlanHolder.apw.reqGoodPartsPerWeek;
			}
		});
	}

	calculateTotalTimeReqCapacityAPW() {
		this.totalTimeReqCapacityAPW = 0;
		this.sharedLoadingPlanHolderList.forEach((sharedLoadingPlanHolder, index) => {
			if (sharedLoadingPlanHolder.capacityAPW && sharedLoadingPlanHolder.capacityAPW.timeReq) {
				this.totalTimeReqCapacityAPW = this.totalTimeReqCapacityAPW + sharedLoadingPlanHolder.capacityAPW.timeReq;
			} else {
				this.totalTimeReqCapacityAPW = this.totalTimeReqCapacityAPW + sharedLoadingPlanHolder.apw.timeReq;
			}
		});
	}

	calculateTotalTimeReqCapacityMPW() {
		this.totalTimeReqCapacityMPW = 0;
		this.sharedLoadingPlanHolderList.forEach((sharedLoadingPlanHolder, index) => {
			if (sharedLoadingPlanHolder.capacityMPW && sharedLoadingPlanHolder.capacityMPW.timeReq) {
				this.totalTimeReqCapacityMPW = this.totalTimeReqCapacityMPW + sharedLoadingPlanHolder.capacityMPW.timeReq;
			} else {
				this.totalTimeReqCapacityMPW = this.totalTimeReqCapacityMPW + sharedLoadingPlanHolder.mpw.timeReq;
			}
		});
	}

	calculateTimeRequiredCapacityMPW(sharedLoadingPlanHolderLocal: SharedLoadingPlanHolder) {
		const reqGoodPartsPerWeek = sharedLoadingPlanHolderLocal.capacityMPW.reqGoodPartsPerWeek;
		const nict = sharedLoadingPlanHolderLocal.capacityMPW.nict;
		const timeReqDemonstratedOEEMPW = (((reqGoodPartsPerWeek * nict) / 3600) / this.demonOEE) * 100;
		sharedLoadingPlanHolderLocal.capacityMPW.timeReq = timeReqDemonstratedOEEMPW;
		this.calculateTotalTimeReqCapacityMPW();
		this.calculateAllocationMinRequiredCapacityMPW(sharedLoadingPlanHolderLocal);
	}

	calculateAllocationMinRequiredCapacityMPW(sharedLoadingPlanHolderLocal: SharedLoadingPlanHolder) {
		sharedLoadingPlanHolderLocal.capacityMPW.allocationMinRequired = (sharedLoadingPlanHolderLocal.capacityMPW.timeReq / this.natMPW) * 100;
		this.calculateAllocationTotalRequiredCapacityMPW(sharedLoadingPlanHolderLocal);
	}

	calculateAllocationTotalRequiredCapacityMPW(sharedLoadingPlanHolder: SharedLoadingPlanHolder) {
		if (!sharedLoadingPlanHolder.capacityMPW.isOtherAllocation) {
			sharedLoadingPlanHolder.capacityMPW.allocationTotalRequired = sharedLoadingPlanHolder.capacityMPW.allocationMinRequired + sharedLoadingPlanHolder.capacityMPW.additionalAllocation;
		}
		this.calculateTotalOfAllocationTotalCapacityMPW();
	}

	calculateTotalOfAllocationTotalCapacityMPW() {
		this.totalOfAllocationTotalCapacityMPW = 0;
		let capacityCnt = 0;
		this.sharedLoadingPlanHolderList.forEach((sharedLoadingPlanHolder) => {
			if (sharedLoadingPlanHolder.capacityMPW && sharedLoadingPlanHolder.capacityMPW.allocationTotalRequired) {
				capacityCnt++;
				this.totalOfAllocationTotalCapacityMPW = this.totalOfAllocationTotalCapacityMPW + sharedLoadingPlanHolder.capacityMPW.allocationTotalRequired;
			} else {
				this.totalOfAllocationTotalCapacityMPW = this.totalOfAllocationTotalCapacityMPW + sharedLoadingPlanHolder.mpw.allocationTotalRequired;
			}
			this.showLoadingPlanExceedMsgCapacityMPW();
		});
		if (capacityCnt === 0) {
			this.totalOfAllocationTotalCapacityMPW = 0;
		}
	}

	showLoadingPlanExceedMsgCapacityMPW() {
		this.loadingPlanExceedsCapacityAbove100CapacityMPW = false;
		this.loadingPlanExceedsCapacityAbove90CapacityMPW = false;
		if (this.totalOfAllocationTotalCapacityMPW > 100) {
			this.loadingPlanExceedsCapacityAbove100CapacityMPW = true;
		} else if (this.totalOfAllocationTotalCapacityMPW > 90 && this.totalOfAllocationTotalCapacityMPW < 100) {
			this.loadingPlanExceedsCapacityAbove90CapacityMPW = true;
		}
	}

	calculateTotalReqGoodPartsPerWeekCapacityMPW() {
		this.totalReqGoodPartsCapacityMPW = 0;
		this.sharedLoadingPlanHolderList.forEach((sharedLoadingPlanHolder, index) => {
			if (sharedLoadingPlanHolder.capacityMPW && sharedLoadingPlanHolder.capacityMPW.reqGoodPartsPerWeek) {
				this.totalReqGoodPartsCapacityMPW = this.totalReqGoodPartsCapacityMPW + sharedLoadingPlanHolder.capacityMPW.reqGoodPartsPerWeek;
			} else {
				this.totalReqGoodPartsCapacityMPW = this.totalReqGoodPartsCapacityMPW + sharedLoadingPlanHolder.mpw.reqGoodPartsPerWeek;
			}
		});
	}

	transformCapacityAddAllocation(sharedLoadingPlanHolderList: SharedLoadingPlanHolder[], boxIndex: number, isAPW: boolean) {
		const sharedLoadingPlan = sharedLoadingPlanHolderList[boxIndex];
		if (isAPW) {
			if (isNaN(sharedLoadingPlan.capacityAPW.additionalAllocation)) {
				sharedLoadingPlan.capacityAPW.additionalAllocation = 0;
			}
			sharedLoadingPlan.capacityAPW.additionalAllocation = (sharedLoadingPlan.capacityAPW.additionalAllocation * 10) / 10;
		}
		if (!isAPW) {
			if (isNaN(sharedLoadingPlan.capacityMPW.additionalAllocation)) {
				sharedLoadingPlan.capacityMPW.additionalAllocation = 0;
			}
			sharedLoadingPlan.capacityMPW.additionalAllocation = (sharedLoadingPlan.capacityMPW.additionalAllocation * 10) / 10;
		}
		if (sharedLoadingPlan.capacityAPW.isOtherAllocation) {
			sharedLoadingPlan.capacityAPW.allocationTotalRequired = sharedLoadingPlan.capacityAPW.additionalAllocation;
		}
		if (sharedLoadingPlan.capacityMPW.isOtherAllocation) {
			sharedLoadingPlan.capacityMPW.allocationTotalRequired = sharedLoadingPlan.capacityMPW.additionalAllocation;
		}
	}

	checkCapacityStudyAvailable() {
		this.isCapacityStudyAvailable = false;
		this.sharedLoadingPlanHolderList.forEach((slPlanHolder, indx) => {
			if (slPlanHolder.capacityAPW && slPlanHolder.capacityMPW) {
				this.isCapacityStudyAvailable = true;
			}
		});
	}

	loadSLPChartData() {
		this.showChart = true;
		setTimeout(() => {
			const sharedLoadingChartTemp = _.cloneDeep(sharedLoadingChartTemplate);
			sharedLoadingChartTemp.processName = this.selectedProcessName.processName;
			const sharedLoadingAPW = this.selectedSharedLoadingRoot.sharedLoadingList[0];
			const sharedLoadingMPW = this.selectedSharedLoadingRoot.sharedLoadingList[1];

			//APW
			sharedLoadingChartTemp.apwChart = this.extractAndBuildChartData(sharedLoadingAPW);
			//MPW
			sharedLoadingChartTemp.mpwChart = this.extractAndBuildChartData(sharedLoadingMPW);
			sharedLoadingChartTemp.mpwChart.labels = sharedLoadingChartTemp.apwChart.labels;
			sharedLoadingChartTemp.mpwChart.datasets[0].backgroundColor = sharedLoadingChartTemp.apwChart.datasets[0].backgroundColor;
			sharedLoadingChartTemp.mpwChart.datasets[0].hoverBackgroundColor = sharedLoadingChartTemp.apwChart.datasets[0].hoverBackgroundColor;
			this.sharedLoadingChart = sharedLoadingChartTemp;
		}, 0);
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		});
	}

	private extractAndBuildChartData(sharedLoading: SharedLoading): ChartData {
		const chartData: ChartData = {
			'labels': [],
			'datasets': [],
			'isSharedLoadingExceeds100Percent': false,
			'height': 0
		};
		const vLineList: string [] = [];

		//Data
		const dataSetAPW = _.cloneDeep(dataSetTemplate);
		const allocPercentData = [];
		let totalAllocation = 0;
		let remainingAllocation = 0;

		//Color
		dataSetAPW.backgroundColor = [];
		dataSetAPW.hoverBackgroundColor = [];
		const isCapacityStudyScenario = this.isCapacityStudyAvailable;
		//Handling for Capacity Study Scenario
		const sharedLoadingPlanHolderList = [];
		sharedLoading.sharedLoadingPlanList.forEach((sharedLoadingPlan, index) => {
			const sharedLoadingPlanHolder = new SharedLoadingPlanHolder();
			if (sharedLoadingPlan.isCapacity) {
				let indexValue = 0;
				sharedLoadingPlanHolderList.forEach((slPlanHolder, indx) => {
					if (slPlanHolder.apw.groupId === sharedLoadingPlan.groupId) {
						indexValue = indx;
					}
				});
				sharedLoadingPlanHolderList[indexValue].capacityAPW = sharedLoadingPlan;
			} else {
				sharedLoadingPlanHolder.apw = sharedLoadingPlan;
				sharedLoadingPlanHolderList.push(sharedLoadingPlanHolder);
			}
		});

		sharedLoadingPlanHolderList.forEach((sharedLoadingPlanHolder, slpIndex) => {
			if (vLineList && vLineList.length > 0 && vLineList.includes(sharedLoadingPlanHolder.apw.vehLine)) {
				const index = vLineList.indexOf(sharedLoadingPlanHolder.apw.vehLine);
				if (isCapacityStudyScenario && sharedLoadingPlanHolder.capacityAPW && sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired) {
					allocPercentData[index] = allocPercentData[index] + _.round(sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired, 1);
					totalAllocation = totalAllocation + _.round(sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired, 1);
				} else {
					allocPercentData[index] = allocPercentData[index] + _.round(sharedLoadingPlanHolder.apw.allocationTotalRequired, 1);
					totalAllocation = totalAllocation + _.round(sharedLoadingPlanHolder.apw.allocationTotalRequired, 1);
				}
			} else {
				vLineList.push(sharedLoadingPlanHolder.apw.vehLine);
				if (isCapacityStudyScenario && sharedLoadingPlanHolder.capacityAPW && sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired) {
					allocPercentData.push(_.round(sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired, 1));
					totalAllocation = totalAllocation + _.round(sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired, 1);
				} else {
					allocPercentData.push(_.round(sharedLoadingPlanHolder.apw.allocationTotalRequired, 1));
					totalAllocation = totalAllocation + _.round(sharedLoadingPlanHolder.apw.allocationTotalRequired, 1);
				}
				dataSetAPW.backgroundColor.push(chartColors[slpIndex].backgroundColor);
				dataSetAPW.hoverBackgroundColor.push(chartColors[slpIndex].hoverBackgroundColor);
			}
		});

		vLineList.push('Free');
		this.vLineGreaterTwenty = 0;
		vLineList.forEach(vLine => {
			if (vLine.length > 21) {
				this.vLineGreaterTwenty += 1;
			}
		});
		chartData.labels = vLineList;
		totalAllocation = _.round(totalAllocation, 1);
		remainingAllocation = 100 - totalAllocation;
		if (remainingAllocation < 0) {
			chartData.isSharedLoadingExceeds100Percent = true;
		}
		remainingAllocation = _.round(remainingAllocation, 1);
		remainingAllocation = remainingAllocation <= 0 ? 0 : remainingAllocation;
		allocPercentData.push(remainingAllocation);

		dataSetAPW.data = allocPercentData;
		dataSetAPW.backgroundColor.push(chartColors[chartColors.length - 1].backgroundColor);
		dataSetAPW.hoverBackgroundColor.push(chartColors[chartColors.length - 1].hoverBackgroundColor);
		chartData.datasets[0] = dataSetAPW;
		return chartData;
	}

	checkForFreeAllocationMessage(chartData: ChartData): boolean {
		let showMessage = false;
		if (chartData && chartData.datasets && chartData.datasets.length > 0) {
			const freeAllocation = chartData.datasets[0].data[chartData.datasets[0].data.length - 1];
			if (freeAllocation > 0 && freeAllocation <= 10) {
				showMessage = true;
			}
		}
		return showMessage;
	}

	getFreeAllocation(chartData: ChartData): number {
		let freeAllocation = 0;
		if (chartData && chartData.datasets && chartData.datasets.length > 0) {
			freeAllocation = chartData.datasets[0].data[chartData.datasets[0].data.length - 1];
		}
		return freeAllocation;
	}

	private onCloseChart() {
		this.showChart = false;
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
		this.unsubscribe$.unsubscribe();
	}

	confirmStudy(sharedLoadingPlanHolder: SharedLoadingPlanHolder, boxIndex: number) {
		this.processNameService.isAnyeCARSReferringThisSLP(this.selectedSiteCode.code, this.selectedProcessName.id, sharedLoadingPlanHolder.apw.partNamePrefix.replace(/\//g, '$'), sharedLoadingPlanHolder.apw.partNameBase.replace(/\//g, '$'), sharedLoadingPlanHolder.apw.partNameSuffix.replace(/\//g, '$')).subscribe(isPresent => {
			if (isPresent) {
				const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
					header: 'Confirmation',
					width: '50%',
					contentStyle: {'max-height': '500px', 'overflow': 'auto'},
					data: {
						'message': 'DisplaySharedLoadingWhatIfConfirmWarn'
					}
				});
				deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
					if (deleteModel != null) {
						if (deleteModel.isDeleted) {
							this.confirmWhatIf(sharedLoadingPlanHolder, boxIndex);
						}
					}
				});
			} else {
				this.confirmWhatIf(sharedLoadingPlanHolder, boxIndex);
			}
		});
	}

	confirmWhatIf(sharedLoadingPlanHolder: SharedLoadingPlanHolder, boxIndex: number) {
		this.undoIds = [sharedLoadingPlanHolder.apw.id, sharedLoadingPlanHolder.mpw.id, sharedLoadingPlanHolder.capacityAPW.id, sharedLoadingPlanHolder.capacityMPW.id];
		this.undoList[boxIndex] = this.undoIds;
		sharedLoadingPlanHolder.enableUndoShareLoading = true;
		sharedLoadingPlanHolder.enableAckUndoShareLoading = false;

		sharedLoadingPlanHolder.capacityAPW.isAlertReq = false;
		sharedLoadingPlanHolder.capacityMPW.isAlertReq = false;

		sharedLoadingPlanHolder.apw.reqGoodPartsPerWeek = sharedLoadingPlanHolder.capacityAPW.reqGoodPartsPerWeek;
		sharedLoadingPlanHolder.apw.nict = sharedLoadingPlanHolder.capacityAPW.nict;
		sharedLoadingPlanHolder.apw.timeReq = sharedLoadingPlanHolder.capacityAPW.timeReq;
		sharedLoadingPlanHolder.apw.toolChangeoverTime = sharedLoadingPlanHolder.capacityAPW.toolChangeoverTime;
		sharedLoadingPlanHolder.apw.allocationMinRequired = sharedLoadingPlanHolder.capacityAPW.allocationMinRequired;
		sharedLoadingPlanHolder.apw.additionalAllocation = sharedLoadingPlanHolder.capacityAPW.additionalAllocation;
		sharedLoadingPlanHolder.apw.allocationTotalRequired = sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired;

		sharedLoadingPlanHolder.mpw.reqGoodPartsPerWeek = sharedLoadingPlanHolder.capacityMPW.reqGoodPartsPerWeek;
		sharedLoadingPlanHolder.mpw.nict = sharedLoadingPlanHolder.capacityMPW.nict;
		sharedLoadingPlanHolder.mpw.timeReq = sharedLoadingPlanHolder.capacityMPW.timeReq;
		sharedLoadingPlanHolder.mpw.toolChangeoverTime = sharedLoadingPlanHolder.capacityMPW.toolChangeoverTime;
		sharedLoadingPlanHolder.mpw.allocationMinRequired = sharedLoadingPlanHolder.capacityMPW.allocationMinRequired;
		sharedLoadingPlanHolder.mpw.additionalAllocation = sharedLoadingPlanHolder.capacityMPW.additionalAllocation;
		sharedLoadingPlanHolder.mpw.allocationTotalRequired = sharedLoadingPlanHolder.capacityMPW.allocationTotalRequired;
		this.calculateTotalReqGoodPartsPerWeekAPW();
		this.calculateTotalReqGoodPartsPerWeekMPW();
		this.calculateTotalTimeReqAPW();
		this.calculateTotalTimeReqMPW();
		this.calculateTotalOfAllocationTotalAPW();
		this.calculateTotalOfAllocationTotalMPW();
		delete sharedLoadingPlanHolder.capacityAPW;
		delete sharedLoadingPlanHolder.capacityMPW;
		this.calculateTotalReqGoodPartsPerWeekCapacityAPW();
		this.calculateTotalReqGoodPartsPerWeekCapacityMPW();
		this.calculateTotalTimeReqCapacityAPW();
		this.calculateTotalTimeReqCapacityMPW();
		this.calculateTotalOfAllocationTotalCapacityAPW();
		this.calculateTotalOfAllocationTotalCapacityMPW();

	}

	undo(sharedLoadingPlanHolder: SharedLoadingPlanHolder, boxIndex: number) {
		if (this.undoList[boxIndex] === undefined) {
			this.utilService.pushMsg('error', 'Error', 'No data to restore');
		} else {
			this.sharedLoadingService.fetchUndoList(this.undoList[boxIndex]).subscribe(value => {

				sharedLoadingPlanHolder.apw.id = value[0].id;
				sharedLoadingPlanHolder.apw.reqGoodPartsPerWeek = value[0].reqGoodPartsPerWeek;
				sharedLoadingPlanHolder.apw.nict = value[0].nict;
				sharedLoadingPlanHolder.apw.timeReq = value[0].timeReq;
				sharedLoadingPlanHolder.apw.toolChangeoverTime = value[0].toolChangeoverTime;
				sharedLoadingPlanHolder.apw.allocationMinRequired = value[0].allocationMinRequired;
				sharedLoadingPlanHolder.apw.additionalAllocation = value[0].additionalAllocation;
				sharedLoadingPlanHolder.apw.allocationTotalRequired = value[0].allocationTotalRequired;
				sharedLoadingPlanHolder.apw.isAlertReq = value[0].isAlertReq;

				sharedLoadingPlanHolder.mpw.id = value[2].id;
				sharedLoadingPlanHolder.mpw.reqGoodPartsPerWeek = value[2].reqGoodPartsPerWeek;
				sharedLoadingPlanHolder.mpw.nict = value[2].nict;
				sharedLoadingPlanHolder.mpw.timeReq = value[2].timeReq;
				sharedLoadingPlanHolder.mpw.toolChangeoverTime = value[2].toolChangeoverTime;
				sharedLoadingPlanHolder.mpw.allocationMinRequired = value[2].allocationMinRequired;
				sharedLoadingPlanHolder.mpw.additionalAllocation = value[2].additionalAllocation;
				sharedLoadingPlanHolder.mpw.allocationTotalRequired = value[2].allocationTotalRequired;
				sharedLoadingPlanHolder.mpw.isAlertReq = value[2].isAlertReq;

				sharedLoadingPlanHolder.capacityAPW = new SharedLoadingPlan(emptySLP);
				sharedLoadingPlanHolder.capacityMPW = new SharedLoadingPlan(emptySLP);
				sharedLoadingPlanHolder.capacityAPW.capacityId = value[1].capacityId;

				sharedLoadingPlanHolder.capacityAPW.id = value[1].id;
				sharedLoadingPlanHolder.capacityAPW.reqGoodPartsPerWeek = value[1].reqGoodPartsPerWeek;
				sharedLoadingPlanHolder.capacityAPW.nict = value[1].nict;
				sharedLoadingPlanHolder.capacityAPW.timeReq = value[1].timeReq;
				sharedLoadingPlanHolder.capacityAPW.toolChangeoverTime = value[1].toolChangeoverTime;
				sharedLoadingPlanHolder.capacityAPW.allocationMinRequired = value[1].allocationMinRequired;
				sharedLoadingPlanHolder.capacityAPW.additionalAllocation = value[1].additionalAllocation;
				sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired = value[1].allocationTotalRequired;
				sharedLoadingPlanHolder.capacityAPW.isCapacity = true;
				sharedLoadingPlanHolder.capacityAPW.isApw = true;
				sharedLoadingPlanHolder.capacityAPW.partName = sharedLoadingPlanHolder.apw.partName;
				sharedLoadingPlanHolder.capacityAPW.groupId = sharedLoadingPlanHolder.apw.groupId;
				sharedLoadingPlanHolder.capacityAPW.isAlertReq = value[1].isAlertReq;

				sharedLoadingPlanHolder.capacityMPW.id = value[3].id;
				sharedLoadingPlanHolder.capacityMPW.reqGoodPartsPerWeek = value[3].reqGoodPartsPerWeek;
				sharedLoadingPlanHolder.capacityMPW.nict = value[3].nict;
				sharedLoadingPlanHolder.capacityMPW.timeReq = value[3].timeReq;
				sharedLoadingPlanHolder.capacityMPW.toolChangeoverTime = value[3].toolChangeoverTime;
				sharedLoadingPlanHolder.capacityMPW.allocationMinRequired = value[3].allocationMinRequired;
				sharedLoadingPlanHolder.capacityMPW.additionalAllocation = value[3].additionalAllocation;
				sharedLoadingPlanHolder.capacityMPW.allocationTotalRequired = value[3].allocationTotalRequired;
				sharedLoadingPlanHolder.capacityMPW.isCapacity = true;
				sharedLoadingPlanHolder.capacityMPW.isApw = false;
				sharedLoadingPlanHolder.capacityMPW.partName = sharedLoadingPlanHolder.apw.partName;
				sharedLoadingPlanHolder.capacityMPW.groupId = sharedLoadingPlanHolder.apw.groupId;
				sharedLoadingPlanHolder.capacityMPW.isAlertReq = value[3].isAlertReq;
				sharedLoadingPlanHolder.enableUndoShareLoading = false;

				if (sharedLoadingPlanHolder.capacityAPW.isAlertReq) {
					sharedLoadingPlanHolder.enableAckShareLoading = true;
				} else {
					sharedLoadingPlanHolder.enableAckShareLoading = false;
				}

				this.calculateTotalReqGoodPartsPerWeekAPW();
				this.calculateTotalReqGoodPartsPerWeekMPW();
				this.calculateTotalTimeReqAPW();
				this.calculateTotalTimeReqMPW();
				this.calculateTotalOfAllocationTotalAPW();
				this.calculateTotalOfAllocationTotalMPW();

				this.calculateTotalReqGoodPartsPerWeekCapacityAPW();
				this.calculateTotalReqGoodPartsPerWeekCapacityMPW();
				this.calculateTotalTimeReqCapacityAPW();
				this.calculateTotalTimeReqCapacityMPW();
				this.calculateTotalOfAllocationTotalCapacityAPW();
				this.calculateTotalOfAllocationTotalCapacityMPW();
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}

	}

	acknowledgeProcess(sharedLoadingPlanHolder: SharedLoadingPlanHolder, boxIndex: number) {
		sharedLoadingPlanHolder.enableConfirmShareLoading = false;
		sharedLoadingPlanHolder.enableAckShareLoading = false;
		sharedLoadingPlanHolder.enableAckUndoShareLoading = true;
		sharedLoadingPlanHolder.capacityAPW.isAlertReq = false;
		sharedLoadingPlanHolder.capacityMPW.isAlertReq = false;
	}

	acknowledgeUndoProcess(sharedLoadingPlanHolder: SharedLoadingPlanHolder, boxIndex: number) {
		sharedLoadingPlanHolder.enableConfirmShareLoading = true;
		sharedLoadingPlanHolder.enableAckShareLoading = true;
		sharedLoadingPlanHolder.enableAckUndoShareLoading = false;
		sharedLoadingPlanHolder.capacityAPW.isAlertReq = true;
		sharedLoadingPlanHolder.capacityMPW.isAlertReq = true;
	}

	isSiteCodeAvailableInSIM(siteCode: string) {
		this.processNameService.isSiteCodePresentInSIM(siteCode).subscribe(isPresent => {
			this.isSitePresentInSIM = isPresent;
		});
	}

	private findAttributes() {
		if (this.loggedInUser == null || this.loggedInUser === '' && (!this.attributes)) {
			this.attributes = JSON.parse(sessionStorage.getItem('attributes'));
			if (this.attributes) {
				this.loggedInUser = this.attributes.userRole;
			} else {
				console.log('Unable to set aps attributes');
			}
		}
	}

	private buildSharedLoadingLink() {
		if (this.sharedLoadingRootId) {
			this.masterSLRLinkInvokedFromSnapshot = window.location.origin + '/#/create-shared-loading/view/' + this.processNameId.toString();
			if (this.isCarStatusSubmitted) {
				this.masterSLRLinkInvokedFromSnapshot += '/false/0/0/N/0/10';
			}
		} else {
			this.masterSLRLinkInvokedFromSnapshot = 'N/A';
		}
		if (this.masterSLRLinkInvokedFromSnapshot !== 'N/A') {
			window.open(this.masterSLRLinkInvokedFromSnapshot);
		}
	}

	private slpPresentCheck() {
		if (this.isCarStatusSubmitted) {
			if (this.selectedSharedLoadingRoot.masterSlrId) {
				this.sharedLoadingService.findById(this.selectedSharedLoadingRoot.masterSlrId).subscribe(value => {
					if (value) {
						this.slpPresent = true;
						this.processNameId = value.id.toString();
					} else {
						this.slpPresent = false;
					}
				}, error => {
					this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				});
			} else {
				this.slpPresent = false;
			}
		}
	}

	private initializePartPlanDetails() {
		this.partNumberChange = false;
		this.initialPartNames = [];
		this.planCount = this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList.length;
		this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList.forEach(slp => {
			this.initialPartNames.push(slp.partName);
		});
	}

	private checkNonSiteUser(userId: string, userRole: string) {
		if (userRole.includes('Manager') || userRole.includes('Engineer') || userRole.includes('Supervisor')) {
			this.carRequestService.isPrimeUser(userId).subscribe(isValid => {
				if (isValid) {
					this.isNonSiteUser = true;
				} else {
					this.siteCodes = this.siteCodeService.fetchSiteCodes();
					this.isNonSiteUser = false;
				}
			});
		} else if (!userRole.toLowerCase().includes('ecar') && !userRole.includes('Developer')) {
			this.isNonSiteUser = true;
		} else {
			this.siteCodes = this.siteCodeService.fetchSiteCodes();
			this.isNonSiteUser = false;
		}
	}

	downloadSLPExcelTemplate() {
		this.sharedLoadingService.downloadSLPTemplate();
	}

	roundNumber(number, decimal_digit) {
		return Math.round(number * Math.pow(10, decimal_digit)) / Math.pow(10, decimal_digit);
	}

	uploadSLPExcel(event, slpFileUpload) {
		console.log('uploading called...');
		const file = event.files[0];
		this.resetFields();
		this.sharedLoadingService.uploadFile(file, this.selectedSharedLoadingRoot.siteCode, this.selectedSharedLoadingRoot.processNameId).subscribe((value) => {
			this.selectedSharedLoadingRoot = value;
			if (this.mode !== 'edit') {
				this.selectedSharedLoadingRoot.createdBy = '';
				this.selectedSharedLoadingRoot.createdDate = 1580372096578;
				this.selectedSharedLoadingRoot.updatedBy = '';
				this.selectedSharedLoadingRoot.updatedDate = 1580372096578;
			} else {
				this.selectedSharedLoadingRoot.updatedBy = '';
				this.selectedSharedLoadingRoot.updatedDate = 1580372096578;
			}

			this.selectedSharedLoadingAPW.daysPerWeek = this.selectedSharedLoadingRoot.sharedLoadingList[0].daysPerWeek;
			this.selectedSharedLoadingAPW.shiftsPerDay = this.selectedSharedLoadingRoot.sharedLoadingList[0].shiftsPerDay;
			this.selectedSharedLoadingAPW.hrsPerShift = this.selectedSharedLoadingRoot.sharedLoadingList[0].hrsPerShift;
			this.selectedSharedLoadingAPW.contPlanned = this.selectedSharedLoadingRoot.sharedLoadingList[0].contPlanned;
			if (this.demonOEE === this.roundNumber(this.selectedSharedLoadingRoot.demonstratedOEE * 100, 1)) {
				this.demonOEE = this.roundNumber(this.selectedSharedLoadingRoot.demonstratedOEE * 100, 1);
				this.isDemonOEEDiff = false;
			} else {
				this.isDemonOEEDiff = true;
			}
			this.selectedSharedLoadingRoot.demonstratedOEE = 0;
			this.selectedSharedLoadingMPW.daysPerWeek = this.selectedSharedLoadingRoot.sharedLoadingList[1].daysPerWeek;
			this.selectedSharedLoadingMPW.shiftsPerDay = this.selectedSharedLoadingRoot.sharedLoadingList[1].shiftsPerDay;
			this.selectedSharedLoadingMPW.hrsPerShift = this.selectedSharedLoadingRoot.sharedLoadingList[1].hrsPerShift;
			this.selectedSharedLoadingMPW.contPlanned = this.selectedSharedLoadingRoot.sharedLoadingList[1].contPlanned;


			let i = 0;
			for (let part = 0; part < this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList.length; part++) {
				if (this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[part].isCapacity) {
					for (let parentPart = 0; parentPart < this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList.length; parentPart++) {
						if (this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[part].partName === this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[parentPart].partName) {
							this.indexDuplicate[i] = part - this.indexDuplicate.length;
							i += 1;
							this.sharedLoadingPlanHolderList[parentPart].capacityAPW = this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[part];
							this.sharedLoadingPlanHolderList[parentPart].capacityAPW.additionalAllocation *= 100;
							this.sharedLoadingPlanHolderList[parentPart].capacityAPW.groupId = this.sharedLoadingPlanHolderList[parentPart].apw.groupId;
							this.sharedLoadingPlanHolderList[parentPart].capacityAPW.capacityId = this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[part].capacityId;
							this.sharedLoadingPlanHolderList[parentPart].capacityAPW.createdBy = '';
							this.sharedLoadingPlanHolderList[parentPart].capacityAPW.createdDate = 1580372096578;
							this.sharedLoadingPlanHolderList[parentPart].capacityAPW.updatedBy = '';
							this.sharedLoadingPlanHolderList[parentPart].capacityAPW.updatedDate = 1580372096578;
							this.sharedLoadingPlanHolderList[parentPart].capacityAPW.isCapacity = this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[part].isCapacity;
							this.sharedLoadingPlanHolderList[parentPart].capacityAPW.isOtherAllocation = this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[part].isOtherAllocation;
							const partNames1 = this.sharedLoadingPlanHolderList[parentPart].capacityAPW.partName.split('-');
							if (partNames1.length > 2) {
								this.sharedLoadingPlanHolderList[parentPart].capacityAPW.partNamePrefix = partNames1[0].trim();
								this.sharedLoadingPlanHolderList[parentPart].capacityAPW.partNameBase = partNames1[1].trim();
								this.sharedLoadingPlanHolderList[parentPart].capacityAPW.partNameSuffix = partNames1[2].trim();
							}
							this.sharedLoadingPlanHolderList[parentPart].capacityMPW = this.selectedSharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[part];
							this.sharedLoadingPlanHolderList[parentPart].capacityMPW.additionalAllocation *= 100;
							this.sharedLoadingPlanHolderList[parentPart].capacityMPW.groupId = this.sharedLoadingPlanHolderList[parentPart].mpw.groupId;
							this.sharedLoadingPlanHolderList[parentPart].capacityMPW.capacityId = this.selectedSharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[part].capacityId;
							this.sharedLoadingPlanHolderList[parentPart].capacityMPW.createdBy = '';
							this.sharedLoadingPlanHolderList[parentPart].capacityMPW.createdDate = 1580372096578;
							this.sharedLoadingPlanHolderList[parentPart].capacityMPW.updatedBy = '';
							this.sharedLoadingPlanHolderList[parentPart].capacityMPW.updatedDate = 1580372096578;
							this.sharedLoadingPlanHolderList[parentPart].capacityMPW.isCapacity = this.selectedSharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[part].isCapacity;
							this.sharedLoadingPlanHolderList[parentPart].capacityMPW.isOtherAllocation = this.selectedSharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[part].isOtherAllocation;
							this.sharedLoadingPlanHolderList[parentPart].capacityMPW.partNamePrefix = '';
							this.sharedLoadingPlanHolderList[parentPart].capacityMPW.partNameBase = '';
							this.sharedLoadingPlanHolderList[parentPart].capacityMPW.partNameSuffix = '';
							break;
						}
					}
				} else {
					this.sharedLoadingPlanHolderList[part].apw = this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[part];
					this.sharedLoadingPlanHolderList[part].apw.additionalAllocation *= 100;
					this.sharedLoadingPlanHolderList[part].apw.groupId = part;
					this.sharedLoadingPlanHolderList[part].apw.capacityId = '';
					this.sharedLoadingPlanHolderList[part].apw.createdBy = '';
					this.sharedLoadingPlanHolderList[part].apw.createdDate = 1580372096578;
					this.sharedLoadingPlanHolderList[part].apw.updatedBy = '';
					this.sharedLoadingPlanHolderList[part].apw.updatedDate = 1580372096578;
					this.sharedLoadingPlanHolderList[part].apw.isCapacity = this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[part].isCapacity;
					this.sharedLoadingPlanHolderList[part].apw.isOtherAllocation = this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[part].isOtherAllocation;
					if (this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[part].allocationTotalRequired) {
						this.sharedLoadingPlanHolderList[part].apw.allocationTotalRequired = this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[part].allocationTotalRequired;
					} else {
						this.sharedLoadingPlanHolderList[part].apw.allocationTotalRequired = 0;
					}

					const partNames = this.sharedLoadingPlanHolderList[part].apw.partName.split('-');
					if (partNames.length > 2) {
						this.sharedLoadingPlanHolderList[part].apw.partNamePrefix = partNames[0].trim();
						this.sharedLoadingPlanHolderList[part].apw.partNameBase = partNames[1].trim();
						this.sharedLoadingPlanHolderList[part].apw.partNameSuffix = partNames[2].trim();
					}

					this.sharedLoadingPlanHolderList[part].mpw = this.selectedSharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[part];
					this.sharedLoadingPlanHolderList[part].mpw.additionalAllocation *= 100;
					this.sharedLoadingPlanHolderList[part].mpw.groupId = part;
					this.sharedLoadingPlanHolderList[part].mpw.capacityId = '';
					this.sharedLoadingPlanHolderList[part].mpw.createdBy = '';
					this.sharedLoadingPlanHolderList[part].mpw.createdDate = 1580372096578;
					this.sharedLoadingPlanHolderList[part].mpw.updatedBy = '';
					this.sharedLoadingPlanHolderList[part].mpw.updatedDate = 1580372096578;
					this.sharedLoadingPlanHolderList[part].mpw.isCapacity = this.selectedSharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[part].isCapacity;
					this.sharedLoadingPlanHolderList[part].mpw.isOtherAllocation = this.selectedSharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[part].isOtherAllocation;
					if (this.selectedSharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[part].allocationTotalRequired) {
						this.sharedLoadingPlanHolderList[part].mpw.allocationTotalRequired = this.selectedSharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[part].allocationTotalRequired;
					} else {
						this.sharedLoadingPlanHolderList[part].mpw.allocationTotalRequired = 0;
					}
					this.sharedLoadingPlanHolderList[part].mpw.partNamePrefix = '';
					this.sharedLoadingPlanHolderList[part].mpw.partNameBase = '';
					this.sharedLoadingPlanHolderList[part].mpw.partNameSuffix = '';
				}

				this.displayNatAPW(this.selectedSharedLoadingAPW);
				this.displayNatMPW(this.selectedSharedLoadingMPW);
				this.calculateTimeRequiredAPW(this.sharedLoadingPlanHolderList[part]);
				this.calculateTimeRequiredMPW(this.sharedLoadingPlanHolderList[part]);

				if (part < this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList.length - 1) {
					this.addSHPlanBox();
				}

				this.calculateTotalReqGoodPartsPerWeekAPW();
				this.calculateTotalReqGoodPartsPerWeekMPW();
				this.calculateTotalTimeReqAPW();
				this.calculateTotalTimeReqMPW();
				this.calculateTotalOfAllocationTotalAPW();
				this.calculateTotalOfAllocationTotalMPW();

				this.calculateTotalReqGoodPartsPerWeekCapacityAPW();
				this.calculateTotalReqGoodPartsPerWeekCapacityMPW();
				this.calculateTotalTimeReqCapacityAPW();
				this.calculateTotalTimeReqCapacityMPW();
				this.calculateTotalOfAllocationTotalCapacityAPW();
				this.calculateTotalOfAllocationTotalCapacityMPW();
			}

			if (this.indexDuplicate.length > 0) {
				this.indexDuplicate.forEach(index => {
					this.sharedLoadingPlanHolderList.splice(index, 1);
				});
			}
			if (!this.isDemonOEEDiff && ((this.sharedLoadingPlanHolderList.length === this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList.length) || (this.sharedLoadingPlanHolderList.length === this.selectedSharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList.length - this.indexDuplicate.length))) {
				if (this.mode === 'edit') {
					this.selectedSharedLoadingRoot.totalAllocationPercentageAPW = this.totalOfAllocationTotalAPW;
					this.selectedSharedLoadingRoot.totalAllocationPercentageMPW = this.totalOfAllocationTotalMPW;
					this.selectedSharedLoadingRoot.totalAllocationPercentageCapacityAPW = this.totalOfAllocationTotalCapacityAPW;
					this.selectedSharedLoadingRoot.totalAllocationPercentageCapacityMPW = this.totalOfAllocationTotalCapacityMPW;
					this.selectedSharedLoadingRoot.isAlertRequired = false;
					this.selectedSharedLoadingRoot.sharedLoadingList[0].processNameId = this.selectedSharedLoadingRoot.processNameId;
					this.selectedSharedLoadingRoot.sharedLoadingList[1].processNameId = this.selectedSharedLoadingRoot.processNameId;
					this.sharedLoadingService.overwriteSharedLoading(this.selectedSharedLoadingRoot.id, this.selectedSharedLoadingRoot).subscribe((slr) => {
						this.router.navigate(['manage-shared-loading']);
					}, error => {
						this.utilService.pushMsg('error', 'Error', error.error);
					});
				} else {
					this.onSubmit(false);
				}
			}


		}, error => {
			if (error.error.error.messages[0].includes('Uploaded Template is not valid.')) {
				this.utilService.pushMsg('error', 'Error', error.error.error.messages[0]);
			} else {
				this.utilService.pushMsg('error', 'Error', 'Upload Failed, Please review template.');
			}
		});
		slpFileUpload.clear();
	}
}

import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {
	chartColors,
	ChartData,
	dataSetTemplate, linkColors,
	OEEChart,
	oeeChartTemplate,
	SharedLoadingChart,
	sharedLoadingChartTemplate
} from '../models/chart-data';
import {ActivatedRoute} from '@angular/router';
import {CarRequestService} from '../services/request/car-request.service';
import {CarRequest, deviationReasonDropdown} from '../models/car-request';
import {ManageSubmissionScenario} from '../models/manage-submission-scenario';
import {emptyProcessStreamModel, ProcessStream} from '../models/process-stream';
import {ManageSubmissionScenarioService} from '../services/master/manage-submission-scenario/manage-submission-scenario.service';
import {RequestStateHistory} from '../models/request-state-history';
import {HistoricalMfgPerf} from '../models/historical-mfgperf';
import {DemonOEEMap} from '../models/processid-demon-oee';
import {HistoricalMfgPerfService} from '../services/master/historical-mfgperf/historical-mfgperf.service';
import {UtilService} from '../services/util.service';
import {PsPnMapTran} from '../models/pspnmaptran';
import * as moment from 'moment';
import {DatePipe} from '@angular/common';
import * as _ from 'lodash';
import {ChartService} from '../services/chart/chart.service';
import {ProcessStreamService} from '../services/master/process-stream/process-stream.service';
import {SharedLoadingRoot} from '../models/shared-loading-root';
import {SharedLoading} from '../models/shared-loading';
import {ManageSharedLoadingService} from '../services/master/manage-shared-loading/manage-shared-loading.service';
import {jsPlumb} from 'jsplumb';
import {ProcessData} from '../models/process-data';
import {DeclarationQuestionsTran} from '../models/declaration-question-trans';
import {SharedLoadingPlanHolder} from '../models/shared-loading-plan';
import {ProcessNameService} from '../services/master/process-name/process-name.service';
import {PrimarySupportingMapTran} from '../models/primary-supporting-map-tran';
import {ProcessName} from '../models/process-name';


@Component({
	selector: 'ecar-print',
	templateUrl: './ecar-print.component.html',
	styleUrls: ['./ecar-print.component.css']
})
export class EcarPrintComponent implements OnInit, AfterViewInit, OnDestroy {
	carRequest: CarRequest;
	carId: number;
	selectedPrimaryStream: ProcessStream = emptyProcessStreamModel;

	demonOEEList: DemonOEEMap[] = [];

	allProcessContainsAnyErrors = 'Valid';
	declarationStatus = 'OK';
	public HstMfgPrimaryStreamChart: OEEChart[] = [];
	public HstMfgSupportingStreamCharts: OEEChart[][] = [];

	primaryProcessStreamChart: OEEChart;
	supportingProcessStreamChart: OEEChart[] = [];

	cols3: any[];
	pspnMap: any[];

	options: any;
	reqStateHistories: RequestStateHistory[];

	//viewProces Stream
	processStreamTree: ProcessStream[] = [];
	isConnectionMade = false;
	jsPlumbInstance;
	//viewProces Stream ends

	//SLP Chart
	isThereAnySHChartAvailable = false;
	isSharedLoadingExistInPS = false;
	isSharedLoadingExistInSS = false;
	sharedLoadingChartForPrimaryStream: SharedLoadingChart[];
	sharedLoadingChartForSupportingStream: SharedLoadingChart[][];
	sharedLoadingRoots: SharedLoadingRoot[];

	//SLP Chart ends

	processDataList: ProcessData[];
	supportingProcessDataList: any[];
	apsAttributes: any;
	hideCapacityStudyElements: boolean;
	bottleNeckProcessNameApw: string[] = [];
	bottleNeckProcessNameMpw: string[] = [];
	hideProcessDetailsValidation: boolean;

	totalAllocationPercentageGreaterThan100 = false;
	containAssumptionsHasFalse = false;

	pspnMapTabSplit: any[];
	processDataListArray: any[];
	processDataListObj: any[];
	isCapacityStudyScenario = false;
	historicalMfgPerfObj: any;
	historicalmfgperfs: HistoricalMfgPerf[];

	constructor(private activatedRoute: ActivatedRoute, private carRequestService: CarRequestService, private manageSubmissionScenarioService: ManageSubmissionScenarioService, private hmpService: HistoricalMfgPerfService, private utilService: UtilService, private datePipe: DatePipe, private chartService: ChartService, private processStreamService: ProcessStreamService, private sharedLoadingService: ManageSharedLoadingService, private processNameService: ProcessNameService) {
	}

	ngOnInit() {
		this.carRequestService.hideBulletinsOnPrintPreview(true);
		this.processDataList = [];
		this.retrieveRouteParameter();
		this.jsPlumbInstance = jsPlumb.getInstance();
		this.apsAttributes = JSON.parse(sessionStorage.getItem('attributes'));
		this.carRequestService.findById(this.carId).subscribe(value => {
			this.carRequest = value;
			/*if (this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
				this.carRequest.psPnMapTransList.forEach(psPnTrans => {
					this.demonOEECalculate(psPnTrans, this.carRequest.siteCode);
				});
			}
			if (this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
				this.carRequest.primarySupportingMapTranList.forEach( priSupp => {
					priSupp.psPnMapTranList.forEach(psPnTrans => {
						this.demonOEECalculate(psPnTrans, this.carRequest.siteCode);
					});
				});
			}*/
			this.isCapacityStudyScenario = this.carRequest.submissionScenarioObject && this.carRequest.submissionScenarioObject['capacityStudy'] === true && this.carRequest.capacityRequirementSource === 'What-If' ? true : false;
			this.manageSubmissionScenarioService.findById(this.carRequest.submissionScenario).subscribe((scenario: ManageSubmissionScenario) => {
				this.carRequest.submissionScenarioObject = scenario;
				this.carRequest.scenarioName = this.carRequest.submissionScenarioObject.templateName;
				const reversedPsPnMapTransList = _.reverse(this.carRequest.psPnMapTransList);
				if (reversedPsPnMapTransList) {
					reversedPsPnMapTransList.forEach(psPnMapTran => {
						psPnMapTran = this.validateProcessDataList(psPnMapTran);
						this.processDataList.push(psPnMapTran.processDataList[0]);
						this.processDataList.push(psPnMapTran.processDataList[1]);
					});
					this.processDataListObj = [];
					this.processDataListArray = [];
					this.processDataList.forEach(pData => {
						if (this.processDataListObj.length < 29) {
							this.processDataListObj.push(pData);
						} else {
							this.processDataListObj.push(pData);
							this.processDataListArray.push(this.processDataListObj);
							this.processDataListObj = [];
						}
					});
					this.processDataListArray.push(this.processDataListObj);
					console.log(this.processDataList);
					this.supportingProcessDataList = [];
					if (this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
						this.carRequest.primarySupportingMapTranList.forEach(supportingMap => {
							let supportingProcess = {};
							let processNameList = [];
							let processDataList = [];
							let matchFlag = false;
							_.reverse(supportingMap.psPnMapTranList);
							supportingMap.psPnMapTranList.forEach(supportingPspnMap => {
								if (processNameList.length < 14) {
									processNameList.push(supportingPspnMap.processName);
									supportingPspnMap = this.validateProcessDataList(supportingPspnMap);
									processDataList.push(supportingPspnMap.processDataList[0]);
									processDataList.push(supportingPspnMap.processDataList[1]);
								} else {
									processNameList.push(supportingPspnMap.processName);
									supportingPspnMap = this.validateProcessDataList(supportingPspnMap);
									processDataList.push(supportingPspnMap.processDataList[0]);
									processDataList.push(supportingPspnMap.processDataList[1]);
									this.supportProcessDataConstruction(processNameList, processDataList, supportingMap, matchFlag, supportingProcess);
									supportingProcess = {};
									processNameList = [];
									processDataList = [];
									matchFlag = false;
								}
							});
							this.supportProcessDataConstruction(processNameList, processDataList, supportingMap, matchFlag, supportingProcess);
						});
					}
					console.log('supporting stream');
					console.log(this.supportingProcessDataList);
					this.preparePrimaryLabel();
					this.findPrimaryStreamById(this.carRequest.primaryStreamId);
					console.log(this.carRequest);
					/*Optimization
					this.carRequest.psPnMapTransList.forEach(processStreamProcessNameObject => {
						this.loadDemonOeeMap(processStreamProcessNameObject.processNameId);
					});*/
					// tslint:disable-next-line:no-shadowed-variable
					if ((this.carRequest.requestStatus === 'DRAFT' && this.carRequest.recallReason === null) || ((this.carRequest.requestStatus === 'REJECTED' || (this.carRequest.requestStatus === 'DRAFT' && this.carRequest.recallReason !== null)) && this.carRequest.keepHistoricalPSInRejRecall === false && this.carRequest.isProcessStreamAvailable)) {
						this.processStreamService.findProcessStreamTreeById(this.carRequest.primaryStreamId).subscribe(procStream => {
							this.processStreamTree = procStream;
						});
					} else {
						this.processStreamService.findProcessStreamTranTreeById(this.carRequest.primaryStreamId, this.carRequest.reqId).subscribe(procStream1 => {
							this.processStreamTree = procStream1;
						});
					}
					//hmpchart
					this.loadChartData();
					this.retrieveSharedLoadingIdBySiteCodeAndBuildChart();
					this.carRequestService.findReqHistoriesById(this.carId).subscribe(response => {
						this.reqStateHistories = response;
					});
					this.allProcessContainsAnyErrors = this.utilService.isPrimaryAndSecondaryStreamProcessContainsErrors(this.carRequest);
					this.carRequest.isAnyProcessContainsAPWGreaterThan5 = this.utilService.isPrimaryAndSecondaryStreamProcessContainsAPWGreaterThan5(this.carRequest);
					if (this.carRequest.isAnyProcessContainsAPWGreaterThan5) {
						this.carRequest.deviationReasonCode = this.carRequest.deviationReasonCode ? this.carRequest.deviationReasonCode : -1;
						this.carRequest.deviationReasonObject = deviationReasonDropdown.find(value1 => value1.code === this.carRequest.deviationReasonCode);
					}
				}
				this.updateDeclarationQuestionAnswers();
				this.declarationStatus = this.declarationQuestionStatus();
				this.isSupplierSubmittedWithinDateLimitForClosedCARs();
				this.totalAllocationAndcontainAssumptionsCheck();
				this.isOldECARWithinTheDateLimit();
				this.carRequestService.convertBottleneckProcessStringToMap(this.carRequest);
				this.bottleNeckProcessNameApw = [];
				this.bottleNeckProcessNameMpw = [];
				if (this.carRequest.bottleNeckProcessNameIdApwMap) {
					this.carRequest.bottleNeckProcessNameIdApwMap.forEach((processNameIdApw, key) => {
						this.processStreamService.findProcessStreamById(key).subscribe(processStream => {
							this.processNameService.findProcessNameByIdList(processNameIdApw.join('-')).subscribe(processNameList => {
								processNameList.forEach((processNameObj: ProcessName) => {
									this.bottleNeckProcessNameApw.push(processStream.processStreamName + ' - ' + processNameObj.processName);
								});
							});
						});
					});
				}
				if (this.carRequest.bottleNeckProcessNameIdMpwMap) {
					this.carRequest.bottleNeckProcessNameIdMpwMap.forEach((processNameIdMpw, key) => {
						this.processStreamService.findProcessStreamById(key).subscribe(processStream => {
							this.processNameService.findProcessNameByIdList(processNameIdMpw.join('-')).subscribe(processNameList => {
								processNameList.forEach((processNameObj: ProcessName) => {
									this.bottleNeckProcessNameMpw.push(processStream.processStreamName + ' - ' + processNameObj.processName);
								});
							});
						});
					});
				}
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	private isSupplierSubmittedWithinDateLimitForClosedCARs() {
		if (this.carRequest.requestStatus === 'SUBMITTED' || this.carRequest.requestStatus === 'COMPLETED' || this.carRequest.requestStatus === 'APPROVED' || this.carRequest.requestStatus === 'ACKNOWLEDGED') {
			this.carRequestService.isSupplierSubmittedWithinDateLimit(this.carRequest.reqId).subscribe(isSubmitted => {
				if (isSubmitted) {
					this.hideCapacityStudyElements = true;
				} else {
					this.hideCapacityStudyElements = false;
				}
			});
		} else {
			this.hideCapacityStudyElements = false;
		}
	}

	private isOldECARWithinTheDateLimit() {
		if (this.carRequest.requestStatus === 'SUBMITTED' || this.carRequest.requestStatus === 'COMPLETED' || this.carRequest.requestStatus === 'APPROVED' || this.carRequest.requestStatus === 'ACKNOWLEDGED') {
			this.carRequestService.isOldECARWithinTheDateLimit(this.carRequest.reqId).subscribe(isSubmitted => {
				if (isSubmitted) {
					this.hideProcessDetailsValidation = true;
				} else {
					this.hideProcessDetailsValidation = false;
				}
			});
		} else {
			this.hideProcessDetailsValidation = false;
		}
	}

	private validateProcessDataList(psPnMapTran: PsPnMapTran): PsPnMapTran {
		if (!psPnMapTran.processDataList[0].isApw) {
			psPnMapTran.processDataList[0].isApw = true;
		}
		psPnMapTran.processDataList[0].theoreticalParts = _.round(psPnMapTran.processDataList[0].theoreticalParts);
		psPnMapTran.processDataList[1].theoreticalParts = _.round(psPnMapTran.processDataList[1].theoreticalParts);
		psPnMapTran.processDataList[0].changeOverTimeWeekly = _.round(psPnMapTran.processDataList[0].changeOverTimeWeekly);
		psPnMapTran.processDataList[1].changeOverTimeWeekly = _.round(psPnMapTran.processDataList[1].changeOverTimeWeekly);
		psPnMapTran.processDataList[0].allocPercent = _.round(psPnMapTran.processDataList[0].allocPercent, 1);
		psPnMapTran.processDataList[1].allocPercent = _.round(psPnMapTran.processDataList[1].allocPercent, 1);
		psPnMapTran.processDataList[0].equipmentAvailability = _.round(psPnMapTran.processDataList[0].equipmentAvailability, 1);
		psPnMapTran.processDataList[0].weekPartEstimate = Math.floor(psPnMapTran.processDataList[0].weekPartEstimate);
		psPnMapTran.processDataList[1].weekPartEstimate = Math.floor(psPnMapTran.processDataList[1].weekPartEstimate);
		psPnMapTran.processDataList[0].netIdleCycleTime = _.round(psPnMapTran.processDataList[0].netIdleCycleTime, 2);
		psPnMapTran.processDataList[0].netAvailableTime = _.round(psPnMapTran.processDataList[0].netAvailableTime, 2);
		psPnMapTran.processDataList[1].netAvailableTime = _.round(psPnMapTran.processDataList[1].netAvailableTime, 2);
		psPnMapTran.processDataList[0].reqJph = _.round(((3600 / psPnMapTran.processDataList[0].netIdleCycleTime) * psPnMapTran.processDataList[0].reqOee) / 100, 1);
		psPnMapTran.processDataList[1].reqJph = _.round(((3600 / psPnMapTran.processDataList[0].netIdleCycleTime) * psPnMapTran.processDataList[1].reqOee) / 100, 1);
		psPnMapTran.processDataList[0].reqJph = psPnMapTran.processDataList[0].reqJph === 1 / 0 ? 0 : psPnMapTran.processDataList[0].reqJph;
		psPnMapTran.processDataList[1].reqJph = psPnMapTran.processDataList[1].reqJph === 1 / 0 ? 0 : psPnMapTran.processDataList[1].reqJph;
		psPnMapTran.processDataList[0].avaiRemainPercent = _.round(psPnMapTran.processDataList[0].avaiRemainPercent, 1);
		psPnMapTran.processDataList[1].avaiRemainPercent = _.round(psPnMapTran.processDataList[1].avaiRemainPercent, 1);
		const partSumAPW = psPnMapTran.processDataList[0].theoreticalParts - ((psPnMapTran.processDataList[0].avaiRemainPercent * psPnMapTran.processDataList[0].theoreticalParts) / 100);
		psPnMapTran.processDataList[0].isContainAssumptions = partSumAPW <= psPnMapTran.processDataList[0].theoreticalParts;
		const partSumMPW = psPnMapTran.processDataList[1].theoreticalParts - ((psPnMapTran.processDataList[1].avaiRemainPercent * psPnMapTran.processDataList[1].theoreticalParts) / 100);
		psPnMapTran.processDataList[1].isContainAssumptions = partSumMPW <= psPnMapTran.processDataList[1].theoreticalParts;
		psPnMapTran.processDataList[0].availablePerformanceEfficiency = _.round(psPnMapTran.processDataList[0].availablePerformanceEfficiency);
		psPnMapTran.processDataList[0].demonJph = _.round(((3600 / psPnMapTran.processDataList[0].netIdleCycleTime) * psPnMapTran.processDataList[0].averageHisOee) / 100, 1);
		psPnMapTran.processDataList[0].demonJph = psPnMapTran.processDataList[0].demonJph === 1 / 0 ? 0 : psPnMapTran.processDataList[0].demonJph;
		if (!this.carRequest.submissionScenarioObject.templateName.toLowerCase().startsWith('capacity')) {
			psPnMapTran.processDataList[0].plannedNetIdealCycleTime = _.round(psPnMapTran.processDataList[0].plannedNetIdealCycleTime, 2);
			psPnMapTran.processDataList[0].phaseJPH = _.round(((3600 / psPnMapTran.processDataList[0].plannedNetIdealCycleTime) * psPnMapTran.processDataList[0].phaseOEE) / 100, 1);
			psPnMapTran.processDataList[0].phaseJPH = !psPnMapTran.processDataList[0].phaseJPH ? 0 : psPnMapTran.processDataList[0].phaseJPH;
			psPnMapTran.processDataList[0].performanceEfficiency = _.round(psPnMapTran.processDataList[0].performanceEfficiency, 1);
			psPnMapTran.processDataList[0].noOfPartsScrappedPercent = _.round(psPnMapTran.processDataList[0].noOfPartsScrappedPercent, 1);
			psPnMapTran.processDataList[0].noOfPartsReworkedPercent = _.round(psPnMapTran.processDataList[0].noOfPartsReworkedPercent, 1);
			psPnMapTran.processDataList[0].qualityRate = _.round(psPnMapTran.processDataList[0].qualityRate, 1);
		}
		if (psPnMapTran.processDataList[0].sharedLoadingTotalAllocPercent) {
			psPnMapTran.processDataList[0].sharedLoadingTotalAllocPercent = _.round(psPnMapTran.processDataList[0].sharedLoadingTotalAllocPercent, 1);
			psPnMapTran.processDataList[1].sharedLoadingTotalAllocPercent = _.round(psPnMapTran.processDataList[1].sharedLoadingTotalAllocPercent, 1);
		}
		if (psPnMapTran.processDataList[0].totalAllocationPercentageAPW) {
			psPnMapTran.processDataList[0].totalAllocationPercentageAPW = _.round(psPnMapTran.processDataList[0].totalAllocationPercentageAPW, 1);
			psPnMapTran.processDataList[1].totalAllocationPercentageAPW = _.round(psPnMapTran.processDataList[1].totalAllocationPercentageMPW, 1);
		}
		if (psPnMapTran.processDataList[1].totalAllocationPercentageCapacityMPW) {
			//&& psPnMapTran.processDataList[0].totalAllocationPercentageAPW < psPnMapTran.processDataList[0].totalAllocationPercentageCapacityAPW)
			psPnMapTran.processDataList[0].totalAllocationPercentageCapacityMPW = _.round(psPnMapTran.processDataList[0].totalAllocationPercentageCapacityAPW, 1);
			psPnMapTran.processDataList[1].totalAllocationPercentageCapacityMPW = _.round(psPnMapTran.processDataList[1].totalAllocationPercentageCapacityMPW, 1);
		} /*else {
			psPnMapTran.processDataList[0].totalAllocationPercentageCapacityMPW = null;
			psPnMapTran.processDataList[1].totalAllocationPercentageCapacityMPW = null;
		}*/
		psPnMapTran.processDataList[0].overAllWarningRiskError = this.noOfMessageCheck(psPnMapTran);
		return psPnMapTran;
	}

	findPrimaryStreamById(id) {
		this.processStreamService.findProcessStreamById(id).subscribe(value => {
			this.selectedPrimaryStream = value;
			this.buildOEEChart();
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe(params => {
			this.carId = Number(params.get('id'));
		});
	}

	loadDemonOeeMap(processNameId: number) {
		let demonOee = 0;
		const historicalMfgPerfObj = {
			processId: processNameId,
			siteCode: this.carRequest.siteCode
		};
		if (!this.carRequest.requestStatus || this.carRequest.requestStatus === 'DRAFT' || this.carRequest.requestStatus === 'REJECTED') {
			this.hmpService.findHistMfgPerfRecords(historicalMfgPerfObj).subscribe((response: HistoricalMfgPerf[]) => {
				demonOee = this.utilService.calculateAverageOEE(response);
				this.demonOEEList.push(new DemonOEEMap({'id': processNameId, 'demonOee': demonOee}));
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		} else {
			this.hmpService.findHistMfgPerfRecordsByCarId(historicalMfgPerfObj, this.carRequest.reqId).subscribe((response: HistoricalMfgPerf[]) => {
				demonOee = this.utilService.calculateAverageOEE(response);
				this.demonOEEList.push(new DemonOEEMap({'id': processNameId, 'demonOee': demonOee}));
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	//HMP Chart starts
	loadChartData() {
		if (this.carRequest.requestStatus !== null && this.carRequest.requestStatus !== 'DRAFT' && this.carRequest.requestStatus !== 'STADRAFT' && this.carRequest.requestStatus !== 'REJECTED') {
			this.hmpService.findAllHMPByCarId(this.carRequest.reqId).subscribe((resp: HistoricalMfgPerf[]) => {
				this.buildPrimaryStreamHistoricalMfgChartData(resp);
				this.buildSupportingStreamHistoricalMfgChartData(resp);
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		} else {
			const processNameIds = [];
			this.carRequest.psPnMapTransList.forEach(value => {
				processNameIds.push(value.processNameId);
			});
			if (this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
				this.carRequest.primarySupportingMapTranList.forEach(supportingProcessList => {
					supportingProcessList.psPnMapTranList.forEach(supportingProcess => {
						processNameIds.push(supportingProcess.processNameId);
					});
				});
			}
			if (processNameIds && processNameIds.length > 0) {
				this.hmpService.findAllHMPBySiteCodeProcessIds(this.carRequest.siteCode, processNameIds).subscribe((resp: HistoricalMfgPerf[]) => {
					this.buildPrimaryStreamHistoricalMfgChartData(resp);
					this.buildSupportingStreamHistoricalMfgChartData(resp);
				}, error => {
					this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				});
			}
		}
	}

	private buildPrimaryStreamHistoricalMfgChartData(hmpData: HistoricalMfgPerf[]) {
		this.HstMfgPrimaryStreamChart = [];
		this.HstMfgPrimaryStreamChart = this.buildProcessDataChartData(hmpData, this.carRequest.psPnMapTransList);
	}

	private buildSupportingStreamHistoricalMfgChartData(hmpData: HistoricalMfgPerf[]) {
		this.HstMfgSupportingStreamCharts = [];
		let HstMfgSupportingStreamChart: OEEChart[];
		if (this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
			this.carRequest.primarySupportingMapTranList.forEach(supportingMap => {
				const supportingStreamName = supportingMap.processStreamName;
				HstMfgSupportingStreamChart = this.buildProcessDataChartData(hmpData, supportingMap.psPnMapTranList);
				HstMfgSupportingStreamChart.map(value => value.processStreamName = supportingStreamName + ': ' + value.processStreamName);
				this.HstMfgSupportingStreamCharts.push(HstMfgSupportingStreamChart);
			});
		}
	}

	buildProcessDataChartData(hmpData: HistoricalMfgPerf[], psPnMapTransList: PsPnMapTran[]) {
		const chartData = [];
		psPnMapTransList.forEach(psPnTran => {
			const oee = [];
			const rOEE = [];
			const labels = [];
			let requiredOEE = 0;
			let demonOEEMax;
			let yMax;
			const filteredHmpData = hmpData.filter(value => value.processId === psPnTran.processNameId);

			const rAPW = psPnTran.processDataList[0].reqOee;
			const rMPW = psPnTran.processDataList[1].reqOee;
			requiredOEE = rAPW > rMPW ? rAPW : rMPW;

			//Sort the response by date
			filteredHmpData.sort(function (left, right) {
				return moment.utc(left.hmfDate).diff(moment.utc(right.hmfDate));
			});
			filteredHmpData.forEach((historicalObj) => {
				oee.push(historicalObj.oee);
				rOEE.push(requiredOEE);
				if (historicalObj.hmfDate) {
					labels.push(this.datePipe.transform(historicalObj.hmfDate, 'dd-MMM-yyyy'));
				}
			});
			demonOEEMax = Math.max(...oee);
			const tempChart = _.cloneDeep(oeeChartTemplate);
			if (demonOEEMax < 100 && requiredOEE < 100) {
				yMax = 100;
			} else {
				if (demonOEEMax > 100) {
					yMax = Math.ceil(demonOEEMax / 20) * 20;
					tempChart.isDemonOEEExceeds100Percent = true;
				}
				if (requiredOEE > 100) {
					yMax = yMax > Math.ceil(requiredOEE / 20) * 20 ? yMax : Math.ceil(requiredOEE / 20) * 20;
					tempChart.isReqOEEExceeds100Percent = true;
				}
			}

			tempChart.processStreamName = psPnTran.processName;
			tempChart.oeeChart = {
				labels: labels,
				datasets: [
					{
						type: 'line',
						label: 'Demonstrated OEE',
						borderColor: '#5DADE2',
						borderWidth: 2,
						fill: false,
						data: oee,
						pointRadius: 5
					},
					{
						type: 'line',
						label: 'Required OEE',
						borderColor: '#EC7063',
						borderWidth: 1,
						fill: false,
						data: rOEE,
						pointRadius: 0
					}
				]
			};
			tempChart.oeeChartOptions = {
				scales: {
					yAxes: [{
						ticks: {
							beginAtZero: true,
							max: yMax,
							callback: function (value, index, values) {
								return value + ' %';
							}
						}
					}],
					xAxes: [{
						ticks: {
							suggestedMax: 25,
							beginAtZero: true
						}
					}]
				}
			};
			chartData.push(tempChart);
		});
		return chartData;
	}

	//HMP Chart ends

	//OEE Chart starts
	buildOEEChart() {
		this.primaryProcessStreamChart = _.cloneDeep(oeeChartTemplate);
		if (this.selectedPrimaryStream && this.selectedPrimaryStream.processStreamName) {
			this.primaryProcessStreamChart = this.chartService.buildOEEPrimaryStreamChartData(this.carRequest, this.selectedPrimaryStream.processStreamName);
		}
		this.supportingProcessStreamChart = this.chartService.buildOEESupportingStreamChartData(this.carRequest);
	}

	//OEE Chart ends

	//SLP Chart Starts
	checkForFreeAllocationMessage(chartData: ChartData): boolean {
		let showMessage = false;
		if (chartData && chartData.datasets && chartData.datasets.length > 0) {
			const freeAllocation = chartData.datasets[0].data[chartData.datasets[0].data.length - 1];
			if (freeAllocation > 0 && freeAllocation <= 10) {
				showMessage = true;
			}
		}
		return showMessage;
	}

	getFreeAllocation(chartData: ChartData): number {
		let freeAllocation = 0;
		if (chartData && chartData.datasets && chartData.datasets.length > 0) {
			freeAllocation = chartData.datasets[0].data[chartData.datasets[0].data.length - 1];
		}
		return freeAllocation;
	}

	retrieveSharedLoadingIdBySiteCodeAndBuildChart() {
		this.buildChartDataForPrimaryStream();
		this.buildChartDataForSupportingStream();
	}

	buildChartDataForPrimaryStream() {
		this.sharedLoadingChartForPrimaryStream = [];
		if (this.carRequest && this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
			this.carRequest.psPnMapTransList.forEach((psPnMapTranObject: PsPnMapTran) => {
				if (psPnMapTranObject && psPnMapTranObject.processDataList[0] && !psPnMapTranObject.processDataList[0].dedicated) {
					this.isThereAnySHChartAvailable = true;
					if (this.carRequest.requestStatus !== null && this.carRequest.requestStatus !== 'DRAFT' && this.carRequest.requestStatus !== 'STADRAFT' && this.carRequest.requestStatus !== 'REJECTED') {
						this.sharedLoadingService.retrieveSharedLoadingByCarIdAndSiteAndProcess(this.carRequest.reqId, this.carRequest.siteCode, psPnMapTranObject.processNameId).subscribe(value => {
							if (value) {
								const sharedLoadingChartTemp = this.buildChartDataFromPsPnMapTranObject(psPnMapTranObject, psPnMapTranObject.processName, value);
								if (sharedLoadingChartTemp) {
									this.sharedLoadingChartForPrimaryStream.push(sharedLoadingChartTemp);
								}
							}
						}, error => {
							this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
						});
					} else {
						this.sharedLoadingService.retrieveSharedLoadingIdBySiteCodeAndProcessNameId(this.carRequest.siteCode, psPnMapTranObject.processNameId.toString()).subscribe(value => {
							if (value) {
								const sharedLoadingChartTemp = this.buildChartDataFromPsPnMapTranObject(psPnMapTranObject, psPnMapTranObject.processName, value);
								if (sharedLoadingChartTemp) {
									this.sharedLoadingChartForPrimaryStream.push(sharedLoadingChartTemp);
								}
							}
						}, error => {
							this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
						});
					}
				}
			});
		}
	}

	buildChartDataFromPsPnMapTranObject(psPnMapTranObject: PsPnMapTran, processStreamName: string, slRoot: SharedLoadingRoot): SharedLoadingChart {
		let sharedLoadingChartTemp: SharedLoadingChart;
		this.isSharedLoadingExistInPS = true;
		sharedLoadingChartTemp = this.buildAPWAndMPWChartData(slRoot, psPnMapTranObject, processStreamName);
		return sharedLoadingChartTemp;
	}

	buildAPWAndMPWChartData(sharedLoadingRoot: SharedLoadingRoot, psPnMapTranObject: PsPnMapTran, processStreamName: string): SharedLoadingChart {
		const sharedLoadingChartTemp = _.cloneDeep(sharedLoadingChartTemplate);
		sharedLoadingChartTemp.processName = psPnMapTranObject.processName;
		sharedLoadingChartTemp.processStreamName = processStreamName;
		const sharedLoadingAPW = sharedLoadingRoot.sharedLoadingList[0];
		const sharedLoadingMPW = sharedLoadingRoot.sharedLoadingList[1];

		//APW
		sharedLoadingChartTemp.apwChart = this.extractAndBuildChartData(sharedLoadingAPW);

		//MPW
		sharedLoadingChartTemp.mpwChart = this.extractAndBuildChartData(sharedLoadingMPW);
		sharedLoadingChartTemp.mpwChart.labels = sharedLoadingChartTemp.apwChart.labels;
		sharedLoadingChartTemp.mpwChart.datasets[0].backgroundColor = sharedLoadingChartTemp.apwChart.datasets[0].backgroundColor;
		sharedLoadingChartTemp.mpwChart.datasets[0].hoverBackgroundColor = sharedLoadingChartTemp.apwChart.datasets[0].hoverBackgroundColor;
		return sharedLoadingChartTemp;
	}

	extractAndBuildChartData(sharedLoading: SharedLoading): ChartData {
		const chartData: ChartData = {
			'labels': [],
			'datasets': [],
			'isSharedLoadingExceeds100Percent': false,
			'height': 0
		};
		const vLineList: string [] = [];

		//Data
		const dataSetAPW = _.cloneDeep(dataSetTemplate);
		const allocPercentData = [];
		let totalAllocation = 0;
		let remainingAllocation = 0;

		//Color
		dataSetAPW.backgroundColor = [];
		dataSetAPW.hoverBackgroundColor = [];

		const isCapacityStudyScenario = this.carRequest.submissionScenarioObject['capacityStudy'] === true && this.carRequest.capacityRequirementSource === 'What-If' ? true : false;
		//Handling for Capacity Study Scenario
		const sharedLoadingPlanHolderList = [];
		sharedLoading.sharedLoadingPlanList.forEach((sharedLoadingPlan, slpIndex) => {
			const sharedLoadingPlanHolder = new SharedLoadingPlanHolder();
			if (sharedLoadingPlan.isCapacity) {
				let indexValue = 0;
				sharedLoadingPlanHolderList.forEach((slPlanHolder, indx) => {
					if (slPlanHolder.apw.groupId === sharedLoadingPlan.groupId) {
						indexValue = indx;
					}
				});
				sharedLoadingPlanHolderList[indexValue].capacityAPW = sharedLoadingPlan;
			} else {
				sharedLoadingPlanHolder.apw = sharedLoadingPlan;
				sharedLoadingPlanHolderList.push(sharedLoadingPlanHolder);
			}
		});
		sharedLoadingPlanHolderList.forEach((sharedLoadingPlanHolder, slpIndex) => {
			if (vLineList && vLineList.length > 0 && vLineList.includes(sharedLoadingPlanHolder.apw.vehLine)) {
				const index = vLineList.indexOf(sharedLoadingPlanHolder.apw.vehLine);
				if (isCapacityStudyScenario && sharedLoadingPlanHolder.capacityAPW && sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired) {
					allocPercentData[index] = allocPercentData[index] + _.round(sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired, 1);
					totalAllocation = totalAllocation + _.round(sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired, 1);
				} else {
					allocPercentData[index] = allocPercentData[index] + _.round(sharedLoadingPlanHolder.apw.allocationTotalRequired, 1);
					totalAllocation = totalAllocation + _.round(sharedLoadingPlanHolder.apw.allocationTotalRequired, 1);
				}
			} else {
				vLineList.push(sharedLoadingPlanHolder.apw.vehLine);
				if (isCapacityStudyScenario && sharedLoadingPlanHolder.capacityAPW && sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired) {
					allocPercentData.push(_.round(sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired, 1));
					totalAllocation = totalAllocation + _.round(sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired, 1);
				} else {
					allocPercentData.push(_.round(sharedLoadingPlanHolder.apw.allocationTotalRequired, 1));
					totalAllocation = totalAllocation + _.round(sharedLoadingPlanHolder.apw.allocationTotalRequired, 1);
				}
				dataSetAPW.backgroundColor.push(chartColors[slpIndex].backgroundColor);
				dataSetAPW.hoverBackgroundColor.push(chartColors[slpIndex].hoverBackgroundColor);
			}
		});
		vLineList.push('Free');
		chartData.labels = vLineList;
		totalAllocation = _.round(totalAllocation, 1);
		remainingAllocation = 100 - totalAllocation;
		if (remainingAllocation < 0) {
			chartData.isSharedLoadingExceeds100Percent = true;
		}
		remainingAllocation = _.round(remainingAllocation, 1);
		remainingAllocation = remainingAllocation <= 0 ? 0 : remainingAllocation;
		allocPercentData.push(remainingAllocation);

		dataSetAPW.data = allocPercentData;
		dataSetAPW.backgroundColor.push(chartColors[chartColors.length - 1].backgroundColor);
		dataSetAPW.hoverBackgroundColor.push(chartColors[chartColors.length - 1].hoverBackgroundColor);
		chartData.datasets[0] = dataSetAPW;
		return chartData;
	}

	buildChartDataForSupportingStream() {
		this.sharedLoadingChartForSupportingStream = [];
		if (this.carRequest && this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
			this.carRequest.primarySupportingMapTranList.forEach((primarySupportingMapTran) => {
				if (primarySupportingMapTran && primarySupportingMapTran.psPnMapTranList && primarySupportingMapTran.psPnMapTranList.length > 0) {
					const sharedLoadingChartForSupportingStreamTemp = [];
					primarySupportingMapTran.psPnMapTranList.forEach((psPnMapTranObject, i) => {
						if (psPnMapTranObject && psPnMapTranObject.processDataList[0] && !psPnMapTranObject.processDataList[0].dedicated) {
							this.isThereAnySHChartAvailable = true;
							this.isSharedLoadingExistInSS = true;
							if (this.carRequest.requestStatus !== null && this.carRequest.requestStatus !== 'DRAFT' && this.carRequest.requestStatus !== 'STADRAFT' && this.carRequest.requestStatus !== 'REJECTED') {
								this.sharedLoadingService.retrieveSharedLoadingByCarIdAndSiteAndProcess(this.carRequest.reqId, this.carRequest.siteCode, psPnMapTranObject.processNameId).subscribe(value => {
									if (value) {
										const sharedLoadingChartTemp = this.buildChartDataFromPsPnMapTranObject(psPnMapTranObject, primarySupportingMapTran.processStreamName, value);
										if (sharedLoadingChartTemp) {
											sharedLoadingChartForSupportingStreamTemp.push(sharedLoadingChartTemp);
											if (i === primarySupportingMapTran.psPnMapTranList.length - 1 && sharedLoadingChartForSupportingStreamTemp && sharedLoadingChartForSupportingStreamTemp.length > 0) {
												this.sharedLoadingChartForSupportingStream.push(sharedLoadingChartForSupportingStreamTemp);
												console.log('final this.sharedLoadingChartForSupportingStreamTemp ' + this.sharedLoadingChartForSupportingStream.length);
											}
										}
									}
								}, error => {
									this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
								});
							} else {
								this.sharedLoadingService.retrieveSharedLoadingIdBySiteCodeAndProcessNameId(this.carRequest.siteCode, psPnMapTranObject.processNameId.toString()).subscribe(value => {
									if (value) {
										const sharedLoadingChartTemp = this.buildChartDataFromPsPnMapTranObject(psPnMapTranObject, primarySupportingMapTran.processStreamName, value);
										if (sharedLoadingChartTemp) {
											sharedLoadingChartForSupportingStreamTemp.push(sharedLoadingChartTemp);
											if (i === primarySupportingMapTran.psPnMapTranList.length - 1 && sharedLoadingChartForSupportingStreamTemp && sharedLoadingChartForSupportingStreamTemp.length > 0) {
												this.sharedLoadingChartForSupportingStream.push(sharedLoadingChartForSupportingStreamTemp);
												console.log('final this.sharedLoadingChartForSupportingStreamTemp ' + this.sharedLoadingChartForSupportingStream.length);
											}
										}
									}
								}, error => {
									this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
								});
							}
						}
					});
				}
			});
		}
	}

	//SLP Chart ends

	// viewProcess stream
	connectProcessNames() {
		if (!this.isConnectionMade) {
			this.jsPlumbInstance = jsPlumb.getInstance();
			const psSize = this.processStreamTree.length;
			for (let curTreeIndex = 0; curTreeIndex < psSize; curTreeIndex++) {
				const curProcessStream = this.processStreamTree[curTreeIndex];
				const availableProcessNames = curProcessStream.availableProcessNames;
				const pnSize = availableProcessNames.length;
				for (let curPNIndex = 0; curPNIndex < pnSize; curPNIndex++) { // n-1 loop to make connection
					if (curPNIndex < pnSize - 1) {
						const sourceId = 'div-' + curProcessStream.id + '-' + availableProcessNames[curPNIndex].id;
						const targetId = 'div-' + curProcessStream.id + '-' + availableProcessNames[curPNIndex + 1].id;

						const connectJson = this.buildConnection(sourceId, targetId);
						this.jsPlumbInstance.connect(connectJson);
					}

					if (curTreeIndex !== 0) { // other than parent
						const priSuppoMap = curProcessStream.primarySupportingMap;
						const primaryProcessStreamId = priSuppoMap['primaryProcessStreamId'];
						const supportingProcessStreamId = priSuppoMap['supportingProcessStreamId'];
						const primaryProcessNameId = priSuppoMap['primaryProcessNameId'];

						const sourceIdPriSupp = 'div-' + primaryProcessStreamId + '-' + primaryProcessNameId;
						const targerIdPriSupp = 'div-' + supportingProcessStreamId + '-' + availableProcessNames[availableProcessNames.length - 1].id;
						const connectJsonPriSupp = this.buildPriSuppConnection(sourceIdPriSupp, targerIdPriSupp, linkColors[curTreeIndex].colorCode);
						this.jsPlumbInstance.connect(connectJsonPriSupp);
					}
					this.isConnectionMade = true;
				}
			}
		}
	}

	private buildPriSuppConnection(sourceIdPriSupp: string, targerIdPriSupp: string, strokeColor: string) {
		const connectJson = {
			source: targerIdPriSupp,
			target: sourceIdPriSupp,
			anchors: ['Right', 'Right'],
			paintStyle: {stroke: strokeColor, strokeWidth: 2},
			endpoint: 'Blank',
			endpointStyle: {fill: 'yellow'},
			connector: ['Bezier', {curviness: 30}],
			overlays: [['Arrow', {
				location: 1,
				id: 'arrow',
				length: 14,
				foldback: 0.4
			}]]
		};
		return connectJson;
	}

	private buildConnection(sourceId, targetId) {
		const connectJson = {
			source: sourceId,
			target: targetId,
			anchors: ['Right', 'Left'],
			endpoint: 'Blank',
			endpointStyle: {fill: 'blue'},
			paintStyle: {stroke: '#03a108', strokeWidth: 1},
			hoverPaintStyle: {strokeStyle: '#dbe300'},
			connector: ['Flowchart', {cornerRadius: 2, alwaysRespectStubs: false}],
			overlays: [['Arrow', {
				location: 1,
				id: 'arrow',
				length: 14,
				foldback: 0.4
			}]]
		};
		return connectJson;
	}

	// viewProcess stream ends

	preparePrimaryLabel() {
		this.cols3 = [];
		this.cols3 = [
			{
				field: 'subHeader',
				header: 'A4) Planned Departmental Operating  Pattern & Net Available Time for All Customers',
				scenario: 'All'
			},
			{field: 'daysPerWeekFlag', header: 'B) Days/Week', scenario: 'All'},
			{field: 'shiftsPerDayFlag', header: 'C) Shifts/Day', scenario: 'All'},
			{field: 'hrsPerShiftFlag', header: 'D) Total hours/Shift', scenario: 'All'},
			{field: 'downTimeFlag', header: 'E) downTime (min/shift)', scenario: 'All'},
			{field: 'dedicated', header: 'F) Process Type', scenario: 'All', mergeData: true},
			{
				field: 'planMinChan',
				header: 'G1) Minutes per Changeover (into this part #)',
				scenario: 'All',
				mergeData: true
			},
			{
				field: 'planChangFreq',
				header: 'G2) Changeover Frequency / Week (into this part #)',
				scenario: 'All',
				mergeData: true
			},
			{field: 'allocPercent', header: 'F1) Allocation Percent', scenario: 'All'},
			{field: 'netAvailableTime', header: 'G) Net Available Time (hrs / week)', scenario: 'All'},
			{field: 'subHeader', header: 'A5) Required Good Parts / Week', scenario: 'All'},
			{field: 'scrappedPercentFlag', header: 'H) Planned scrap percentage', scenario: 'All', mergeData: true},
			{
				field: 'reqGoodPartsNext',
				header: 'J) Req\'d Good Parts / Week to Support Next Process',
				scenario: 'All'
			},
			{field: 'subHeader', header: 'A6) Required OEE  (Overall Equipment Effectiveness)', scenario: 'All'},
			{field: 'idealCycleTimeFlag', header: 'K) Ideal Cycle Time (sec/cycle)', scenario: 'All', mergeData: true},
			{
				field: 'noToolsMachParallelFlag',
				header: 'L) # of Tools / Machines in parallel (planned)',
				scenario: 'All',
				mergeData: true
			},
			{
				field: 'noIdentityPartsFlag',
				header: 'M) # of identical parts per Cycle (planned)',
				scenario: 'All',
				mergeData: true
			},
			{
				field: 'netIdleCycleTimeFlag',
				header: 'N) Net Ideal Cycle Time  (sec/part)',
				scenario: 'All',
				mergeData: true
			},
			{field: 'theoreticalParts', header: 'P) Theoretical Parts per week at 100% OEE', scenario: 'All'},
			{field: 'reqOeeFlag', header: 'Q) Required OEE', scenario: 'All'},
			{field: 'reqJph', header: 'Required JPH', scenario: 'All'},
			{field: 'partsReworkedPercentFlag', header: 'R) Planned Rework percentage', scenario: 'All', mergeData: true},
			{field: 'isContainAssumptions', header: 'S) Assumptions can be contained?', scenario: 'All'},
			{
				field: 'avaiRemainPercentFlag',
				header: 'T) % Remaining for Availability & Performance Efficiency losses',
				scenario: 'All'
			},
			{field: 'otherAssumptionX', header: 'Other Assumptions', scenario: 'All', mergeData: true},
			{
				field: 'subHeader',
				header: 'A7) Planned Headcount/ Capital Equipment & Shared Process / Total Allocation Plan',
				scenario: 'All'
			},
			{field: 'plannedHeadCount', header: 'Planned Headcount (direct labor)', scenario: 'All', mergeData: true},
			{field: 'capitalEquipment', header: 'Capital Equipment', scenario: 'All', mergeData: true},
			{
				field: 'totalAllocationPercentageAPW',
				header: 'U) Total % Allocation from Shared Loading Plan Sheet',
				scenario: 'All'
			},
			{
				field: 'totalAllocationPercentageCapacityMPW',
				header: 'What-If Total % Allocation from Shared Loading Plan Sheet',
				scenario: 'All'
			},
			{
				field: 'subHeader',
				header: 'B1) Supplier Demonstrated OEE  (Overall Equipment Effectiveness)  - Historical Performance',
				scenario: 'Capacity Planning'
			},
			{field: 'supplierName', header: 'V) Supplier Name', scenario: 'Capacity Planning', mergeData: true},
			{field: 'supplierLocation', header: 'W) Supplier Location', scenario: 'Capacity Planning', mergeData: true},
			{field: 'siteCodeSurProcess', header: 'X) Site Code', scenario: 'Capacity Planning', mergeData: true},
			{
				field: 'surCustomerProcess',
				header: 'Y) Surrogate Program',
				scenario: 'Capacity Planning',
				mergeData: true
			},
			{
				field: 'averageHisOee',
				header: 'Z) Average Historical OEE',
				scenario: 'Capacity Planning',
				mergeData: true
			},
			{
				field: 'demonJph',
				header: 'Demonstrated JPH',
				scenario: 'Capacity Planning',
				mergeData: true
			},
			{
				field: 'weekPartEstimate',
				header: 'B2) Process Specific Weekly Part Estimate',
				scenario: 'Capacity Planning'
			},
			{
				field: 'subHeader',
				header: 'B) Supplier Demonstrated OEE - Phase 0 PPAP (Run @ Rate)',
				scenario: 'Phase 0 PPAP'
			},
			{field: 'subHeader', header: 'B1) Equipment Availability', scenario: 'Phase 0 PPAP'},
			{
				field: 'totalAvailableTimeFlag',
				header: 'V) Total Available Time (minutes)',
				scenario: 'Phase 0 PPAP',
				mergeData: true
			},
			{
				field: 'plannedDownTimeFlag',
				header: 'W) Contractual downTime (minutes)',
				scenario: 'Phase 0 PPAP',
				mergeData: true
			},
			{
				field: 'netAvailableTimePhase',
				header: 'X) Net Available Time (minutes)',
				scenario: 'Phase 0 PPAP',
				mergeData: true
			},
			{
				field: 'changeOverTimeActualFlag',
				header: 'Y) Changeover Time Actual (minutes)',
				scenario: 'Phase 0 PPAP',
				mergeData: true
			},
			{
				field: 'changeOverTimeWeekly',
				header: 'Z) Changeover Time weekly (minutes)',
				scenario: 'Phase 0 PPAP',
				mergeData: true
			},
			{
				field: 'observedUnplannedDownTimeFlag',
				header: 'AB) Observed downTime (incremental to W): Planned & Unplanned (minutes)',
				scenario: 'Phase 0 PPAP', mergeData: true
			},
			{field: 'operatingTime', header: 'AC) Operating Time (minutes)', scenario: 'Phase 0 PPAP', mergeData: true},
			{
				field: 'equipmentAvailabilityFlag',
				header: 'AD) Equipment Availability (%)',
				scenario: 'Phase 0 PPAP',
				mergeData: true
			},
			{field: 'subHeader', header: 'B2) Performance Efficiency', scenario: 'Phase 0 PPAP'},
			{field: 'totalPartsRunFlag', header: 'AE) Total Parts Run', scenario: 'Phase 0 PPAP', mergeData: true},
			{
				field: 'noOfCurrentToolsORMachinesFlag',
				header: 'AF1) # of current tool or machines in parallel',
				scenario: 'Phase 0 PPAP', mergeData: true
			},
			{
				field: 'noOfIdenticalPartsProducedFlag',
				header: 'AF2) # of identical parts produced',
				scenario: 'Phase 0 PPAP', mergeData: true
			},
			{
				field: 'plannedNetIdealCycleTime',
				header: 'AF) Planned Net Ideal Cycle Time (sec/part)',
				scenario: 'Phase 0 PPAP', mergeData: true
			},
			{
				field: 'performanceEfficiencyFlag',
				header: 'AG) Performance Efficiency (%)',
				scenario: 'Phase 0 PPAP',
				mergeData: true
			},
			{
				field: 'availablePerformanceEfficiency',
				header: 'AH) Availability and/or Performance Efficiency(minutes)',
				scenario: 'Phase 0 PPAP', mergeData: true
			},
			{
				field: 'subHeader',
				header: 'B3) Quality Rate & B4) Overall Equipment Effectiveness (OEE)',
				scenario: 'Phase 0 PPAP'
			},
			{field: 'noOfPartsScrappedFlag', header: 'AJ) # of Parts Scrapped', scenario: 'Phase 0 PPAP', mergeData: true},
			{
				field: 'noOfPartsScrappedPercentFlag',
				header: '% of Parts Scrapped',
				scenario: 'Phase 0 PPAP',
				mergeData: true
			},
			{field: 'noOfPartsReworkedFlag', header: 'AK) # of Parts Reworked', scenario: 'Phase 0 PPAP', mergeData: true},
			{
				field: 'noOfPartsReworkedPercentFlag',
				header: '% of Parts Reworked',
				scenario: 'Phase 0 PPAP',
				mergeData: true
			},
			{field: 'qualityRate', header: 'AL) Quality Rate (%)', scenario: 'Phase 0 PPAP', mergeData: true},
			{field: 'phaseOEEFlag', header: 'AM) Phase 0 OEE (%)', scenario: 'Phase 0 PPAP', mergeData: true},
			{field: 'phaseJPH', header: 'Phase JPH', scenario: 'Phase 0 PPAP', mergeData: true},
			{field: 'weekPartEstimate', header: 'B5) Weekly Parts Estimate', scenario: 'Phase 0 PPAP'},
			{
				field: 'observedAvgCycleTimeFlag',
				header: 'B6) Observed Average Cycle Time(sec/cycle)',
				scenario: 'Phase 0 PPAP', mergeData: true
			},
			{
				field: 'actualHeadCountFlag',
				header: 'Actual Headcount (Direct Labor)',
				scenario: 'Phase 0 PPAP',
				mergeData: true
			},
			{
				field: 'subHeader',
				header: 'B) Supplier Demonstrated OEE - Phase 3 PPAP (Capacity Verification)',
				scenario: 'Phase 3 PPAP', mergeData: true
			},
			{field: 'subHeader', header: 'B1) Equipment Availability', scenario: 'Phase 3 PPAP'},
			{
				field: 'totalAvailableTimeFlag',
				header: 'V) Total Available Time (minutes)',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{
				field: 'plannedDownTimeFlag',
				header: 'W) Contractual downTime (minutes)',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{
				field: 'netAvailableTimePhase',
				header: 'X) Net Available Time (minutes)',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{
				field: 'changeOverTimeActualFlag',
				header: 'Y) Changeover Time Actual (minutes)',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{
				field: 'changeOverTimeWeekly',
				header: 'Z) Changeover Time weekly (minutes)',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{
				field: 'observedUnplannedDownTimeFlag',
				header: 'AB) Observed downTime (incremental to W): Planned & Unplanned (minutes)',
				scenario: 'Phase 3 PPAP', mergeData: true
			},
			{field: 'operatingTime', header: 'AC) Operating Time (minutes)', scenario: 'Phase 3 PPAP', mergeData: true},
			{
				field: 'equipmentAvailabilityFlag',
				header: 'AD) Equipment Availability (%)',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{field: 'subHeader', header: 'B2) Performance Efficiency', scenario: 'Phase 3 PPAP'},
			{field: 'totalPartsRunFlag', header: 'AE) Total Parts Run', scenario: 'Phase 3 PPAP', mergeData: true},
			{
				field: 'plannedNetIdealCycleTime',
				header: 'AF) Planned Net Ideal Cycle Time (sec/part)',
				scenario: 'Phase 3 PPAP', mergeData: true
			},
			{
				field: 'performanceEfficiencyFlag',
				header: 'AG) Performance Efficiency (%)',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{
				field: 'availablePerformanceEfficiency',
				header: 'AH) Availability and/or Performance Efficiency(minutes)',
				scenario: 'Phase 3 PPAP', mergeData: true
			},
			{
				field: 'subHeader',
				header: 'B3) Quality Rate & B4) Overall Equipment Effectiveness (OEE)',
				scenario: 'Phase 3 PPAP'
			},
			{field: 'noOfPartsScrappedFlag', header: 'AJ) # of Parts Scrapped', scenario: 'Phase 3 PPAP', mergeData: true},
			{
				field: 'noOfPartsScrappedPercentFlag',
				header: '% of Parts Scrapped',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{field: 'noOfPartsReworkedFlag', header: 'AK) # of Parts Reworked', scenario: 'Phase 3 PPAP', mergeData: true},
			{
				field: 'noOfPartsReworkedPercentFlag',
				header: '% of Parts Reworked',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{
				field: 'qualityRate',
				header: 'AL) Quality Rate (%)',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{field: 'phaseOEEFlag', header: 'AM) Phase 3 OEE (%)', scenario: 'Phase 3 PPAP', mergeData: true},
			{field: 'phaseJPH', header: 'Phase JPH', scenario: 'Phase 3 PPAP', mergeData: true},
			{field: 'weekPartEstimate', header: 'B5) Weekly Parts Estimate', scenario: 'Phase 3 PPAP'},
			{
				field: 'observedAvgCycleTimeFlag',
				header: 'B6) Observed Average Cycle Time(sec/cycle)',
				scenario: 'Phase 3 PPAP', mergeData: true
			},
			{
				field: 'actualHeadCountFlag',
				header: 'Actual Headcount (Direct Labor)',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{field: 'resultStatusMessage', header: 'Capacity Analysis Results', scenario: 'All'},
			{field: 'overAllWarningRiskError', header: 'Overall Warning/Risk/Error Details', scenario: 'All', mergeData: true}
		];
		this.pspnMap = [];
		this.pspnMapTabSplit = [];
		//this.pspnMap = this.carRequest.psPnMapTransList;
		this.carRequest.psPnMapTransList.forEach(pspn => {
			if (this.pspnMap.length < 14) {
				this.pspnMap.push(pspn);
			} else {
				this.pspnMap.push(pspn);
				this.pspnMapTabSplit.push(this.pspnMap);
				this.pspnMap = [];
			}
		});
		this.pspnMapTabSplit.push(this.pspnMap);
	}

	private updateDeclarationQuestionAnswers() {
		const updatedQustnList: DeclarationQuestionsTran[] = [];
		this.carRequest.declarationQuestionsTranList.forEach(question => {
			if (question.ansType === 'Descriptive') {
				question.answer = question.openTypeAnswer;
			} else if (question.ansType === 'Yes / No') {
				question.answer = question.yesNoAnswer;
				question.optionStr = JSON.stringify(question.optionObj);
			} else if (question.ansType === 'Date Picker' && question.dateAnswer) {
				if (question.dateAnswer) {
					const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
						'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
					];
					const date = new Date(question.dateAnswer);
					question.answer = date.getDate() + '-' + monthNames[date.getMonth()] + '-' + date.getFullYear();
					question.dateAnswerDisplay = Date.parse(this.utilService.convertDateStringInFormat(question.dateAnswer));
				}
			}
			updatedQustnList.push(question);
		});
		this.carRequest.declarationQuestionsTranList = updatedQustnList;
	}

	declarationQuestionStatus() {
		let isMandatoryQuestionsValid = true;
		this.carRequest.declarationQuestionsTranList.forEach(question => {
			if (question.mandatory) {
				if (!question.answer || question.answer === '' || question.answer === null) {
					isMandatoryQuestionsValid = false;
				}
				if (isMandatoryQuestionsValid && question.question === 'Supplier used the following method to complete sub-tier supplier capacity analysis:' && question.answer === 'Others' && (!question.comments || question.comments === '')) {
					isMandatoryQuestionsValid = false;
				}
				if (isMandatoryQuestionsValid && question.question === 'Has this eCAR been created for a family of parts? (e.g. LH/RH) If so, you must enter the additional part numbers in the comments below.' && question.answer === 'Yes' && (!question.comments || question.comments === '')) {
					isMandatoryQuestionsValid = false;
				}
			}
		});
		if (!isMandatoryQuestionsValid) {
			return 'Incomplete';
		} else if (this.carRequest.declarationQuestionsTranList[0].yesNoAnswer === 'No' || this.carRequest.declarationQuestionsTranList[4].yesNoAnswer === 'No' ||
			(this.carRequest.declarationQuestionsTranList[0].yesNoAnswer !== 'NA' && this.carRequest.declarationQuestionsTranList[1].openTypeAnswer !== this.carRequest.declarationQuestionsTranList[2].openTypeAnswer)) {
			return 'Risk';
		} else {
			return 'OK';
		}
	}

	ngOnDestroy() {
		this.jsPlumbInstance.deleteEveryConnection();
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.connectProcessNames();
			// window.print();
		}, 6000);
		this.connectProcessNames();
	}

	@HostListener('window:afterprint')
	onAfterPrint() {
		window.close();
	}

	@HostListener('window:beforeprint')
	onBeforePrint() {
		this.jsPlumbInstance.repaintEverything();
	}

	onPrint() {
		const landscape = false;
		const head = document.head || document.getElementsByTagName('head')[0];
		const style = document.createElement('style');
		style.type = 'text/css';
		style.media = 'print';

		style.appendChild(document.createTextNode(landscape ?
			'@page { size: A3 landscape; margin: auto;}' :
			'@page { size: A3;  margin: auto; }'));

		head.appendChild(style);
		window.print();
	}

	private totalAllocationAndcontainAssumptionsCheck() {
		this.totalAllocationPercentageGreaterThan100 = false;
		this.containAssumptionsHasFalse = false;
		if (this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
			this.carRequest.psPnMapTransList.forEach(psPnMapTran => {
				if (psPnMapTran.processDataList) {
					psPnMapTran.processDataList.forEach(processData => {
						if (!this.totalAllocationPercentageGreaterThan100 && !processData.dedicated && ((processData.totalAllocationPercentageAPW && processData.totalAllocationPercentageAPW > 100) || (processData.totalAllocationPercentageMPW && processData.totalAllocationPercentageMPW > 100))) {
							this.totalAllocationPercentageGreaterThan100 = true;
						}
						if (!this.containAssumptionsHasFalse && ((!processData.dedicated && !processData.isContainAssumptions) ||
							(processData.dedicated && !processData.isContainAssumptions
								&& processData.dedicatedTotalAllocPercent < 100))) {
							this.containAssumptionsHasFalse = true;
						}
					});
				}
			});
		}
		if ((!this.totalAllocationPercentageGreaterThan100 || !this.containAssumptionsHasFalse) && this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
			this.carRequest.primarySupportingMapTranList.forEach(priSupMapList => {
				if (priSupMapList.psPnMapTranList && priSupMapList.psPnMapTranList.length > 0) {
					priSupMapList.psPnMapTranList.forEach(psPnMapTran => {
						if (psPnMapTran.processDataList) {
							psPnMapTran.processDataList.forEach(processData => {
								if (!this.totalAllocationPercentageGreaterThan100 && !processData.dedicated && ((processData.totalAllocationPercentageAPW && processData.totalAllocationPercentageAPW > 100) || (processData.totalAllocationPercentageMPW && processData.totalAllocationPercentageMPW > 100))) {
									this.totalAllocationPercentageGreaterThan100 = true;
								}
								if (!this.containAssumptionsHasFalse && ((!processData.dedicated && !processData.isContainAssumptions) ||
									(processData.dedicated && !processData.isContainAssumptions
										&& processData.dedicatedTotalAllocPercent < 100))) {
									this.containAssumptionsHasFalse = true;
								}
							});
						}
					});
				}
			});
		}
	}

	private supportProcessDataConstruction(processNameList: any[], processDataList: any[], supportingMap: PrimarySupportingMapTran, matchFlag: boolean, supportingProcess: {}) {
		this.supportingProcessDataList.forEach(supProData => {
			if (supProData.processStreamName === supportingMap.processStreamName) {
				matchFlag = true;
			}
		});
		if (matchFlag) {
			supportingProcess['processStreamName'] = supportingMap.processStreamName + ' (Continued)';
		} else {
			supportingProcess['processStreamName'] = supportingMap.processStreamName;
		}
		supportingProcess['processNameList'] = processNameList;
		supportingProcess['processDataList'] = processDataList;
		this.supportingProcessDataList.push(supportingProcess);
	}

	private cycleTimeCheck(processData: any, flag: any): boolean {
		let isValid = false;
		if (processData.idealCycleTime > 0 && processData.idealCycleTime < 5) {
			isValid = this.validateRange(processData, 0.5, flag);
			return isValid;
		} else if (processData.idealCycleTime >= 5 && processData.idealCycleTime < 20) {
			isValid = this.validateRange(processData, 1, flag);
			return isValid;
		} else if (processData.idealCycleTime >= 20 && processData.idealCycleTime < 100) {
			isValid = this.validateRange(processData, 1.5, flag);
			return isValid;
		} else if (processData.idealCycleTime >= 100) {
			isValid = this.validateRange(processData, 2, flag);
			return isValid;
		}
	}

	private validateRange(processData: any, range: number, flag: any) {
		if (processData.observedAvgCycleTime > processData.idealCycleTime + range) {
			processData.cycleTimeHigher = true;
			processData.cycleTimelower = false;
		} else if (processData.observedAvgCycleTime < processData.idealCycleTime - range) {
			processData.cycleTimeHigher = false;
			processData.cycleTimelower = true;
		} else {
			processData.cycleTimeHigher = false;
			processData.cycleTimelower = false;
		}
		if (flag === 'Higher') {
			return processData.cycleTimeHigher;
		} else {
			return processData.cycleTimelower;
		}
	}

	isFinite(value: number): boolean {
		return isFinite(value);
	}

	noOfMessageCheck(pspnTran: PsPnMapTran) {
		const resultInfo = [];
		this.hmpRecordsCountLessThan25Check(pspnTran, resultInfo);
		this.hmpOlderThan32WeeksCheck(pspnTran, resultInfo);
		this.isHmpItemExceeds100Check(pspnTran, resultInfo);
		this.isOneOrMoreOEEPercentageGreaterThan100Check(pspnTran, resultInfo);
		console.log('XXXXXXXXXXXXXXXX WARNING CHECK XXXXXXXXXXXXXXXXXX');
		if (pspnTran.processDataList[0].dedicated && pspnTran.processDataList[0].phaseOEE > pspnTran.processDataList[0].averageHisOee) {
			if (this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 0')) {
				pspnTran.processDataList[0].phaseOEEFlag = pspnTran.processDataList[0].phaseOEE + 'warning';
				resultInfo.push('Phase 0 OEE (' + pspnTran.processDataList[0].phaseOEE + ') is higher than historical OEE (' + pspnTran.processDataList[0].averageHisOee + ')warning');
			}
			if (this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 3')) {
				pspnTran.processDataList[0].phaseOEEFlag = pspnTran.processDataList[0].phaseOEE + 'warning';
				resultInfo.push('Phase 3 OEE (' + pspnTran.processDataList[0].phaseOEE + ') is higher than historical OEE (' + pspnTran.processDataList[0].averageHisOee + ')warning');
			}
		} else {
			pspnTran.processDataList[0].phaseOEEFlag = pspnTran.processDataList[0].phaseOEE + '';
		}
		if (pspnTran.processDataList[0] && pspnTran.processDataList[0].daysPerWeek && (pspnTran.processDataList[0].daysPerWeek > 5) && (pspnTran.processDataList[0].daysPerWeek <= 7)) {
			pspnTran.processDataList[0].daysPerWeekFlag = pspnTran.processDataList[0].daysPerWeek + 'warning';
			resultInfo.push('APW Days/Week (B : ' + pspnTran.processDataList[0].daysPerWeek + ') is greater than 5 days warning');
		} else if (!pspnTran.processDataList[0].daysPerWeekFlag || (pspnTran.processDataList[0].daysPerWeekFlag && !pspnTran.processDataList[0].daysPerWeekFlag.includes('warning'))) {
			pspnTran.processDataList[0].daysPerWeekFlag = pspnTran.processDataList[0].daysPerWeek + '';
		}
		if (pspnTran.processDataList[1] && pspnTran.processDataList[1].daysPerWeek && (pspnTran.processDataList[1].daysPerWeek === 7)) {
			pspnTran.processDataList[1].daysPerWeekFlag = pspnTran.processDataList[1].daysPerWeek + 'warning';
			resultInfo.push('MPW Days/Week (B : ' + pspnTran.processDataList[1].daysPerWeek + ') is greater than 6 days warning');
		} else if (!pspnTran.processDataList[1].daysPerWeekFlag || (pspnTran.processDataList[1].daysPerWeekFlag && !pspnTran.processDataList[1].daysPerWeekFlag.includes('warning'))) {
			pspnTran.processDataList[1].daysPerWeekFlag = pspnTran.processDataList[1].daysPerWeek + '';
		}
		if (pspnTran.processDataList[0] && pspnTran.processDataList[0].shiftsPerDay && pspnTran.processDataList[0].hrsPerShift && (pspnTran.processDataList[0].shiftsPerDay * pspnTran.processDataList[0].hrsPerShift) > 24) {
			pspnTran.processDataList[0].shiftsPerDayFlag = pspnTran.processDataList[0].shiftsPerDay + 'warning';
			pspnTran.processDataList[0].hrsPerShiftFlag = pspnTran.processDataList[0].hrsPerShift + 'warning';
			resultInfo.push('Total Hours per Day APW (C : ' + pspnTran.processDataList[0].shiftsPerDay + 'X D: ' +  pspnTran.processDataList[0].hrsPerShift + ') exceeds 24 Hours in the Operating Pattern. warning');
		} else if (pspnTran.processDataList[0].shiftsPerDayFlag && !pspnTran.processDataList[0].shiftsPerDayFlag.includes('warning') && pspnTran.processDataList[0].hrsPerShiftFlag && !pspnTran.processDataList[0].hrsPerShiftFlag.includes('warning')) {
			pspnTran.processDataList[0].shiftsPerDayFlag = pspnTran.processDataList[0].shiftsPerDay + '';
			pspnTran.processDataList[0].hrsPerShiftFlag = pspnTran.processDataList[0].hrsPerShift + '';
		}
		if (!pspnTran.processDataList[0].shiftsPerDayFlag) {
			pspnTran.processDataList[0].shiftsPerDayFlag = pspnTran.processDataList[0].shiftsPerDay + '';
		}
		if (!pspnTran.processDataList[0].hrsPerShiftFlag) {
			pspnTran.processDataList[0].hrsPerShiftFlag = pspnTran.processDataList[0].hrsPerShift + '';
		}
		if (pspnTran.processDataList[1] && pspnTran.processDataList[1].shiftsPerDay && pspnTran.processDataList[1].hrsPerShift && (pspnTran.processDataList[1].shiftsPerDay * pspnTran.processDataList[1].hrsPerShift) > 24) {
			pspnTran.processDataList[1].shiftsPerDayFlag = pspnTran.processDataList[1].shiftsPerDay + 'warning';
			pspnTran.processDataList[1].hrsPerShiftFlag = pspnTran.processDataList[1].hrsPerShift + 'warning';
			resultInfo.push('Total Hours per Day MPW (C : ' + pspnTran.processDataList[1].shiftsPerDay + 'X D : ' +  pspnTran.processDataList[1].hrsPerShift + ') exceeds 24 Hours in the Operating Pattern. warning');
		} else if (pspnTran.processDataList[1].shiftsPerDayFlag && !pspnTran.processDataList[1].shiftsPerDayFlag.includes('warning') && pspnTran.processDataList[1].hrsPerShiftFlag && !pspnTran.processDataList[1].hrsPerShiftFlag.includes('warning')) {
			pspnTran.processDataList[1].shiftsPerDayFlag = pspnTran.processDataList[1].shiftsPerDay + '';
			pspnTran.processDataList[1].hrsPerShiftFlag = pspnTran.processDataList[1].hrsPerShift + '';
		}
		if (!pspnTran.processDataList[1].shiftsPerDayFlag) {
			pspnTran.processDataList[1].shiftsPerDayFlag = pspnTran.processDataList[1].shiftsPerDay + '';
		}
		if (!pspnTran.processDataList[1].hrsPerShiftFlag) {
			pspnTran.processDataList[1].hrsPerShiftFlag = pspnTran.processDataList[1].hrsPerShift + '';
		}
		if ((pspnTran.processDataList[0].dedicated && pspnTran.isPartNameAvailableInSharedLoading)) {
			resultInfo.push('Shared loading plan exists for this process (F) warning');
		}
		if ((pspnTran.processDataList[0].dedicated && pspnTran.isProcessUsedInAnotherPartNumber && (!pspnTran.isPartNameAvailableInSharedLoading))) {
			resultInfo.push('This process has already been shown as ‘Dedicated’ in the eCAR for -' + pspnTran.recordsUsedInAnotherPartNumbers + '.If still in production on this equipment, please create a Shared Loading Plan and select Shared in this eCAR. warning');
		}
		if ((pspnTran.processDataList[0].dedicated && pspnTran.isProcessUsedInAnotherPartNumber && (!pspnTran.isPartNameAvailableInSharedLoading))) {
			resultInfo.push('This process has already been shown as ‘Dedicated’ in the eCAR for -' + pspnTran.recordsUsedInAnotherPartNumbers + 'warning');
		}
		if (pspnTran.processDataList[0].idealCycleTime !== null && pspnTran.processDataList[0].noToolsMachParallel !== null && pspnTran.processDataList[0].noIdentityParts !== null && pspnTran.processDataList[0].reqOee === 0) {
			pspnTran.processDataList[0].reqOeeFlag = pspnTran.processDataList[0].reqOee + 'warning';
			resultInfo.push('APW Required OEE (Q : ' + pspnTran.processDataList[0].reqOee + ') should not be 0%, please review your data warning');
		} else if (!pspnTran.processDataList[0].reqOeeFlag || (pspnTran.processDataList[0].reqOeeFlag && !pspnTran.processDataList[0].reqOeeFlag.includes('warning'))) {
			pspnTran.processDataList[0].reqOeeFlag = pspnTran.processDataList[0].reqOee + '';
		}
		if (pspnTran.processDataList[0].idealCycleTime !== null && pspnTran.processDataList[0].noToolsMachParallel !== null && pspnTran.processDataList[0].noIdentityParts !== null && pspnTran.processDataList[1].reqOee === 0) {
			pspnTran.processDataList[1].reqOeeFlag = pspnTran.processDataList[1].reqOee + 'warning';
			resultInfo.push('MPW Required OEE (Q : ' + pspnTran.processDataList[1].reqOee + ') should not be 0%, please review your data warning');
		} else if (!pspnTran.processDataList[1].reqOeeFlag || (pspnTran.processDataList[1].reqOeeFlag && !pspnTran.processDataList[1].reqOeeFlag.includes('warning'))) {
			pspnTran.processDataList[1].reqOeeFlag = pspnTran.processDataList[1].reqOee + '';
		}
		if (((pspnTran.processDataList[0].avaiRemainPercent < 10) && (pspnTran.processDataList[0].avaiRemainPercent > 0))) {
			pspnTran.processDataList[0].avaiRemainPercentFlag = pspnTran.processDataList[0].avaiRemainPercent + 'warning';
			resultInfo.push('APW Remaining % for Availability & Efficiency losses (T : ' + pspnTran.processDataList[0].avaiRemainPercent + ') is less than 10%, please check OEE feasibility warning');
		} else if (!pspnTran.processDataList[0].avaiRemainPercentFlag || (pspnTran.processDataList[0].avaiRemainPercentFlag && !pspnTran.processDataList[0].avaiRemainPercentFlag.includes('warning'))) {
			pspnTran.processDataList[0].avaiRemainPercentFlag = pspnTran.processDataList[0].avaiRemainPercent + '';
		}
		if (((pspnTran.processDataList[1].avaiRemainPercent < 10) && (pspnTran.processDataList[1].avaiRemainPercent > 0))) {
			pspnTran.processDataList[1].avaiRemainPercentFlag = pspnTran.processDataList[1].avaiRemainPercent + 'warning';
			resultInfo.push('MPW Remaining % for Availability & Efficiency losses (T : ' + pspnTran.processDataList[1].avaiRemainPercent + ') is less than 10%, please check OEE feasibility warning');
		} else if (!pspnTran.processDataList[1].avaiRemainPercentFlag || (pspnTran.processDataList[1].avaiRemainPercentFlag && !pspnTran.processDataList[1].avaiRemainPercentFlag.includes('warning'))) {
			pspnTran.processDataList[1].avaiRemainPercentFlag = pspnTran.processDataList[1].avaiRemainPercent + '';
		}
		if (pspnTran.processDataList[0].sharedLoadingTotalAllocPercent >= 90 && pspnTran.processDataList[0].sharedLoadingTotalAllocPercent <= 100) {
			pspnTran.processDataList[0].sharedLoadingTotalAllocPercentFlag = pspnTran.processDataList[0].sharedLoadingTotalAllocPercent + 'warning';
			resultInfo.push('APW Allocation (U : ' + _.round(pspnTran.processDataList[0].sharedLoadingTotalAllocPercent, 1) + ') > 90%, A detailed shared loading plan is needed warning');
		} else if (!pspnTran.processDataList[0].sharedLoadingTotalAllocPercentFlag || (pspnTran.processDataList[0].sharedLoadingTotalAllocPercentFlag && !pspnTran.processDataList[0].sharedLoadingTotalAllocPercentFlag.includes('warning'))) {
			pspnTran.processDataList[0].sharedLoadingTotalAllocPercentFlag = pspnTran.processDataList[0].sharedLoadingTotalAllocPercent + '';
		}
		if (pspnTran.processDataList[1].sharedLoadingTotalAllocPercent >= 90 &&  pspnTran.processDataList[1].sharedLoadingTotalAllocPercent <= 100) {
			pspnTran.processDataList[1].sharedLoadingTotalAllocPercentFlag = pspnTran.processDataList[1].sharedLoadingTotalAllocPercent + 'warning';
			resultInfo.push('MPW Allocation (U : ' + _.round(pspnTran.processDataList[1].sharedLoadingTotalAllocPercent, 1) + ') > 90%, A detailed shared loading plan is needed warning');
		} else if (!pspnTran.processDataList[1].sharedLoadingTotalAllocPercentFlag || (pspnTran.processDataList[1].sharedLoadingTotalAllocPercentFlag && !pspnTran.processDataList[1].sharedLoadingTotalAllocPercentFlag.includes('warning'))) {
			pspnTran.processDataList[1].sharedLoadingTotalAllocPercentFlag = pspnTran.processDataList[1].sharedLoadingTotalAllocPercent + '';
		}
		if (pspnTran.processDataList[0].dedicatedTotalAllocPercent >= 90 && pspnTran.processDataList[0].dedicatedTotalAllocPercent <= 100) {
			pspnTran.processDataList[0].dedicatedTotalAllocPercentFlag = pspnTran.processDataList[0].dedicatedTotalAllocPercent + 'warning';
			resultInfo.push('APW Allocation (U : ' + _.round(pspnTran.processDataList[0].dedicatedTotalAllocPercent, 1) + ') > 90% warning');
		} else if (!pspnTran.processDataList[0].dedicatedTotalAllocPercentFlag || (pspnTran.processDataList[0].dedicatedTotalAllocPercentFlag && !pspnTran.processDataList[0].dedicatedTotalAllocPercentFlag.includes('warning'))) {
			pspnTran.processDataList[0].dedicatedTotalAllocPercentFlag = pspnTran.processDataList[0].dedicatedTotalAllocPercent + '';
		}
		if (pspnTran.processDataList[1].dedicatedTotalAllocPercent >= 90 &&  pspnTran.processDataList[1].dedicatedTotalAllocPercent <= 100) {
			pspnTran.processDataList[1].dedicatedTotalAllocPercentFlag = pspnTran.processDataList[1].dedicatedTotalAllocPercent + 'warning';
			resultInfo.push('MPW Allocation (U : ' + _.round(pspnTran.processDataList[1].dedicatedTotalAllocPercent, 1) + ') > 90% warning');
		} else if (!pspnTran.processDataList[1].dedicatedTotalAllocPercentFlag || (pspnTran.processDataList[1].dedicatedTotalAllocPercentFlag && !pspnTran.processDataList[1].dedicatedTotalAllocPercentFlag.includes('warning'))) {
			pspnTran.processDataList[1].dedicatedTotalAllocPercentFlag = pspnTran.processDataList[1].dedicatedTotalAllocPercent + '';
		}
		if ((this.carRequest.scenarioName === 'Phase 3' && pspnTran.processDataList[0].changeOverTimeActual && pspnTran.processDataList[0].changeOverTimeActual !== null && pspnTran.processDataList[0].changeOverTimeActual <= (pspnTran.processDataList[0].planMinChan - (pspnTran.processDataList[0].planMinChan * 0.5)))) {
			pspnTran.processDataList[0].changeOverTimeActualFlag = pspnTran.processDataList[0].changeOverTimeActual + 'warning';
			resultInfo.push('Changeover Time Actual (Y : ' + pspnTran.processDataList[0].changeOverTimeActual + ') is at least 50% lower than the Changeover Time Planned warning');
		} else if (!pspnTran.processDataList[0].changeOverTimeActualFlag || (pspnTran.processDataList[0].changeOverTimeActualFlag && !pspnTran.processDataList[0].changeOverTimeActualFlag.includes('warning'))) {
			pspnTran.processDataList[0].changeOverTimeActualFlag = pspnTran.processDataList[0].changeOverTimeActual + '';
		}
		if ((this.carRequest.scenarioName === 'Phase 3' && pspnTran.processDataList[0].changeOverTimeActual && pspnTran.processDataList[0].changeOverTimeActual !== null && pspnTran.processDataList[0].changeOverTimeActual >= (pspnTran.processDataList[0].planMinChan + (0.1 * pspnTran.processDataList[0].planMinChan)))) {
			pspnTran.processDataList[0].changeOverTimeActualFlag = pspnTran.processDataList[0].changeOverTimeActual + 'warning';
			resultInfo.push('Changeover Time Actual (Y : ' + pspnTran.processDataList[0].changeOverTimeActual + ') is at least 10% higher than the Changeover Time Planned warning');
		} else if (!pspnTran.processDataList[0].changeOverTimeActualFlag || (pspnTran.processDataList[0].changeOverTimeActualFlag && !pspnTran.processDataList[0].changeOverTimeActualFlag.includes('warning'))) {
			pspnTran.processDataList[0].changeOverTimeActualFlag = pspnTran.processDataList[0].changeOverTimeActual + '';
		}
		if (this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 0') && ( pspnTran.processDataList[0].noToolsMachParallel && (pspnTran.processDataList[0].noToolsMachParallel !== null) && pspnTran.processDataList[0].noOfCurrentToolsORMachines && (pspnTran.processDataList[0].noOfCurrentToolsORMachines !== null) && (pspnTran.processDataList[0].noOfCurrentToolsORMachines < pspnTran.processDataList[0].noToolsMachParallel))) {
			pspnTran.processDataList[0].noOfCurrentToolsORMachinesFlag = pspnTran.processDataList[0].noOfCurrentToolsORMachines + 'warning';
			pspnTran.processDataList[0].noToolsMachParallelFlag = pspnTran.processDataList[0].noToolsMachParallel + 'warning';
			resultInfo.push('Not all tools/machines (AF1 : ' + pspnTran.processDataList[0].noOfCurrentToolsORMachines + ') in place as planned (shown in (L : ' + pspnTran.processDataList[0].noToolsMachParallel + ') warning');
		} else if (pspnTran.processDataList[0].noOfCurrentToolsORMachinesFlag && !pspnTran.processDataList[0].noOfCurrentToolsORMachinesFlag.includes('warning') && pspnTran.processDataList[0].noToolsMachParallelFlag && !pspnTran.processDataList[0].noToolsMachParallelFlag.includes('warning')) {
			pspnTran.processDataList[0].noOfCurrentToolsORMachinesFlag = pspnTran.processDataList[0].noOfCurrentToolsORMachines + '';
			pspnTran.processDataList[0].noToolsMachParallelFlag = pspnTran.processDataList[0].noToolsMachParallel + '';
		}
		if (!pspnTran.processDataList[0].noOfCurrentToolsORMachinesFlag) {
			pspnTran.processDataList[0].noOfCurrentToolsORMachinesFlag = pspnTran.processDataList[0].noOfCurrentToolsORMachines + '';
		}
		if (!pspnTran.processDataList[0].noToolsMachParallelFlag) {
			pspnTran.processDataList[0].noToolsMachParallelFlag = pspnTran.processDataList[0].noToolsMachParallel + '';
		}
		if (( pspnTran.processDataList[0].noIdentityParts && pspnTran.processDataList[0].noOfIdenticalPartsProduced && (pspnTran.processDataList[0].noOfIdenticalPartsProduced !== null) && (pspnTran.processDataList[0].noIdentityParts !== null) && (pspnTran.processDataList[0].noOfIdenticalPartsProduced < pspnTran.processDataList[0].noIdentityParts))) {
			pspnTran.processDataList[0].noOfIdenticalPartsProducedFlag = pspnTran.processDataList[0].noOfIdenticalPartsProduced + 'warning';
			pspnTran.processDataList[0].noIdentityPartsFlag = pspnTran.processDataList[0].noIdentityParts + 'warning';
			resultInfo.push('Less parts produced per Tool or Machine Cycle (AF2 : ' + pspnTran.processDataList[0].noOfIdenticalPartsProduced + ') than planned (shown in (M : ' + pspnTran.processDataList[0].noIdentityParts + ') warning');
		} else if (pspnTran.processDataList[0].noOfIdenticalPartsProducedFlag && !pspnTran.processDataList[0].noOfIdenticalPartsProducedFlag.includes('warning') && pspnTran.processDataList[0].noIdentityPartsFlag && !pspnTran.processDataList[0].noIdentityPartsFlag.includes('warning')) {
			pspnTran.processDataList[0].noOfIdenticalPartsProducedFlag = pspnTran.processDataList[0].noOfIdenticalPartsProduced + '';
			pspnTran.processDataList[0].noIdentityPartsFlag = pspnTran.processDataList[0].noIdentityParts + '';
		}
		if (!pspnTran.processDataList[0].noOfIdenticalPartsProducedFlag) {
			pspnTran.processDataList[0].noOfIdenticalPartsProducedFlag = pspnTran.processDataList[0].noOfIdenticalPartsProduced + '';
		}
		if (!pspnTran.processDataList[0].noIdentityPartsFlag) {
			pspnTran.processDataList[0].noIdentityPartsFlag = pspnTran.processDataList[0].noIdentityParts + '';
		}
		if ((pspnTran.processDataList[0].scrappedPercent && (pspnTran.processDataList[0].noOfPartsScrappedPercent !== null) && (pspnTran.processDataList[0].scrappedPercent !== null) && (pspnTran.processDataList[0].noOfPartsScrappedPercent > pspnTran.processDataList[0].scrappedPercent))) {
			pspnTran.processDataList[0].noOfPartsScrappedPercentFlag = pspnTran.processDataList[0].noOfPartsScrappedPercent + 'warning';
			pspnTran.processDataList[0].scrappedPercentFlag = pspnTran.processDataList[0].scrappedPercent + 'warning';
			resultInfo.push('Scrap Rate(AJ : ' + pspnTran.processDataList[0].noOfPartsScrappedPercent + ') is higher than Planned Scrap Rate (shown in (H : ' + pspnTran.processDataList[0].scrappedPercent + ') warning');
		} else if (pspnTran.processDataList[0].noOfPartsScrappedPercentFlag && !pspnTran.processDataList[0].noOfPartsScrappedPercentFlag.includes('warning') && pspnTran.processDataList[0].scrappedPercentFlag && !pspnTran.processDataList[0].scrappedPercentFlag.includes('warning')) {
			pspnTran.processDataList[0].noOfPartsScrappedPercentFlag = pspnTran.processDataList[0].noOfPartsScrappedPercent + '';
			pspnTran.processDataList[0].scrappedPercentFlag = pspnTran.processDataList[0].scrappedPercent + '';
		}
		if (!pspnTran.processDataList[0].noOfPartsScrappedPercentFlag) {
			pspnTran.processDataList[0].noOfPartsScrappedPercentFlag = pspnTran.processDataList[0].noOfPartsScrappedPercent + '';
		}
		if (!pspnTran.processDataList[0].scrappedPercentFlag) {
			pspnTran.processDataList[0].scrappedPercentFlag = pspnTran.processDataList[0].scrappedPercent + '';
		}
		if (( pspnTran.processDataList[0].partsReworkedPercent && (pspnTran.processDataList[0].noOfPartsReworkedPercent !== null) && (pspnTran.processDataList[0].partsReworkedPercent !== null) && (pspnTran.processDataList[0].noOfPartsReworkedPercent > pspnTran.processDataList[0].partsReworkedPercent))) {
			pspnTran.processDataList[0].noOfPartsReworkedPercentFlag = pspnTran.processDataList[0].noOfPartsReworkedPercent + 'warning';
			pspnTran.processDataList[0].partsReworkedPercentFlag = pspnTran.processDataList[0].partsReworkedPercent + 'warning';
			resultInfo.push('Rework rate(AK : ' + pspnTran.processDataList[0].noOfPartsReworkedPercent + ') higher than planned (shown in (R : ' + pspnTran.processDataList[0].partsReworkedPercent + ') warning');
		} else if (pspnTran.processDataList[0].noOfPartsReworkedPercentFlag && !pspnTran.processDataList[0].noOfPartsReworkedPercentFlag.includes('warning') && pspnTran.processDataList[0].partsReworkedPercentFlag && !pspnTran.processDataList[0].partsReworkedPercentFlag.includes('warning')) {
			pspnTran.processDataList[0].noOfPartsReworkedPercentFlag = pspnTran.processDataList[0].noOfPartsReworkedPercent + '';
			pspnTran.processDataList[0].partsReworkedPercentFlag = pspnTran.processDataList[0].partsReworkedPercent + '';
		}
		if (!pspnTran.processDataList[0].noOfPartsReworkedPercentFlag) {
			pspnTran.processDataList[0].noOfPartsReworkedPercentFlag = pspnTran.processDataList[0].noOfPartsReworkedPercent + '';
		}
		if (!pspnTran.processDataList[0].partsReworkedPercentFlag) {
			pspnTran.processDataList[0].partsReworkedPercentFlag = pspnTran.processDataList[0].partsReworkedPercent + '';
		}
		if ((pspnTran.processDataList[0].observedAvgCycleTime && !(pspnTran.processDataList[0].observedAvgCycleTime === null) && pspnTran.processDataList[0].observedAvgCycleTime && !(pspnTran.processDataList[0].idealCycleTime === null)) && this.cycleTimeCheck(pspnTran.processDataList[0], 'Higher')) {
			pspnTran.processDataList[0].observedAvgCycleTimeFlag = pspnTran.processDataList[0].observedAvgCycleTime + 'warning';
			pspnTran.processDataList[0].idealCycleTimeFlag = pspnTran.processDataList[0].idealCycleTime + 'warning';
			resultInfo.push('Observed Average Cycle Time (B6 : ' + pspnTran.processDataList[0].observedAvgCycleTime + ') is higher than Ideal Cycle Time (K : ' + pspnTran.processDataList[0].idealCycleTime + '), ensure there is a Cycle Time Improvement Plan in place warning');
		} else if (pspnTran.processDataList[0].observedAvgCycleTimeFlag && !pspnTran.processDataList[0].observedAvgCycleTimeFlag.includes('warning') && pspnTran.processDataList[0].idealCycleTimeFlag && !pspnTran.processDataList[0].idealCycleTimeFlag.includes('warning')) {
			pspnTran.processDataList[0].observedAvgCycleTimeFlag = pspnTran.processDataList[0].observedAvgCycleTime + '';
			pspnTran.processDataList[0].idealCycleTimeFlag = pspnTran.processDataList[0].idealCycleTime + '';
		}
		if ((pspnTran.processDataList[0].observedAvgCycleTime && !(pspnTran.processDataList[0].observedAvgCycleTime === null) && pspnTran.processDataList[0].observedAvgCycleTime && !(pspnTran.processDataList[0].idealCycleTime === null)) && this.cycleTimeCheck(pspnTran.processDataList[0], 'Lower')) {
			pspnTran.processDataList[0].observedAvgCycleTimeFlag = pspnTran.processDataList[0].observedAvgCycleTime + 'warning';
			pspnTran.processDataList[0].idealCycleTimeFlag = pspnTran.processDataList[0].idealCycleTime + 'warning';
			resultInfo.push('Observed Average Cycle Time (B6 : ' + pspnTran.processDataList[0].observedAvgCycleTime + ')) is lower than Ideal Cycle Time (K : ' + pspnTran.processDataList[0].idealCycleTime + '). Adjust Ideal Cycle Time if necessary. warning');
		} else if (pspnTran.processDataList[0].observedAvgCycleTimeFlag && !pspnTran.processDataList[0].observedAvgCycleTimeFlag.includes('warning') && pspnTran.processDataList[0].idealCycleTimeFlag && !pspnTran.processDataList[0].idealCycleTimeFlag.includes('warning')) {
			pspnTran.processDataList[0].observedAvgCycleTimeFlag = pspnTran.processDataList[0].observedAvgCycleTime + '';
			pspnTran.processDataList[0].idealCycleTimeFlag = pspnTran.processDataList[0].idealCycleTime + '';
		}
		if (!pspnTran.processDataList[0].observedAvgCycleTimeFlag) {
			pspnTran.processDataList[0].observedAvgCycleTimeFlag = pspnTran.processDataList[0].observedAvgCycleTime + '';
		}
		if (!pspnTran.processDataList[0].idealCycleTimeFlag) {
			pspnTran.processDataList[0].idealCycleTimeFlag = pspnTran.processDataList[0].idealCycleTime + '';
		}
		if (( pspnTran.processDataList[0].plannedHeadCount && pspnTran.processDataList[0].actualHeadCount && (pspnTran.processDataList[0].actualHeadCount !== null) && (pspnTran.processDataList[0].plannedHeadCount !== null) && (pspnTran.processDataList[0].actualHeadCount < pspnTran.processDataList[0].plannedHeadCount)) && (this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 0') || this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 3'))) {
			pspnTran.processDataList[0].actualHeadCountFlag = pspnTran.processDataList[0].actualHeadCount + 'warning';
			resultInfo.push('Actual headcount(' + pspnTran.processDataList[0].actualHeadCount + ') is lower than the planned headcount(' + pspnTran.processDataList[0].plannedHeadCount + ') in A7 warning');
		} else if (!pspnTran.processDataList[0].actualHeadCountFlag || (pspnTran.processDataList[0].actualHeadCountFlag && !pspnTran.processDataList[0].actualHeadCountFlag.includes('warning'))) {
			pspnTran.processDataList[0].actualHeadCountFlag = pspnTran.processDataList[0].actualHeadCount + '';
		}
		if (( pspnTran.processDataList[0].plannedHeadCount && pspnTran.processDataList[0].actualHeadCount && (pspnTran.processDataList[0].actualHeadCount !== null) && (pspnTran.processDataList[0].plannedHeadCount !== null) && (pspnTran.processDataList[0].actualHeadCount > pspnTran.processDataList[0].plannedHeadCount)) && (this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 0') || this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 3'))) {
			pspnTran.processDataList[0].actualHeadCountFlag = pspnTran.processDataList[0].actualHeadCount + 'warning';
			resultInfo.push('Actual headcount(' + pspnTran.processDataList[0].actualHeadCount + ') is higher than the planned headcount(' + pspnTran.processDataList[0].plannedHeadCount + ') in A7. An action plan is needed to improve productivity. warning');
		} else if (!pspnTran.processDataList[0].actualHeadCountFlag || (pspnTran.processDataList[0].actualHeadCountFlag && !pspnTran.processDataList[0].actualHeadCountFlag.includes('warning'))) {
			pspnTran.processDataList[0].actualHeadCountFlag = pspnTran.processDataList[0].actualHeadCount + '';
		}
		console.log('XXXXXXXXXXXXXXXX RISK CHECK XXXXXXXXXXXXXXXXXX');
		if ((pspnTran.processDataList[0].avaiRemainPercent <= 0)) {
			pspnTran.processDataList[0].avaiRemainPercentFlag = pspnTran.processDataList[0].avaiRemainPercent + 'risk';
			resultInfo.push('APW - OEE is not feasible, it cannot contain the changeover, scrap & rework.(T : ' + pspnTran.processDataList[0].avaiRemainPercent + '% risk)');
		} else if (!pspnTran.processDataList[0].avaiRemainPercentFlag || (pspnTran.processDataList[0].avaiRemainPercentFlag && !pspnTran.processDataList[0].avaiRemainPercentFlag.includes('warning'))) {
			pspnTran.processDataList[0].avaiRemainPercentFlag = pspnTran.processDataList[0].avaiRemainPercent + '';
		}
		if ((pspnTran.processDataList[1].avaiRemainPercent <= 0)) {
			pspnTran.processDataList[1].avaiRemainPercentFlag = pspnTran.processDataList[1].avaiRemainPercent + 'risk';
			resultInfo.push('MPW - OEE is not feasible, it cannot contain the changeover, scrap & rework.(T : ' + pspnTran.processDataList[1].avaiRemainPercent + '% risk)');
		} else if (!pspnTran.processDataList[1].avaiRemainPercentFlag || (pspnTran.processDataList[1].avaiRemainPercentFlag && !pspnTran.processDataList[1].avaiRemainPercentFlag.includes('warning'))) {
			pspnTran.processDataList[1].avaiRemainPercentFlag = pspnTran.processDataList[1].avaiRemainPercent + '';
		}
		if (pspnTran.processDataList[0].reqOee > 100) {
			pspnTran.processDataList[0].reqOeeFlag = pspnTran.processDataList[0].reqOee + 'risk';
			resultInfo.push('Required OEE (Q : ' + pspnTran.processDataList[0].reqOee + ') exceeds 100% risk');
		} else if (pspnTran.processDataList[1].reqOee > 100) {
			pspnTran.processDataList[1].reqOeeFlag = pspnTran.processDataList[1].reqOee + 'risk';
			resultInfo.push('Required OEE (Q : ' + pspnTran.processDataList[1].reqOee + ') exceeds 100% risk');
		} else if (!pspnTran.processDataList[0].reqOeeFlag || (pspnTran.processDataList[0].reqOeeFlag && !pspnTran.processDataList[0].reqOeeFlag.includes('warning'))) {
			pspnTran.processDataList[0].reqOeeFlag = pspnTran.processDataList[0].reqOee + '';
		} else if (!pspnTran.processDataList[1].reqOeeFlag || (pspnTran.processDataList[1].reqOeeFlag && !pspnTran.processDataList[1].reqOeeFlag.includes('warning'))) {
			pspnTran.processDataList[1].reqOeeFlag = pspnTran.processDataList[1].reqOee + '';
		}
		if (pspnTran.processDataList[0].sharedLoadingTotalAllocPercent > 100) {
			pspnTran.processDataList[0].sharedLoadingTotalAllocPercentFlag = pspnTran.processDataList[0].sharedLoadingTotalAllocPercent + 'risk';
			resultInfo.push('APW % Allocation (U : ' + pspnTran.processDataList[0].sharedLoadingTotalAllocPercent + ') > 100%, Loading Plan exceeds capacity risk');
		} else if (!pspnTran.processDataList[0].sharedLoadingTotalAllocPercentFlag || (pspnTran.processDataList[0].sharedLoadingTotalAllocPercentFlag && !pspnTran.processDataList[0].sharedLoadingTotalAllocPercentFlag.includes('warning'))) {
			pspnTran.processDataList[0].sharedLoadingTotalAllocPercentFlag = pspnTran.processDataList[0].sharedLoadingTotalAllocPercent + '';
		}
		if (pspnTran.processDataList[1].sharedLoadingTotalAllocPercent > 100) {
			pspnTran.processDataList[1].sharedLoadingTotalAllocPercentFlag = pspnTran.processDataList[1].sharedLoadingTotalAllocPercent + 'risk';
			resultInfo.push('MPW % Allocation (U : ' + pspnTran.processDataList[1].sharedLoadingTotalAllocPercent + ') > 100%, Loading Plan exceeds capacity risk');
		} else if (!pspnTran.processDataList[1].sharedLoadingTotalAllocPercentFlag || (pspnTran.processDataList[1].sharedLoadingTotalAllocPercentFlag && !pspnTran.processDataList[1].sharedLoadingTotalAllocPercentFlag.includes('warning'))) {
			pspnTran.processDataList[1].sharedLoadingTotalAllocPercentFlag = pspnTran.processDataList[1].sharedLoadingTotalAllocPercent + '';
		}
		if (pspnTran.processDataList[0].dedicatedTotalAllocPercent > 100) {
			pspnTran.processDataList[0].dedicatedTotalAllocPercentFlag = pspnTran.processDataList[0].dedicatedTotalAllocPercent + 'risk';
			resultInfo.push('APW % Allocation (U : ' + pspnTran.processDataList[0].dedicatedTotalAllocPercent + ') > 100% risk');
		} else if (!pspnTran.processDataList[0].dedicatedTotalAllocPercentFlag || (pspnTran.processDataList[0].dedicatedTotalAllocPercentFlag && !pspnTran.processDataList[0].dedicatedTotalAllocPercentFlag.includes('warning'))) {
			pspnTran.processDataList[0].dedicatedTotalAllocPercentFlag = pspnTran.processDataList[0].dedicatedTotalAllocPercent + '';
		}
		if (pspnTran.processDataList[1].dedicatedTotalAllocPercent > 100) {
			pspnTran.processDataList[1].dedicatedTotalAllocPercentFlag = pspnTran.processDataList[1].dedicatedTotalAllocPercent + 'risk';
			resultInfo.push('MPW % Allocation (U : ' + pspnTran.processDataList[1].dedicatedTotalAllocPercent + ') > 100% risk');
		} else if (!pspnTran.processDataList[1].dedicatedTotalAllocPercentFlag || (pspnTran.processDataList[1].dedicatedTotalAllocPercentFlag && !pspnTran.processDataList[1].dedicatedTotalAllocPercentFlag.includes('warning'))) {
			pspnTran.processDataList[1].dedicatedTotalAllocPercentFlag = pspnTran.processDataList[1].dedicatedTotalAllocPercent + '';
		}
		if (pspnTran.demonOEE && pspnTran.processDataList[0].reqOee && (pspnTran.demonOEE < pspnTran.processDataList[0].reqOee) && this.carRequest.scenarioName === 'Capacity Planning') {
			pspnTran.demonOEEFlag = pspnTran.demonOEE + 'risk';
			resultInfo.push('APW - Demonstrated OEE (Z : ' + pspnTran.demonOEE + ') is less than Required OEE(' + pspnTran.processDataList[0].reqOee + ' risk)');
		} else if (!pspnTran.demonOEEFlag || (pspnTran.demonOEEFlag && !pspnTran.demonOEEFlag.includes('warning'))) {
			pspnTran.demonOEEFlag = pspnTran.demonOEE + '';
		}
		if (pspnTran.demonOEE && pspnTran.processDataList[1].reqOee && (pspnTran.demonOEE < pspnTran.processDataList[1].reqOee) && this.carRequest.scenarioName === 'Capacity Planning') {
			pspnTran.demonOEEFlag = pspnTran.demonOEE + 'risk';
			resultInfo.push('MPW - Demonstrated OEE (Z : ' + pspnTran.demonOEE + ') is less than Required OEE(' + pspnTran.processDataList[1].reqOee + ' risk)');
		} else if (!pspnTran.demonOEEFlag || (pspnTran.demonOEEFlag && !pspnTran.demonOEEFlag.includes('warning'))) {
			pspnTran.demonOEEFlag = pspnTran.demonOEE + '';
		}
		if (pspnTran.demonOEE && pspnTran.demonOEE > 100 && this.carRequest.scenarioName === 'Capacity Planning') {
			pspnTran.demonOEEFlag = pspnTran.demonOEE + 'risk';
			resultInfo.push('Demonstrated OEE (Z : ' + pspnTran.demonOEE + ') exceeds 100% risk');
		} else if (!pspnTran.demonOEEFlag || (pspnTran.demonOEEFlag && !pspnTran.demonOEEFlag.includes('warning'))) {
			pspnTran.demonOEEFlag = pspnTran.demonOEE + '';
		}
		if (this.carRequest.scenarioName === 'Phase' && ((pspnTran.processDataList[0].phaseOEE < pspnTran.processDataList[0].reqOee) || (pspnTran.processDataList[1].phaseOEE < pspnTran.processDataList[1].reqOee))) {
			pspnTran.processDataList[0].phaseOEEFlag = pspnTran.processDataList[0].phaseOEE + 'risk';
			pspnTran.processDataList[0].reqOeeFlag = pspnTran.processDataList[0].reqOee + 'risk';
			resultInfo.push('Demonstrated OEE (AM : ' + pspnTran.processDataList[0].phaseOEE + ') is less than Required OEE (Q : ' + pspnTran.processDataList[0].reqOee + ' risk)');
		} else if (pspnTran.processDataList[0].phaseOEEFlag && !pspnTran.processDataList[0].phaseOEEFlag.includes('warning') && !pspnTran.processDataList[0].reqOeeFlag.includes('warning')) {
			pspnTran.processDataList[0].phaseOEEFlag = pspnTran.processDataList[0].phaseOEE + '';
			pspnTran.processDataList[0].reqOeeFlag = pspnTran.processDataList[0].reqOee + '';
		}
		if (!pspnTran.processDataList[0].phaseOEEFlag) {
			pspnTran.processDataList[0].phaseOEEFlag = pspnTran.processDataList[0].phaseOEE + '';
		}
		if (!pspnTran.processDataList[0].reqOeeFlag) {
			pspnTran.processDataList[0].reqOeeFlag = pspnTran.processDataList[0].reqOee + '';
		}
		console.log('XXXXXXXXXXXXXXXX ERROR CHECK XXXXXXXXXXXXXXXXXX');
		if (this.carRequest.requestStatus === 'DRAFT' || this.carRequest.requestStatus === 'REJECTED' || this.carRequest.requestStatus === 'STADRAFT') {
			if (pspnTran.processDataList[0] && pspnTran.processDataList[0].daysPerWeek && pspnTran.processDataList[0].daysPerWeek > 7) {
				pspnTran.processDataList[0].daysPerWeekFlag = pspnTran.processDataList[0].daysPerWeek + 'error';
				resultInfo.push('APW Days/Week (B : ' + pspnTran.processDataList[0].daysPerWeek + ') cannot be greater than 7 days error');
			} else if (!pspnTran.processDataList[0].daysPerWeekFlag || (pspnTran.processDataList[0].daysPerWeekFlag && !pspnTran.processDataList[0].daysPerWeekFlag.includes('warning') && !pspnTran.processDataList[0].daysPerWeekFlag.includes('risk'))) {
				pspnTran.processDataList[0].daysPerWeekFlag = pspnTran.processDataList[0].daysPerWeek + '';
			}
			if (pspnTran.processDataList[1] && pspnTran.processDataList[1].daysPerWeek && pspnTran.processDataList[1].daysPerWeek > 7) {
				pspnTran.processDataList[1].daysPerWeekFlag = pspnTran.processDataList[1].daysPerWeek + 'error';
				resultInfo.push('MPW Days/Week (B : ' + pspnTran.processDataList[1].daysPerWeek + ') cannot be greater than 7 days error');
			} else if (!pspnTran.processDataList[1].daysPerWeekFlag || (pspnTran.processDataList[1].daysPerWeekFlag && !pspnTran.processDataList[1].daysPerWeekFlag.includes('warning') && !pspnTran.processDataList[1].daysPerWeekFlag.includes('risk'))) {
				pspnTran.processDataList[1].daysPerWeekFlag = pspnTran.processDataList[1].daysPerWeek + '';
			}
			if (pspnTran.processDataList[0].downTime && pspnTran.processDataList[0].hrsPerShift && (pspnTran.processDataList[0].downTime / 60 >= pspnTran.processDataList[0].hrsPerShift)) {
				pspnTran.processDataList[0].downTimeFlag = pspnTran.processDataList[0].downTime + 'error';
				pspnTran.processDataList[0].hrsPerShiftFlag = pspnTran.processDataList[0].hrsPerShift + 'error';
				resultInfo.push('APW Contractual Down Time (E : ' + pspnTran.processDataList[0].downTime + ') should be less than Hrs/Shift (D : ' + pspnTran.processDataList[0].hrsPerShift + ') error');
			} else if (pspnTran.processDataList[0].hrsPerShiftFlag && !pspnTran.processDataList[0].hrsPerShiftFlag.includes('warning') && !pspnTran.processDataList[0].hrsPerShiftFlag.includes('risk') && pspnTran.processDataList[0].downTimeFlag && !pspnTran.processDataList[0].downTimeFlag.includes('warning') && !pspnTran.processDataList[0].downTimeFlag.includes('risk')) {
				pspnTran.processDataList[0].downTimeFlag = pspnTran.processDataList[0].downTime + '';
				pspnTran.processDataList[0].hrsPerShiftFlag = pspnTran.processDataList[0].hrsPerShift + '';
			}
			if (!pspnTran.processDataList[0].downTimeFlag) {
				pspnTran.processDataList[0].downTimeFlag = pspnTran.processDataList[0].downTime + '';
			}
			if (!pspnTran.processDataList[0].hrsPerShiftFlag) {
				pspnTran.processDataList[0].hrsPerShiftFlag = pspnTran.processDataList[0].hrsPerShift + '';
			}
			if (pspnTran.processDataList[1].downTime && pspnTran.processDataList[1].hrsPerShift && (pspnTran.processDataList[1].downTime / 60 >= pspnTran.processDataList[1].hrsPerShift)) {
				pspnTran.processDataList[1].downTimeFlag = pspnTran.processDataList[1].downTime + 'error';
				pspnTran.processDataList[1].hrsPerShiftFlag = pspnTran.processDataList[1].hrsPerShift + 'error';
				resultInfo.push('MPW Contractual Down Time (E : ' + pspnTran.processDataList[1].downTime + ') should be less than Hrs/Shift (D : ' + pspnTran.processDataList[1].hrsPerShift + ') error');
			} else if (pspnTran.processDataList[1].hrsPerShiftFlag && !pspnTran.processDataList[1].hrsPerShiftFlag.includes('warning') && !pspnTran.processDataList[1].hrsPerShiftFlag.includes('risk') && pspnTran.processDataList[1].downTimeFlag && !pspnTran.processDataList[1].downTimeFlag.includes('warning') && !pspnTran.processDataList[1].downTimeFlag.includes('risk')) {
				pspnTran.processDataList[1].downTimeFlag = pspnTran.processDataList[1].downTime + '';
				pspnTran.processDataList[1].hrsPerShiftFlag = pspnTran.processDataList[1].hrsPerShift + '';
			}
			if (!pspnTran.processDataList[1].downTimeFlag) {
				pspnTran.processDataList[1].downTimeFlag = pspnTran.processDataList[1].downTime + '';
			}
			if (!pspnTran.processDataList[1].hrsPerShiftFlag) {
				pspnTran.processDataList[1].hrsPerShiftFlag = pspnTran.processDataList[1].hrsPerShift + '';
			}
			if (pspnTran.processDataList[0].scrappedPercent > 100) {
				pspnTran.processDataList[0].scrappedPercentFlag = pspnTran.processDataList[0].scrappedPercent + 'error';
				resultInfo.push('Planned Scrap Percentage (H : ' + pspnTran.processDataList[0].scrappedPercent + ') cannot be greater than 100 error');
			} else if (!pspnTran.processDataList[0].scrappedPercentFlag || (pspnTran.processDataList[0].scrappedPercentFlag && !pspnTran.processDataList[0].scrappedPercentFlag.includes('warning') && !pspnTran.processDataList[0].scrappedPercentFlag.includes('risk'))) {
				pspnTran.processDataList[0].scrappedPercentFlag = pspnTran.processDataList[0].scrappedPercent + '';
			}
			if (pspnTran.processDataList[0].partsReworkedPercent > 100) {
				pspnTran.processDataList[0].partsReworkedPercentFlag = pspnTran.processDataList[0].partsReworkedPercent + 'error';
				resultInfo.push('Planned Rework Percentage (R : ' + pspnTran.processDataList[0].partsReworkedPercent + ') cannot be greater than 100 error');
			} else if (!pspnTran.processDataList[0].scrappedPercentFlag || (pspnTran.processDataList[0].scrappedPercentFlag && !pspnTran.processDataList[0].scrappedPercentFlag.includes('warning') && !pspnTran.processDataList[0].scrappedPercentFlag.includes('risk'))) {
				pspnTran.processDataList[0].partsReworkedPercentFlag = pspnTran.processDataList[0].partsReworkedPercent + '';
			}
			if (((pspnTran.processDataList[0].plannedDownTime + pspnTran.processDataList[0].changeOverTimeActual + pspnTran.processDataList[0].observedUnplannedDownTime) > pspnTran.processDataList[0].totalAvailableTime) && !this.hideProcessDetailsValidation && (this.carRequest.scenarioName === 'Phase 0' || this.carRequest.scenarioName === 'Phase 3')) {
				pspnTran.processDataList[0].plannedDownTimeFlag = pspnTran.processDataList[0].plannedDownTime + 'error';
				pspnTran.processDataList[0].changeOverTimeActualFlag = pspnTran.processDataList[0].changeOverTimeActual + 'error';
				pspnTran.processDataList[0].observedUnplannedDownTimeFlag = pspnTran.processDataList[0].observedUnplannedDownTime + 'error';
				pspnTran.processDataList[0].totalAvailableTimeFlag = pspnTran.processDataList[0].totalAvailableTime + 'error';
				resultInfo.push('Downtime cannot be higher than Total Available Time [(W : ' + pspnTran.processDataList[0].plannedDownTime + '+ Y : ' + pspnTran.processDataList[0].changeOverTimeActual + '+ AB : ' + pspnTran.processDataList[0].observedUnplannedDownTime + ') cannot be higher than (V : ' + pspnTran.processDataList[0].totalAvailableTime + ')], please review and correct error');
			} else if (pspnTran.processDataList[0].plannedDownTimeFlag && !pspnTran.processDataList[0].plannedDownTimeFlag.includes('warning') && !pspnTran.processDataList[0].plannedDownTimeFlag.includes('risk') && pspnTran.processDataList[0].changeOverTimeActualFlag && !pspnTran.processDataList[0].changeOverTimeActualFlag.includes('warning') && !pspnTran.processDataList[0].changeOverTimeActualFlag.includes('risk') && pspnTran.processDataList[0].observedUnplannedDownTimeFlag && !pspnTran.processDataList[0].observedUnplannedDownTimeFlag.includes('warning') && !pspnTran.processDataList[0].observedUnplannedDownTimeFlag.includes('risk') && pspnTran.processDataList[0].totalAvailableTimeFlag && !pspnTran.processDataList[0].totalAvailableTimeFlag.includes('warning') && !pspnTran.processDataList[0].totalAvailableTimeFlag.includes('risk')) {
				pspnTran.processDataList[0].plannedDownTimeFlag = pspnTran.processDataList[0].plannedDownTime + '';
				pspnTran.processDataList[0].changeOverTimeActualFlag = pspnTran.processDataList[0].changeOverTimeActual + '';
				pspnTran.processDataList[0].observedUnplannedDownTimeFlag = pspnTran.processDataList[0].observedUnplannedDownTime + '';
				pspnTran.processDataList[0].totalAvailableTimeFlag = pspnTran.processDataList[0].totalAvailableTime + '';
			}
			if (!pspnTran.processDataList[0].plannedDownTimeFlag) {
				pspnTran.processDataList[0].plannedDownTimeFlag = pspnTran.processDataList[0].plannedDownTime + '';
			}
			if (!pspnTran.processDataList[0].changeOverTimeActualFlag) {
				pspnTran.processDataList[0].changeOverTimeActualFlag = pspnTran.processDataList[0].changeOverTimeActual + '';
			}
			if (!pspnTran.processDataList[0].observedUnplannedDownTimeFlag) {
				pspnTran.processDataList[0].observedUnplannedDownTimeFlag = pspnTran.processDataList[0].observedUnplannedDownTime + '';
			}
			if (!pspnTran.processDataList[0].totalAvailableTimeFlag) {
				pspnTran.processDataList[0].totalAvailableTimeFlag = pspnTran.processDataList[0].totalAvailableTime + '';
			}
			if (pspnTran.processDataList[0].plannedDownTime > pspnTran.processDataList[0].totalAvailableTime) {
				pspnTran.processDataList[0].plannedDownTimeFlag = pspnTran.processDataList[0].plannedDownTime + 'error';
				pspnTran.processDataList[0].totalAvailableTimeFlag = pspnTran.processDataList[0].totalAvailableTime + 'error';
				resultInfo.push('Planned Downtime (W : ' + pspnTran.processDataList[0].plannedDownTime + ') cannot be more than Total Available Time (V : ' + pspnTran.processDataList[0].totalAvailableTime + '). error');
			} else if (pspnTran.processDataList[0].plannedDownTimeFlag && !pspnTran.processDataList[0].plannedDownTimeFlag.includes('warning') && !pspnTran.processDataList[0].plannedDownTimeFlag.includes('risk') && pspnTran.processDataList[0].totalAvailableTimeFlag && !pspnTran.processDataList[0].totalAvailableTimeFlag.includes('warning') && !pspnTran.processDataList[0].totalAvailableTimeFlag.includes('risk')) {
				pspnTran.processDataList[0].plannedDownTimeFlag = pspnTran.processDataList[0].plannedDownTime + '';
				pspnTran.processDataList[0].totalAvailableTimeFlag = pspnTran.processDataList[0].totalAvailableTime + '';
			}
			if (!pspnTran.processDataList[0].plannedDownTimeFlag) {
				pspnTran.processDataList[0].plannedDownTimeFlag = pspnTran.processDataList[0].plannedDownTime + '';
			}
			if (!pspnTran.processDataList[0].totalAvailableTimeFlag) {
				pspnTran.processDataList[0].totalAvailableTimeFlag = pspnTran.processDataList[0].totalAvailableTime + '';
			}
			if (this.carRequest.scenarioName === 'Phase 3' && pspnTran.processDataList[0].changeOverTimeActual !== null && pspnTran.processDataList[0].changeOverTimeActual <= 0 && (pspnTran.processDataList[0].planMinChan * pspnTran.processDataList[0].planChangFreq) > 0 && !this.hideProcessDetailsValidation) {
				pspnTran.processDataList[0].changeOverTimeActualFlag = pspnTran.processDataList[0].changeOverTimeActual + 'error';
				resultInfo.push('Changeover time (Y : ' + pspnTran.processDataList[0].changeOverTimeActual + ') has been planned in A4 but not observed, please review and correct error');
			} else if (!pspnTran.processDataList[0].changeOverTimeActualFlag || (pspnTran.processDataList[0].changeOverTimeActualFlag && !pspnTran.processDataList[0].changeOverTimeActualFlag.includes('warning') && !pspnTran.processDataList[0].changeOverTimeActualFlag.includes('risk'))) {
				pspnTran.processDataList[0].changeOverTimeActualFlag = pspnTran.processDataList[0].changeOverTimeActual + '';
			}
			if (pspnTran.processDataList[0].equipmentAvailability > 100 && !this.hideProcessDetailsValidation && (this.carRequest.scenarioName === 'Phase 0' || this.carRequest.scenarioName === 'Phase 3')) {
				pspnTran.processDataList[0].equipmentAvailabilityFlag = pspnTran.processDataList[0].equipmentAvailability + 'error';
				resultInfo.push('Equipment Availability (AD : ' + pspnTran.processDataList[0].equipmentAvailability + ') cannot be > 100%, please review and correct error');
			} else if (!pspnTran.processDataList[0].equipmentAvailabilityFlag || (pspnTran.processDataList[0].equipmentAvailabilityFlag && !pspnTran.processDataList[0].equipmentAvailabilityFlag.includes('warning') && !pspnTran.processDataList[0].equipmentAvailabilityFlag.includes('risk'))) {
				pspnTran.processDataList[0].equipmentAvailabilityFlag = pspnTran.processDataList[0].equipmentAvailability + '';
			}
			if ((pspnTran.processDataList[0].noToolsMachParallel && pspnTran.processDataList[0].noOfCurrentToolsORMachines > pspnTran.processDataList[0].noToolsMachParallel) && !this.hideProcessDetailsValidation) {
				pspnTran.processDataList[0].noOfCurrentToolsORMachinesFlag = pspnTran.processDataList[0].noOfCurrentToolsORMachines + 'error';
				pspnTran.processDataList[0].noToolsMachParallelFlag = pspnTran.processDataList[0].noToolsMachParallel + 'error';
				resultInfo.push('(AF1 : ' + pspnTran.processDataList[0].noOfCurrentToolsORMachines + ') Cannot be higher than planned in (L : ' + pspnTran.processDataList[0].noToolsMachParallel + '); either update plan or correct this data error');
			} else if (pspnTran.processDataList[0].noOfCurrentToolsORMachinesFlag && !pspnTran.processDataList[0].noOfCurrentToolsORMachinesFlag.includes('warning') && !pspnTran.processDataList[0].noOfCurrentToolsORMachinesFlag.includes('risk') && pspnTran.processDataList[0].noToolsMachParallelFlag && !pspnTran.processDataList[0].noToolsMachParallelFlag.includes('warning') && !pspnTran.processDataList[0].noToolsMachParallelFlag.includes('risk')) {
				pspnTran.processDataList[0].noOfCurrentToolsORMachinesFlag = pspnTran.processDataList[0].noOfCurrentToolsORMachines + '';
				pspnTran.processDataList[0].noToolsMachParallelFlag = pspnTran.processDataList[0].noToolsMachParallel + '';
			}
			if (!pspnTran.processDataList[0].noOfCurrentToolsORMachinesFlag) {
				pspnTran.processDataList[0].noOfCurrentToolsORMachinesFlag = pspnTran.processDataList[0].noOfCurrentToolsORMachines + '';
			}
			if (!pspnTran.processDataList[0].noToolsMachParallelFlag) {
				pspnTran.processDataList[0].noToolsMachParallelFlag = pspnTran.processDataList[0].noToolsMachParallel + '';
			}
			if ((pspnTran.processDataList[0].noIdentityParts && pspnTran.processDataList[0].noOfIdenticalPartsProduced > pspnTran.processDataList[0].noIdentityParts) && !this.hideProcessDetailsValidation) {
				pspnTran.processDataList[0].noOfIdenticalPartsProducedFlag = pspnTran.processDataList[0].noOfIdenticalPartsProduced + 'error';
				pspnTran.processDataList[0].noIdentityPartsFlag = pspnTran.processDataList[0].noIdentityParts + 'error';
				resultInfo.push('(AF2 : ' + pspnTran.processDataList[0].noOfIdenticalPartsProduced + ') Cannot be higher than planned in (M : ' + pspnTran.processDataList[0].noIdentityParts + '); either update plan or correct this data error');
			} else if (pspnTran.processDataList[0].noOfIdenticalPartsProducedFlag && !pspnTran.processDataList[0].noOfIdenticalPartsProducedFlag.includes('warning') && !pspnTran.processDataList[0].noOfIdenticalPartsProducedFlag.includes('risk') && pspnTran.processDataList[0].noIdentityPartsFlag && !pspnTran.processDataList[0].noIdentityPartsFlag.includes('warning') && !pspnTran.processDataList[0].noIdentityPartsFlag.includes('risk')) {
				pspnTran.processDataList[0].noOfIdenticalPartsProducedFlag = pspnTran.processDataList[0].noOfIdenticalPartsProduced + '';
				pspnTran.processDataList[0].noIdentityPartsFlag = pspnTran.processDataList[0].noIdentityParts + '';
			}
			if (!pspnTran.processDataList[0].noOfIdenticalPartsProducedFlag) {
				pspnTran.processDataList[0].noOfIdenticalPartsProducedFlag = pspnTran.processDataList[0].noOfIdenticalPartsProduced + '';
			}
			if (!pspnTran.processDataList[0].noIdentityPartsFlag) {
				pspnTran.processDataList[0].noIdentityPartsFlag = pspnTran.processDataList[0].noIdentityParts + '';
			}
			if ((pspnTran.processDataList[0].noIdentityParts && (pspnTran.processDataList[0].performanceEfficiency !== null) && (isFinite(pspnTran.processDataList[0].performanceEfficiency) && pspnTran.processDataList[0].performanceEfficiency > 100 && !this.hideProcessDetailsValidation && (this.carRequest.scenarioName === 'Phase 0' || this.carRequest.scenarioName === 'Phase 3')))) {
				pspnTran.processDataList[0].performanceEfficiencyFlag = pspnTran.processDataList[0].performanceEfficiency + 'error';
				resultInfo.push('Efficiency (AG : ' + pspnTran.processDataList[0].performanceEfficiency + ') cannot be higher than 100%, please review and correct error');
			} else if (!pspnTran.processDataList[0].performanceEfficiencyFlag || (pspnTran.processDataList[0].performanceEfficiencyFlag && !pspnTran.processDataList[0].performanceEfficiencyFlag.includes('warning') && !pspnTran.processDataList[0].performanceEfficiencyFlag.includes('risk'))) {
				pspnTran.processDataList[0].performanceEfficiencyFlag = pspnTran.processDataList[0].performanceEfficiency + '';
			}
			if ((pspnTran.processDataList[0].noOfPartsScrapped + pspnTran.processDataList[0].noOfPartsReworked) > pspnTran.processDataList[0].totalPartsRun) {
				pspnTran.processDataList[0].noOfPartsScrappedFlag = pspnTran.processDataList[0].noOfPartsScrapped + 'error';
				pspnTran.processDataList[0].noOfPartsReworkedFlag = pspnTran.processDataList[0].noOfPartsReworked + 'error';
				pspnTran.processDataList[0].totalPartsRunFlag = pspnTran.processDataList[0].totalPartsRun + 'error';
				resultInfo.push('Sum of (AJ : ' + pspnTran.processDataList[0].noOfPartsScrapped + ') and (AK : ' + pspnTran.processDataList[0].noOfPartsReworked + ') cannot be greater than the total number of parts run (AE : ' + pspnTran.processDataList[0].totalPartsRun + ') error');
			} else if (pspnTran.processDataList[0].noOfPartsScrappedFlag && !pspnTran.processDataList[0].noOfPartsScrappedFlag.includes('warning') && !pspnTran.processDataList[0].noOfPartsScrappedFlag.includes('risk') && pspnTran.processDataList[0].noOfPartsReworkedFlag && !pspnTran.processDataList[0].noOfPartsReworkedFlag.includes('warning') && !pspnTran.processDataList[0].noOfPartsReworkedFlag.includes('risk') && pspnTran.processDataList[0].totalPartsRunFlag && !pspnTran.processDataList[0].totalPartsRunFlag.includes('warning') && !pspnTran.processDataList[0].totalPartsRunFlag.includes('risk')) {
				pspnTran.processDataList[0].noOfPartsScrappedFlag = pspnTran.processDataList[0].noOfPartsScrapped + '';
				pspnTran.processDataList[0].noOfPartsReworkedFlag = pspnTran.processDataList[0].noOfPartsReworked + '';
				pspnTran.processDataList[0].totalPartsRunFlag = pspnTran.processDataList[0].totalPartsRun + '';
			}
			if (pspnTran.processDataList[0].qualityRate < 0 && !this.hideProcessDetailsValidation && (this.carRequest.scenarioName === 'Phase 0' || this.carRequest.scenarioName === 'Phase 3')) {
				pspnTran.processDataList[0].noOfPartsScrappedFlag = pspnTran.processDataList[0].noOfPartsScrapped + 'error';
				pspnTran.processDataList[0].noOfPartsReworkedFlag = pspnTran.processDataList[0].noOfPartsReworked + 'error';
				pspnTran.processDataList[0].totalPartsRunFlag = pspnTran.processDataList[0].totalPartsRun + 'error';
				resultInfo.push('Sum of scrap (AJ : ' + pspnTran.processDataList[0].noOfPartsScrapped + ') and rework (AK : ' + pspnTran.processDataList[0].noOfPartsReworked + ') cannot be greater than parts produced (AE : ' + pspnTran.processDataList[0].totalPartsRun + '), please review and correct error');
			} else if (pspnTran.processDataList[0].noOfPartsScrappedFlag && !pspnTran.processDataList[0].noOfPartsScrappedFlag.includes('warning') && !pspnTran.processDataList[0].noOfPartsScrappedFlag.includes('risk') && pspnTran.processDataList[0].noOfPartsReworkedFlag && !pspnTran.processDataList[0].noOfPartsReworkedFlag.includes('warning') && !pspnTran.processDataList[0].noOfPartsReworkedFlag.includes('risk') && pspnTran.processDataList[0].totalPartsRunFlag && !pspnTran.processDataList[0].totalPartsRunFlag.includes('warning') && !pspnTran.processDataList[0].totalPartsRunFlag.includes('risk')) {
				pspnTran.processDataList[0].noOfPartsScrappedFlag = pspnTran.processDataList[0].noOfPartsScrapped + '';
				pspnTran.processDataList[0].noOfPartsReworkedFlag = pspnTran.processDataList[0].noOfPartsReworked + '';
				pspnTran.processDataList[0].totalPartsRunFlag = pspnTran.processDataList[0].totalPartsRun + '';
			}
			if (!pspnTran.processDataList[0].noOfPartsScrappedFlag) {
				pspnTran.processDataList[0].noOfPartsScrappedFlag = pspnTran.processDataList[0].noOfPartsScrapped + '';
			}
			if (!pspnTran.processDataList[0].noOfPartsReworkedFlag) {
				pspnTran.processDataList[0].noOfPartsReworkedFlag = pspnTran.processDataList[0].noOfPartsReworked + '';
			}
			if (!pspnTran.processDataList[0].totalPartsRunFlag) {
				pspnTran.processDataList[0].totalPartsRunFlag = pspnTran.processDataList[0].totalPartsRun + '';
			}
			if (!pspnTran.processDataList[0].dedicated && !!pspnTran.processDataList[0].netIdleCycleTime && pspnTran.processDataList[0].netIdleCycleTime.toString() !== 'NaN' && !!pspnTran.nictAPW && pspnTran.processDataList[0].netIdleCycleTime !== pspnTran.nictAPW) {
				pspnTran.processDataList[0].netIdleCycleTimeFlag = pspnTran.processDataList[0].netIdleCycleTime + 'error';
				resultInfo.push('This NICT (K : ' + pspnTran.processDataList[0].netIdleCycleTime + ') does not match the NICT found in Shared Loading. Please modify the data either in process details screen or in the SLP. error');
			} else if (!pspnTran.processDataList[0].netIdleCycleTimeFlag || (pspnTran.processDataList[0].netIdleCycleTimeFlag && !pspnTran.processDataList[0].netIdleCycleTimeFlag.includes('warning') && !pspnTran.processDataList[0].netIdleCycleTimeFlag.includes('risk'))) {
				pspnTran.processDataList[0].netIdleCycleTimeFlag = pspnTran.processDataList[0].netIdleCycleTime + '';
			}
			if ((!pspnTran.processDataList[0].dedicated && (pspnTran.processDataList[0].netIdleCycleTime.toString() !== 'NaN' && pspnTran.nictAPW !== null && ((pspnTran.processDataList[0].netIdleCycleTime) === pspnTran.nictAPW)) && (pspnTran.reqGoodPartsAPW != null) && (pspnTran.reqGoodPartsMPW != null) && (pspnTran.processDataList[0].reqGoodPartsNext !== pspnTran.reqGoodPartsAPW || pspnTran.processDataList[1].reqGoodPartsNext !== pspnTran.reqGoodPartsMPW) && this.carRequest.requestStatus !== 'STADRAFT' && (this.isCapacityStudyScenario ? (!pspnTran.showSLPartNumMismatchMsg && !pspnTran.capacityRecordNotFound) : !pspnTran.showSLPartNumMismatchMsg))) {
				pspnTran.reqGoodPartsAPWFlag = pspnTran.reqGoodPartsAPW + 'error';
				pspnTran.reqGoodPartsMPWFlag = pspnTran.reqGoodPartsMPW + 'error';
				resultInfo.push('The required good parts per week (J : ' + pspnTran.reqGoodPartsAPW + '|' + pspnTran.reqGoodPartsMPW + ') does not match the SLP data. Click the "Update Required Good Parts" button in the process details window to automatically align the SLP data with these numbers. error');
			} else if (pspnTran.reqGoodPartsAPWFlag && !pspnTran.reqGoodPartsAPWFlag.includes('warning') && !pspnTran.reqGoodPartsAPWFlag.includes('risk') && pspnTran.reqGoodPartsMPWFlag && !pspnTran.reqGoodPartsMPWFlag.includes('warning') && !pspnTran.reqGoodPartsMPWFlag.includes('risk')) {
				pspnTran.reqGoodPartsAPWFlag = pspnTran.reqGoodPartsAPW + '';
				pspnTran.reqGoodPartsMPWFlag = pspnTran.reqGoodPartsMPW + '';
			}
			if (!pspnTran.reqGoodPartsAPWFlag) {
				pspnTran.reqGoodPartsAPWFlag = pspnTran.reqGoodPartsAPW + '';
			}
			if (!pspnTran.reqGoodPartsMPWFlag) {
				pspnTran.reqGoodPartsMPWFlag = pspnTran.reqGoodPartsMPW + '';
			}
		} else {
			pspnTran.processDataList[0].netIdleCycleTimeFlag = pspnTran.processDataList[0].netIdleCycleTime + '';
			pspnTran.processDataList[0].totalAvailableTimeFlag = pspnTran.processDataList[0].totalAvailableTime + '';
			pspnTran.processDataList[0].plannedDownTimeFlag = pspnTran.processDataList[0].plannedDownTime + '';
			pspnTran.processDataList[0].observedUnplannedDownTimeFlag = pspnTran.processDataList[0].observedUnplannedDownTime + '';
			pspnTran.processDataList[0].equipmentAvailabilityFlag = pspnTran.processDataList[0].equipmentAvailability + '';
			pspnTran.processDataList[0].totalPartsRunFlag = pspnTran.processDataList[0].totalPartsRun + '';
			pspnTran.processDataList[0].performanceEfficiencyFlag = pspnTran.processDataList[0].performanceEfficiency + '';
			pspnTran.processDataList[0].noOfPartsScrappedFlag = pspnTran.processDataList[0].noOfPartsScrapped + '';
			pspnTran.processDataList[0].noOfPartsReworkedFlag = pspnTran.processDataList[0].noOfPartsReworked + '';
		}
		this.makeFieldsReadable(pspnTran);
		return resultInfo;
	}

	private hmpRecordsCountLessThan25Check(psPnMapTran: PsPnMapTran, resultInfo: any) {
		let recordsCount = 0;
		psPnMapTran.hmpRecordsCountLessThan25WarningShown = false;
		this.historicalMfgPerfObj = {
			processId: psPnMapTran.processNameId,
			siteCode: this.carRequest.siteCode
		};
		this.hmpService.findLatest25HistMfgPerfRecords(this.historicalMfgPerfObj).subscribe((response: HistoricalMfgPerf[]) => {
			this.historicalmfgperfs = response;
			if (this.historicalmfgperfs && this.historicalmfgperfs.length > 0) {
				psPnMapTran.demonOEE = this.utilService.calculateAverageOEE(this.historicalmfgperfs);
				if (this.historicalmfgperfs && this.historicalmfgperfs.length > 0) {
					this.historicalmfgperfs.forEach(hmp => {
						if (hmp.hmfDateDisplay !== null) {
							recordsCount++;
						}
					});
					if (recordsCount < 25) {
						psPnMapTran.hmpRecordsCountLessThan25WarningShown = true;
						this.pushMsgToResultInfo(resultInfo, 'Historical Manufacturing Performance record count is less than 25. warning');
					}
				}
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	private hmpOlderThan32WeeksCheck(psPnMapTran: PsPnMapTran, resultInfo: any) {
		psPnMapTran.hmpOlderThan32WeeksWarningShown = false;
		const thirtyTwoWeeksAgo = moment().subtract(32, 'weeks').valueOf();
		this.historicalMfgPerfObj = {
			processId: psPnMapTran.processNameId,
			siteCode: this.carRequest.siteCode
		};
		this.hmpService.findLatest25HistMfgPerfRecords(this.historicalMfgPerfObj).subscribe((response: HistoricalMfgPerf[]) => {
			this.historicalmfgperfs = response;
			if (this.historicalmfgperfs && this.historicalmfgperfs.length > 0) {
				psPnMapTran.demonOEE = this.utilService.calculateAverageOEE(this.historicalmfgperfs);

				this.historicalmfgperfs.forEach((historicalmfgperf) => {
					const historicalMfgPerfInMilliseconds = this.parseDate(historicalmfgperf.hmfDate);
					if (historicalMfgPerfInMilliseconds < thirtyTwoWeeksAgo) {
						psPnMapTran.hmpOlderThan32WeeksWarningShown = true;
					}
				});
				if (psPnMapTran.hmpOlderThan32WeeksWarningShown) {
					this.pushMsgToResultInfo(resultInfo, 'One or more Historical Manufacturing Performance records are currently older than 32 weeks. warning');
				}
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	private isHmpItemExceeds100Check(psPnMapTran: PsPnMapTran, resultInfo: any) {
		psPnMapTran.isHmpItemExceeds100 = false;
		this.historicalMfgPerfObj = {
			processId: psPnMapTran.processNameId,
			siteCode: this.carRequest.siteCode
		};
		this.hmpService.findLatest25HistMfgPerfRecords(this.historicalMfgPerfObj).subscribe((response: HistoricalMfgPerf[]) => {
			this.historicalmfgperfs = response;
			if (this.historicalmfgperfs && this.historicalmfgperfs.length > 0) {
				psPnMapTran.demonOEE = this.utilService.calculateAverageOEE(this.historicalmfgperfs);

				if (this.historicalmfgperfs.find(historicalObject => historicalObject.oee > 100)) {
					psPnMapTran.isHmpItemExceeds100 = true;
					this.pushMsgToResultInfo(resultInfo, 'One or more HMP records have OEE > 100% error');
				}
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	private isOneOrMoreOEEPercentageGreaterThan100Check (psPnMapTran: PsPnMapTran, resultInfo: any) {
		psPnMapTran.isOneOrMoreOEEPercentageGreaterThan100 = false;
		this.historicalMfgPerfObj = {
			processId: psPnMapTran.processNameId,
			siteCode: this.carRequest.siteCode
		};
		this.hmpService.findLatest25HistMfgPerfRecords(this.historicalMfgPerfObj).subscribe((response: HistoricalMfgPerf[]) => {
			this.historicalmfgperfs = response;
			if (this.historicalmfgperfs && this.historicalmfgperfs.length > 0) {
				psPnMapTran.demonOEE = this.utilService.calculateAverageOEE(this.historicalmfgperfs);

				this.historicalmfgperfs.forEach(hmp => {
					if (hmp.oee !== null && hmp.oee > 100) {
						psPnMapTran.isOneOrMoreOEEPercentageGreaterThan100 = true;
						this.pushMsgToResultInfo(resultInfo, 'One or more Historical Manufacturing Performance records have OEE > 100% error');
					}
				});
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	parseDate(curDate: any) {
		return Date.parse(curDate);
	}

	private makeFieldsReadable(pspnTran: any) {
		pspnTran.processDataList[0].planMinChan = pspnTran.processDataList[0].planMinChan + '';
		pspnTran.processDataList[1].planMinChan = pspnTran.processDataList[1].planMinChan + '';
		pspnTran.processDataList[0].planChangFreq = pspnTran.processDataList[0].planChangFreq + '';
		pspnTran.processDataList[1].planChangFreq = pspnTran.processDataList[1].planChangFreq + '';
		pspnTran.processDataList[0].allocPercent = pspnTran.processDataList[0].allocPercent + '';
		pspnTran.processDataList[1].allocPercent = pspnTran.processDataList[1].allocPercent + '';
		pspnTran.processDataList[0].reqGoodPartsNext = pspnTran.processDataList[0].reqGoodPartsNext + '';
		pspnTran.processDataList[1].reqGoodPartsNext = pspnTran.processDataList[1].reqGoodPartsNext + '';
		pspnTran.processDataList[0].theoreticalParts = pspnTran.processDataList[0].theoreticalParts + '';
		pspnTran.processDataList[1].theoreticalParts = pspnTran.processDataList[1].theoreticalParts + '';
		pspnTran.processDataList[0].reqJph = pspnTran.processDataList[0].reqJph + '';
		pspnTran.processDataList[1].reqJph = pspnTran.processDataList[1].reqJph + '';
		pspnTran.processDataList[0].plannedHeadCount = pspnTran.processDataList[0].plannedHeadCount + '';
		pspnTran.processDataList[1].plannedHeadCount = pspnTran.processDataList[1].plannedHeadCount + '';
		pspnTran.processDataList[0].netAvailableTime = pspnTran.processDataList[0].netAvailableTime + '';
		pspnTran.processDataList[1].netAvailableTime = pspnTran.processDataList[1].netAvailableTime + '';
		pspnTran.processDataList[0].weekPartEstimate = pspnTran.processDataList[0].weekPartEstimate + '';
		pspnTran.processDataList[1].weekPartEstimate = pspnTran.processDataList[1].weekPartEstimate + '';
		pspnTran.processDataList[0].demonJph = pspnTran.processDataList[0].demonJph + '';
		pspnTran.processDataList[1].demonJph = pspnTran.processDataList[1].demonJph + '';
		pspnTran.processDataList[0].averageHisOee = pspnTran.processDataList[0].averageHisOee + '';
		pspnTran.processDataList[1].averageHisOee = pspnTran.processDataList[1].averageHisOee + '';
		pspnTran.processDataList[0].netAvailableTimePhase = pspnTran.processDataList[0].netAvailableTimePhase + '';
		pspnTran.processDataList[1].netAvailableTimePhase = pspnTran.processDataList[1].netAvailableTimePhase + '';
		pspnTran.processDataList[0].changeOverTimeWeekly = pspnTran.processDataList[0].changeOverTimeWeekly + '';
		pspnTran.processDataList[1].changeOverTimeWeekly = pspnTran.processDataList[1].changeOverTimeWeekly + '';
		pspnTran.processDataList[0].operatingTime = pspnTran.processDataList[0].operatingTime + '';
		pspnTran.processDataList[1].operatingTime = pspnTran.processDataList[1].operatingTime + '';
		pspnTran.processDataList[0].plannedNetIdealCycleTime = pspnTran.processDataList[0].plannedNetIdealCycleTime + '';
		pspnTran.processDataList[1].plannedNetIdealCycleTime = pspnTran.processDataList[1].plannedNetIdealCycleTime + '';
		pspnTran.processDataList[0].availablePerformanceEfficiency = pspnTran.processDataList[0].availablePerformanceEfficiency + '';
		pspnTran.processDataList[1].availablePerformanceEfficiency = pspnTran.processDataList[1].availablePerformanceEfficiency + '';
		pspnTran.processDataList[0].qualityRate = pspnTran.processDataList[0].qualityRate + '';
		pspnTran.processDataList[1].qualityRate = pspnTran.processDataList[1].qualityRate + '';
		pspnTran.processDataList[0].phaseJPH = pspnTran.processDataList[0].phaseJPH + '';
		pspnTran.processDataList[1].phaseJPH = pspnTran.processDataList[1].phaseJPH + '';
		pspnTran.processDataList[0].totalAllocationPercentageAPW = pspnTran.processDataList[0].totalAllocationPercentageAPW + '';
		pspnTran.processDataList[1].totalAllocationPercentageAPW = pspnTran.processDataList[1].totalAllocationPercentageAPW + '';
		pspnTran.processDataList[0].totalAllocationPercentageMPW = pspnTran.processDataList[0].totalAllocationPercentageMPW + '';
		pspnTran.processDataList[1].totalAllocationPercentageMPW = pspnTran.processDataList[1].totalAllocationPercentageMPW + '';
	}

	private pushMsgToResultInfo(resultInfo: any, message: string) {
		if (resultInfo && resultInfo.size > 0) {
			let match = 0;
			resultInfo.forEach( infoMsg => {
				if (infoMsg === message) {
					match = 1;
				}
			});
			if (match === 0) {
				resultInfo.push(message);
			}
		} else {
			resultInfo.push(message);
		}
	}
}
